import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import IconUser from "../../../assets/icons/icons-user.svg";
import bg2 from '../../../assets/images/bg/Album Grid.png'
import { getClassrooms } from "../../../Services/Classroom";
import { gsap } from "gsap";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { useAuth } from "../../../contexts/AuthContext";
import "../style.css";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const ArticleFooterComponent = ({type}) => {
  const { inLogin } = useAuth();

  const animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  return (
    <>
      <Swiper
        navigation={{
          prevEl: "#prev5",
          nextEl: "#next5",
        }}
        pagination={{
          clickable: true,
        }}
        onInit={() => {
          animationInit();
        }}
        onSlideChangeTransitionStart={() => animationInit()}
        loop={false}
        id="home-slider"
        className="slider m-0 p-0"
      >
        <SwiperSlide
          className="slide slick-bg1 bg-title44"
          style={{ background: `#14161A` ,backgroundRepeat:'no-repeat',backgroundPosition:'center right',backgroundSize:'100%'}}
        >
          <Container  className="position-relative h-100">
            <div className="slider-inner h-100">
              <Row className="align-items-center  iq-ltr-direction h-100 ">
                <Col xl="7" lg="12" md="12">
                  <div className="d-flex flex-wrap align-items-center">
                    <div
                      className="d-flex align-items-center mt-2 mt-md-3  subTitle"
                      data-iq-gsap="onStart"
                      data-iq-position-x="-200"
                      data-iq-delay="-0.5"
                      style={{ width: "271px", height: "34px" }}
                    >
                      <img style={{ width: "20px" }} src={IconUser} />
                      <span className="ml-3 fs-2">Yıllık Ödemelerde %30 İndirim</span>
                    </div>
                  </div>
                  <h1
                    className="slider-text big-title title text-uppercase text-start"
                    data-iq-gsap="onStart"
                    data-iq-position-x="-200"
                  >
                    11 kategoride 150 den fazla eğitime sınırsız erişim imkanı
                  </h1>
                  <div className="d-flex flex-wrap align-items-center">
                    <p
                      data-iq-gsap="onStart"
                      data-iq-position-y="80"
                      data-iq-delay="0.8"
                      className="text-left"
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                  </div>
                  <div
                    className="d-flex align-items-center r-mb-23"
                    data-iq-gsap="onStart"
                    data-iq-position-y="80"
                    data-iq-delay="0.8"
                  >
                    {inLogin ? (
                      <>
                        <Link
                          to="/sign-up"
                          className="btn btn-hover"
                        >
                          İncele
                        </Link>
                        <Link to="/sign-up" className="btn btn-link">
                          Listene Ekle
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                           to="/sign-up"
                          className="btn btn-hover "
                        >
                         Hesabını oluştur
                        </Link>
                        <Link  to="/sign-up" className="btn btn-link">
                          35 TL / Aylık 
                        </Link>
                      </>
                    )}
                  </div>
                </Col>
                <Col
                  xl="5"
                  lg="12"
                  md="12"
                  className="trailor-video text-center"
                >
                  
                  {type==='classes' &&  <img height={'130%'} src={bg2}/>}
                </Col>
              </Row>
            </div>
          </Container>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
export default  ArticleFooterComponent;
  ;
