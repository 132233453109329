import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../../contexts/RequestContext";
import SelectImg from "../Classroom/SelectImg";
import SelectVideo from "../Classroom/SelectVideo";
import {
  createVideo,
  getVideo,
  updateFileVideo,
  updateThumbnailVideo,
  updateVideo,
} from "../../../Services/Videos";
import {
  notify,
  notifyError,
} from "../../../components/AlartNotify/AlertNotify";
import { getClassroomsPathFile } from "../../../Services/Classroom";

const lessonSchema = Yup.object().shape({
  name: Yup.string().min(3, "En az 3 karakter olmalı!").required(),
  description: Yup.string().min(8).required(),
  order: Yup.number().required(),
  tags: Yup.string(),
  file: Yup.string(),
  download_url: Yup.string(),
  podcast_file_path: Yup.string(),
});

export default function ClassroomVideo({
  video,
  classroom_id,
  classroom_slug,
  handleClose,
  update,
  refetch,
}) {
  const initialValues = {
    ...video,
    name: video?.name,
    description: video?.description,
    classroom_id: classroom_id,
    order: video?.order,
    tags: video?.tags,
    file: video?.file,
    download_url: video?.download_url,
    podcast_file_path: video?.podcast_file_path,
  };
  const [filePath, setFilePath] = useState([]);
  useEffect(() => {
    if (update) {
      (() => {
        getVideo(video.slug)
          .then((res) => {})
          .catch((err) => {});
      })();
    }

    (async () =>
      await getClassroomsPathFile(classroom_slug)
        .then((res) => {
          setFilePath(res?.data);
        })
        .catch((err) => {
          console.log(err);
        }))(classroom_slug);

    return () => {};
  }, []);

  const [thumbnail, setThumbnail] = useState(null);
  let formData = new FormData();
  const formik = useFormik({
    initialValues,
    validationSchema: lessonSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (update) {
        const body = {
          name: values.name,
          order: Number(values.order),
          description: values.description,
          download_url: values?.download_url ? values?.download_url : null,
          podcast_file_path: values?.podcast_file_path
            ? values?.podcast_file_path
            : null,
        };
        await updateVideo(video.slug, body)
          .then((res) => {
            refetch();
            notify("Başarılı Güncellendi!");
            handleClose();
          })
          .catch((err) => {
            notifyError(err);
          });
        if (thumbnail) {
          formData.append("thumbnail", thumbnail);
          await updateThumbnailVideo(video.slug, formData);
        }
        if (values.file) {
          const formData = {
            file: values.file,
          };

          await updateFileVideo(video.slug, formData);
        }
      } else {
        try {
          formData.append("name", values.name);
          formData.append("description", values.description);
          formData.append("classroom_id", classroom_id);
          formData.append("order", values.order);
          formData.append("thumbnail", thumbnail);
          formData.append("file", values?.file);
          formData.append("tags", values.tags);
          formData.append(
            "download_url",
            values?.download_url ? values?.download_url : null
          );

          await createVideo(formData)
            .then((res) => {
              refetch();
              notify("Başarılı Eklendi!");
              handleClose();
            })
            .catch((err) => {
              notifyError(err.response.data.message);
            });
        } catch (error) {}
      }
    },
  });
  return (
    <Row
      className="row align-items-center m-profile justify-content-center h-100"
      style={{ background: "transparent", padding: "20px" }}>
      <Col lg="12">
        <div className="sign-user_card text-left">
          <Row>
            <Col className="device-margin">
              <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate>
                <Row>
                  <Col sm="12">
                    <Form.Group className="">
                      <Row className="ml-1 mb-2">
                        <Form.Label htmlFor="name" style={{ color: "white" }}>
                          Başlık <span className="text-primary">*</span>
                        </Form.Label>
                      </Row>
                      {/* <Row> */}
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps("name")}
                        name="name"
                        className="form-control mb-0"
                        id="exampleInputName"
                        placeholder="Enter Titile"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.name}
                          </span>
                        </div>
                      )}
                      {/* </Row> */}
                    </Form.Group>
                  </Col>

                  <Col sm="12" className="">
                    <Form.Group>
                      <Row className="ml-1 mb-2">
                        <Form.Label
                          htmlFor="description"
                          style={{ color: "white" }}>
                          Açıklama <span className="text-primary">*</span>
                        </Form.Label>
                      </Row>
                      {/* <Row> */}
                      <textarea
                        type="text"
                        {...formik.getFieldProps("description")}
                        name="description"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Enter description"
                        autoComplete="off"
                        required
                        style={{ lineHeight: "25px" }}
                      />
                      {/* </Row> */}

                      {formik.touched.description &&
                        formik.errors.description && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.description}
                            </span>
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="">
                      <Row className="ml-1 mb-2">
                        <Form.Label htmlFor="name" style={{ color: "white" }}>
                          Sırası <span className="text-primary">*</span>
                        </Form.Label>
                      </Row>
                      {/* <Row> */}
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps("order")}
                        name="order"
                        className="form-control mb-0"
                        id="exampleInpuRrder"
                        placeholder="Enter Rrder"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.order && formik.errors.order && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.order}
                          </span>
                        </div>
                      )}
                      {/* </Row> */}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="">
                      <Row className="ml-1 mb-2">
                        <Form.Label htmlFor="name" style={{ color: "white" }}>
                          Tags <span className="text-primary">*</span>
                        </Form.Label>
                      </Row>

                      <Form.Control
                        type="text"
                        {...formik.getFieldProps("tags")}
                        name="tags"
                        className="form-control mb-0"
                        id="exampleInputtags"
                        placeholder="Tags Gir"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.tags && formik.errors.tags && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.tags}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="">
                      <Row className="ml-1 mb-2">
                        <Form.Label htmlFor="name" style={{ color: "white" }}>
                          İndirme linki
                        </Form.Label>
                      </Row>

                      <Form.Control
                        type="text"
                        {...formik.getFieldProps("download_url")}
                        name="download_url"
                        className="form-control mb-0"
                        id="exampleInputdownload_url"
                        placeholder="İndirme linki Gir"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.download_url &&
                        formik.errors.download_url && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.download_url}
                            </span>
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="">
                      <Row className="ml-1 mb-2">
                        <Form.Label htmlFor="name" style={{ color: "white" }}>
                          Podcast linki
                        </Form.Label>
                      </Row>

                      <Form.Control
                        type="text"
                        {...formik.getFieldProps("podcast_file_path")}
                        name="podcast_file_path"
                        className="form-control mb-0"
                        id="exampleInputpodcast_file_path"
                        placeholder="Podcast linki Gir"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.podcast_file_path &&
                        formik.errors.podcast_file_path && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.podcast_file_path}
                            </span>
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <div>
                  <Row>
                    <SelectImg
                      img={video?.thumbnail?.path}
                      setImg={setThumbnail}
                    />

                    <Col sm="6" className="multi">
                      <Form.Label style={{ color: "white" }}>
                        Video Seç <span className="text-primary">*</span>
                      </Form.Label>
                      <select
                        className="mt-2"
                        data-control="select"
                        data-hide-search="true"
                        {...formik.getFieldProps("file")}
                        name="file"
                        defaultValue={"null"}>
                        <option
                          style={{ background: "#000", color: "#fff" }}
                          value={"0"}>
                          Seç
                        </option>
                        {filePath?.map((item, index) => {
                          return (
                            <option
                              style={{ background: "#000", color: "#fff" }}
                              key={index}
                              value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.file && formik.errors.file && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.file}
                            </span>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                  </Row>
                </div>
                <div className="mt-5">
                  <Row>
                    <Col sm="12">
                      <div className="d-flex justify-content-end">
                        <div onClick={handleClose} className="btn btn-link">
                          İptal
                        </div>
                        <button
                          disabled={
                            (formik.isSubmitting ||
                              !formik.isValid ||
                              !formik.touched) &&
                            !update
                          }
                          type="submit"
                          className="btn btn-hover">
                          Kaydet
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
