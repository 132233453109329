import axios from "axios";

const API = process.env.REACT_APP_BASE_ENDPOIND;
const videos = `${API}videos`;

export const getVideo = async (q = "") => {
  const { data } = await axios.get(`${videos}/${q}`);
  return data;
};

export const getLessons = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${videos}?page[number]=${pageParam}&page[size]=${queryKey[1]}`
  );
  return data;
};
export const getLessonsForfilters = async () => {
  const { data } = await axios.get(`${videos}?page[number]=0&page[size]=12`);
  return data;
};
export const deleteLesson = (slug) => {
  return axios.delete(`${videos}/${slug}`).then((response) => response.data);
};
export const createVideo = (body) => {
  return axios.post(videos, body).then((response) => response.data);
};
export const updateVideo = (slug, body) => {
  return axios.put(`${videos}/${slug}`, body).then((response) => response.data);
};

export const updateFileVideo = (slug, body) => {
  return axios
    .post(`${videos}/${slug}/file`, body)
    .then((response) => response.data);
};
export const updateThumbnailVideo = (slug, body) => {
  return axios
    .post(`${videos}/${slug}/thumbnail`, body)
    .then((response) => response.data);
};
export const setVideoWatchCount = async (slug) => {
  const { data } = await axios.get(`${API}video-stats/${slug}`);
  return data;
};
