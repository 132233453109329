import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../../contexts/RequestContext";
import {
  createTeachers,
  updateLogoTeacher,
  updatePhotoTeacher,
  updateTeacher,
} from "../../../Services/TeachersApi";
import "./style.css";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  notify,
  notifyError,
} from "../../../components/AlartNotify/AlertNotify";
import SelectImg from "../Classroom/SelectImg";

const teacherSchema = Yup.object().shape({
  name: Yup.string().min(3, "En az 3 karakter olmalı!").required(),
  description: Yup.string().min(8).required(),
});

export default function TeacAdd({ teach, handleClose, update }) {
  const [isLoading, setIsloading] = useState(false);
  const [categor, setCategories] = useState(teach?.categories);
  const { categories } = useApi();
  const [photo, setPhoto] = useState(null);
  const [logo, setLogo] = useState(null);

  const [selectC, setSelect1] = useState([]);
  const initialValues = {
    ...teach,
    name: teach?.name,
    description: teach?.description,
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateTeacher,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:Teachers");
      notify("Başarılı güncellendi!");
      handleClose();
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.error);
    },
  });
  const mutationPhto = useMutation({
    mutationFn: updatePhotoTeacher,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:Teachers");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationLogo = useMutation({
    mutationFn: updateLogoTeacher,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:Teachers");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationCreate = useMutation({
    mutationFn: createTeachers,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:Teachers");
      notify("Başarılı eklendi!");
      handleClose();
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });

  let formData = new FormData();
  const photeUpdate = () => {
    formData.append("photo", photo);
    mutationPhto.mutate({ slug: teach.slug, formData });
  };
  const logoUpdate = async () => {
    formData.append("logo", logo);
    await mutationLogo.mutate({ slug: teach.slug, formData });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: teacherSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsloading(true);
      if (update) {
        let catTemp = [];
        for (let i = 0; i < categor.length; i++) {
          catTemp.push(categor[i].id);
        }
        if (photo) await photeUpdate();
        if (logo) await logoUpdate();
        const body = {
          name: values?.name,
          description: values?.description,
          categories: catTemp,
        };
        mutation.mutate({ slug: teach.slug, body });
      } else {
        try {
          formData.append("name", values.name);
          formData.append("description", values.description);
          formData.append("photo", photo);
          formData.append("logo", logo);
          for (let i = 0; i < categor.length; i++) {
            formData.append("categories[" + i + "]", categor[i].id);
          }

          mutationCreate.mutate(formData);
        } catch (error) {}
      }
    },
  });

  useEffect(() => {
    if (teach && teach?.categories?.length) {
      for (let i = 0; i < teach?.categories?.length; i++) {
        setSelect1((prev) => [
          ...prev,
          categories?.filter((item) => {
            return teach?.categories?.[i]?.id === item?.id;
          })[0],
        ]);
      }
    }
  }, []);

  return (
    <section className="" style={{ background: "#14161A", overflow: "hidden" }}>
      <Row
        className="row align-items-center m-profile justify-content-center h-100"
        style={{ background: "transparent", padding: "20px" }}
      >
        <Col lg="12">
          <div className="p-4 text-left">
            <Row>
              <Col className="device-margin">
                <Form
                  className="mt-4"
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <Row>
                    <Col sm="6">
                      <Form.Group className="">
                        <Form.Label
                          htmlFor="name"
                          style={{ color: "white" }}
                          className="mb-1"
                        >
                          İsim Soyisim <span className="text-primary">*</span>
                        </Form.Label>

                        <Form.Control
                          type="text"
                          {...formik.getFieldProps("name")}
                          name="name"
                          className="form-control mb-0"
                          id="exampleInputName"
                          placeholder="İsim Gir"
                          autoComplete="off"
                          required
                          //defaultValue={teach?.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.name}
                            </span>
                          </div>
                        )}
                        {/* </Row> */}
                      </Form.Group>
                    </Col>
                    <Col sm="6" className="multi">
                      <Form.Label
                        htmlFor="description"
                        style={{ color: "white" }}
                        className="mb-1"
                      >
                        Kategori Seç <span className="text-primary">*</span>
                      </Form.Label>

                      <Multiselect
                        options={categories} // Options to display in the dropdown
                        selectedValues={selectC} // Preselected value to persist in dropdown
                        onSelect={(event) => {
                          setCategories(event);
                        }} // Function will trigger on select event
                        onRemove={(event) => {
                          setCategories(event);
                        }} // Function will trigger on remove event
                        displayValue="name"
                        // onSearch={function noRefCheck() {}}
                        // showCheckbox // Property name to display in the dropdown options
                        placeholder="Seç"
                        style={{
                          chips: {
                            background: "red",
                          },
                          searchBox: {},
                        }}
                      />
                    </Col>
                    <Col sm="12">
                      <Form.Group>
                        <Form.Label
                          htmlFor="description"
                          style={{ color: "white" }}
                          className="mb-1"
                        >
                          Açıklama <span className="text-primary">*</span>
                        </Form.Label>
                        {/* <Row> */}
                        <textarea
                          type="text"
                          {...formik.getFieldProps("description")}
                          name="description"
                          className="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="Enter description"
                          autoComplete="off"
                          required
                          style={{ lineHeight: "25px" }}
                        />
                        {/* </Row> */}

                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.description}
                              </span>
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <div>
                    <Row>
                      <SelectImg
                        title={" Resim Seç"}
                        img={teach?.photo?.path}
                        setImg={setPhoto}
                      />
                      <SelectImg
                        title={" Logo Seç"}
                        img={teach?.logo?.path}
                        setImg={setLogo}
                      />
                    </Row>
                  </div>
                  <div className="mt-5">
                    <Row>
                      <Col sm="12">
                        <div className="d-flex justify-content-end">
                          <div onClick={handleClose} className="btn btn-link">
                            İptal
                          </div>
                          <button
                            disabled={
                              (formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched ||
                                !photo ||
                                !categor) &&
                              !update
                            }
                            type="submit"
                            className="btn btn-hover"
                          >
                            Kaydet
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  );
}
