import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Modal } from 'react-bootstrap';
import about from '../../../assets/images/lessons/about.png';
import playIcon from '../../../assets/icons/play.png';
import { ModalHeader } from '../../../components/ModalHeader';

import style from './style.module.css';
import { useAuth } from '../../../contexts/AuthContext';
import icon from '../../../assets/video/sample-video.mp4';
import { useApi } from '../../../contexts/RequestContext';
import { delLike, setLike } from '../../../Services/Liked';
import VideoModal from '../../../components/VideoModal';

export default function AboutLesson({ item }) {
  const { inLogin, profile } = useAuth();
  const { videoSet } = useApi();
  const history = useHistory();
  const [toggler1, setToggler1] = useState(false);
  const [more, setMore] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLike, setIsLike] = useState(item?.is_favorite);
  const setLikes = () => {
    if (!isLike) {
      setLike(item?.id, 'classrooms', profile?.id).then(() => {
        setIsLike(!isLike);
      });
    } else {
      delLike(item?.id, 'classrooms', profile?.id).then(() => {
        setIsLike(!isLike);
      });
    }
  };
  const open = () => {
    // if (inLogin) {
    //   videoSet(item);

    //   history.push(`/${item?.teacher?.slug}/${item?.slug}/${item?.videos[0]?.slug}`);
    // } else
    handleShow();
  };
  return (
    <>
      <div className="mt-5">
        <Row>
          <Col xl="6" lg="12" md="12">
            <div
              onClick={open}
              className={style.wsAbout}
              style={{
                background: `url(${item?.banner ? item?.banner?.path : about})`,
                cursor: 'pointer',
              }}
            >
              {/* <img src={item?.banner?.path} height="100%" /> */}
              <div className={style.wsPlay}>
                <div className={style.wsPlayIcon} style={{ width: '70px', height: '70px', zIndex: '99' }}>
                  <img src={playIcon} />
                </div>
              </div>
            </div>
            <div className="position-absolute top-4"></div>
          </Col>
          <Col xl="6" lg="12" md="12" className="text-left">
            <h1
              onClick={open}
              style={{ cursor: 'pointer' }}
              className="h2 slider-text title text-uppercase mt-3 mt-md-0"
              data-iq-gsap="onStart"
              data-iq-position-x="-200"
            >
              {item?.name}
            </h1>
            <div className="d-flex flex-wrap align-items-center">
              <p className="mt-3 mb-3" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                {item?.description}
              </p>
            </div>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="trending-list mb-0 mb-mb-3" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                  <Row>
                    <Col xl="3" lg="12" md="12">
                      <div className="text-muted title starring">Eğitmen</div>
                    </Col>
                    <Col>
                      <span className="text-body">{item?.teacher?.name}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="3" lg="12" md="12">
                      <div className="text-muted title starring">Kategori:</div>
                    </Col>
                    <Col>
                      <span className="text-body">{item?.category?.name}</span>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xl="3" lg="12" md="12">
                      <div className="text-muted title starring">
                        Yüklenme Tarihi:
                      </div>
                    </Col>
                    <Col xl="6" lg="12" md="12">
                      <span className="text-body">09 March 2022</span>
                    </Col>
                  </Row> */}
                </div>
              </Col>
            </Row>

            {inLogin ? (
              <div className="d-flex align-items-center r-mb-23 mt-3" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                <div onClick={open} className="btn btn-hover">
                  <i className="fa fa-play mr-2" aria-hidden="true"></i>İzle
                </div>
                <div onClick={setLikes} className="btn btn-link btn-outline-light ml-3">
                  {isLike ? 'Favorilerden Çıkar' : '+ Favorilere ekle'}
                </div>
                {/* <div className="btn btn-link btn-outline-light ml-3">
                  + Add New Video
                </div> */}
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} centered style={{ background: '#000', width: '100%' }}>
        <Modal.Body style={{ background: '#000', width: '100%' }}>
          <VideoModal handleClose={handleClose} item={item} />
        </Modal.Body>
      </Modal>
    </>
  );
}
