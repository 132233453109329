import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Nav, Tab, Modal } from 'react-bootstrap';
import { getTeachers } from '../../Services/TeachersApi';
import style from './style.module.css';
import { getLikes } from '../../Services/Liked';
import { useAuth } from '../../contexts/AuthContext';
import TeacherModal from '../TeacherModal';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function LikedTeachersSlider(props) {
  const { profile } = useAuth();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [teacher, setTeacher] = useState([]);
  const { isLoading, error, data } = useQuery(['FollowingTeachersSlider', profile?.id, 'teachers'], getLikes);
  useEffect(() => {
    return () => {};
  }, []);
  if (isLoading) return 'Yükleniyor...';

  if (error) return 'An error has occurred: ' + error.message;
  //setTeachers(data.data)

  return (
    <Container>
      <Row>
        <Col sm="12" className="overflow-hidden">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="main-title channel-logo w-75 text-left mt-0">Takip Ettiğin Eğitmenler</h4>
            {data?.data?.length > 0 && (
              <div className="d-flex align-items-center justify-content-between">
                <div style={{ width: '50px' }} className="d-flex align-items-center justify-content-between mr-3">
                  <div id="prev311" className="">
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div id="next311" className="">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                </div>

                <Link className="iq-view-all" to={props.link}>
                  Tümü
                </Link>
              </div>
            )}
          </div>
          <div id="favorites-contens">
            <Swiper
              navigation={{
                prevEl: '#prev311',
                nextEl: '#next311',
              }}
              breakpoints={{
                320: { slidesPerView: 1.8 },
                550: { slidesPerView: 2 },
                991: { slidesPerView: 3 },
                1400: { slidesPerView: 4 },
              }}
              loop={false}
              slidesPerView={5}
              spaceBetween={15}
              as="ul"
              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
            >
              {data?.data?.length < 1 && (
                <div className="d-flex justify-content-center w-100 mt-5">
                  <div>
                    <button disabled={true} className="btn  btn-outline-light">
                      İçerik bulunamadı
                    </button>
                  </div>
                </div>
              )}
              {data?.data?.map((teacher, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    as="li"
                    style={{
                      maxWidth: '235px',
                      minHeight: '320px',
                      marginRight: '15px',
                    }}
                  >
                    <div
                      onClick={() => {
                        handleShow1();
                        setTeacher(teacher);
                      }}
                      className="image-box1 cursor-pointer"
                      style={{ borderRadius: '10px' }}
                    >
                      {teacher?.photo?.path && (
                        // <img
                        //   width="270"
                        //   height="290"
                        //   src={`${teacher?.photo?.path}`}
                        //   className="img-fluid attachment-large size-large"
                        //   alt=""
                        //   loading="lazy"
                        //   style={{ borderRadius: "10px" }}
                        // />
                        <div
                          className={`${style.teacherBg}`}
                          style={{
                            maxWidth: '270px',
                            maxHeight: '290px',
                            background: `url(${teacher?.photo?.path})`,
                          }}
                        ></div>
                      )}

                      {!teacher?.photo?.path && (
                        <img src='"https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"' />
                      )}
                    </div>
                    <div className="icon-box-content mt-3">
                      <div className="widget-container d-flex flex-column text-left">
                        <div
                          onClick={() => {
                            handleShow1();
                            setTeacher(teacher);
                          }}
                          className="heading-title size-default"
                        >
                          {teacher?.name}
                        </div>
                        <p className="text-muted">{teacher?.categories ? teacher?.categories[0]?.name : ''}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Col>
      </Row>
      <Modal size="lg" show={show1} onHide={handleClose1} centered className="border-1">
        <Modal.Body style={{ background: '#14161A', overflow: 'hidden' }} className="border-0">
          <TeacherModal item={teacher} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
    </Container>
  );
}
