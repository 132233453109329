import "./components/style.css";
import OTPCode from "./components/OTPCode";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

export default function VerifyCode() {
  return (
    <section>
      <div className="App">
        <div style={{ paddingBottom: "25%" }}>
          <h4 style={{ paddingTop: "200px" }}>Verify your phone number</h4>
          <p>
            We'll text a verification code to your phone and then you'll enter
            it to <br />
            verify your phone
          </p>
          <OTPCode />
          <Row className="mt-5 d-flex justify-content-center">
            <Col lg="5">
              <Link to="#" className="btn btn-hover w-50">
                Submit
              </Link>
            </Col>
          </Row>
          <Row className="mt-3 justify-content-center">
            <Col lg="5">
              <Link to="#" className="btn btn-link w-50 ">
                  <span className="text-left text-decoration-none">
                  Send again
                  </span>
               
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}
