import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import { getClassrooms } from '../../Services/Classroom';
import 'swiper/swiper-bundle.css';
import LiderSlide from './LiderSlide';
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function ClassesSlider({ prev, next, query, visible }) {
  const [classroom, setClassroom] = useState([]);
  const { isLoading, error, data } = useQuery([`Discover1:${query}`], () => getClassrooms(query));

  useEffect(() => {
    if (data?.data?.length > 0) {
      setClassroom(data?.data);
      visible(true);
    } else {
      visible(false);
    }

    return () => {};
  }, [data]);
  if (isLoading) return 'Yükleniyor...';

  if (error) return 'An error has occurred: ' + error.message;
  return (
    <section id="home" className="pt-1 pt-xl-1">
      <Swiper
        navigation={{
          prevEl: `#${prev}`,
          nextEl: `#${next}`,
        }}
        breakpoints={{
          // 320: { slidesPerView: 0.8 },
          430: { slidesPerView: 1 },
          550: { slidesPerView: 1.1 },
          991: { slidesPerView: 2 },
          1400: { slidesPerView: 2.5 },
        }}
        loop={true}
        spaceBetween={20}
        as="ul"
        className="favorites-slider list-inline row p-0 m-0"
      >
        {classroom?.map((item) => {
          return (
            <SwiperSlide key={item?.id} as="li">
              <LiderSlide item={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}
