import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import style from './style.module.css';
export default function Answer2({ item, handleAddItem }) {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
    handleAddItem(item?.id);
  };

  return (
    <Col sm="6" className="mt-0 mt-md-4 mb-4 mb-md-4">
      <div onClick={handleChange} className={`${style.wsAnswerCardImg} ${checked ? style.wsActiveCard : ''}`}>
        {item?.name}
      </div>
    </Col>
  );
}
