import React, { useState } from "react";
import { Col, Modal } from "react-bootstrap";
import "./style.css";
import verMenu from "../../../assets/icons/icons-more-vertical.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAuth } from "../../../contexts/AuthContext";
import Swal from "sweetalert2";
import AddAvatar from "./AddAvatar";
import { ModalHeader } from "../../../components/ModalHeader";
import { deleteAvatar } from "../../../Services/AvatarApi";
import {
  notify,
  notifyError,
} from "../../../components/AlartNotify/AlertNotify";

export default function ItemAvatar({ avatar, refetch }) {
  const { Profile, Refresh } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function del(id) {
    Swal.fire({
      title: "Onayla!",
      text: "Profili silmek istediğinden emin misin?",
      confirmButtonColor: "#be161a",
      confirmButtonText: "Evet, Sil",
      background: "#000",
      color: "#fff",
      showCancelButton: true,
      customClass: {
        closeButton: "btn btn-outline-light",
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteAvatar(id)
          .then((data) => {
            notify("Başarılı silindi!");
            refetch();
          })
          .catch((err) => {
            notifyError(err);
          });
      } else if (result.isDenied) {
      }
    });
  }
  return (
    <>
      {" "}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Col className="text-center">
        <div className="ws-item-wrapper">
          <div className="ws-item ">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="ws-item-menu"
            >
              <DropdownToggle tag="div">
                <img src={verMenu} />
              </DropdownToggle>
              <DropdownMenu className="bg-dark ">
                <DropdownItem
                  className="text-white h2 m-0"
                  onClick={handleShow}
                >
                  <i className="fa fa-pencil mr-2"></i> Düzele
                </DropdownItem>

                <DropdownItem
                  onClick={() => del(avatar.id)}
                  className="text-white h2 m-0"
                >
                  <i className="fa fa-trash mr-2"></i> Sil
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <img width="100%" height="100%" src={avatar?.file?.path} />
          </div>
          <div className="mt-4 btn-hover-primary">{avatar?.name}</div>
        </div>
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Body style={{ background: "#14161A" }}>
            <ModalHeader
              handleClose={handleClose}
              titleHeader={"Avatar Güncelle"}
            />
            <AddAvatar
              avatar={avatar}
              handleClose={handleClose}
              update={true}
              refetch={refetch}
            />
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
}
