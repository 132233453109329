import React, { useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { useApi } from '../../../contexts/RequestContext';
import style from './style.module.css';
import Swal from 'sweetalert2';
import { deleteCategory } from '../../../Services/Category';
import { ModalHeader } from '../../../components/ModalHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCategory from './AddAvatarCategory';
import { notify, notifyError } from '../../../components/AlartNotify/AlertNotify';
import AddAvatarCategory from './AddAvatarCategory';
import { deleteAvatarCategory } from '../../../Services/AvatarApi';

const AvatarCategory = ({ item, refetch }) => {
  const { Refresh } = useApi();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const del = (id) => {
    Swal.fire({
      title: 'Onayla!',
      text: 'Silmek istediğine emin misin?',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, Sil',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAvatarCategory(id)
          .then((res) => {
            notify('Başarılı  silindi!');
            refetch();
          })
          .catch((err) => {
            notifyError(err.response.data.error);
          });
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Col sm="6" className="mt-4 mb-4">
        <div className={`${style.wsAnswerCard} `}>
          <div className="custom-control custom-checkbox w-100 d-flex justify-content-between align-items-center">
            <span>{item?.name}</span>
            <div className="d-flex">
              <div onClick={handleShow} className="text-white h2  btn-link mr-2" style={{ cursor: 'pointer' }}>
                <i className="fa fa-pencil mr-2"></i>
              </div>

              <div onClick={() => del(item.id)} className="text-white h2 btn-link" style={{ cursor: 'pointer' }}>
                <i className="fa fa-trash mr-2"></i>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Body style={{ background: '#14161A' }}>
            <ModalHeader handleClose={handleClose} titleHeader={`Kategori'yi Güncelle`} />
            <AddAvatarCategory category={item} handleClose={handleClose} update={true} refetch={refetch} />
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
};

export default AvatarCategory;
