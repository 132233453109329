import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import style from './style.module.css';
import './style.css';
import { useAuth } from '../../../contexts/AuthContext';
import { sendMessages } from '../../../Services/Support';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifyError } from '../../../components/AlartNotify/AlertNotify';

const loginSchema = Yup.object().shape({
  name: Yup.string().required('Ad ve Soyad zorunlu alan!'),
  email: Yup.string()
    .email('Geçerli bir e-posta olmalı!')
    .min(3, 'E-posta en az 3 karakter olmalı!')
    .max(50, 'En fazla 50 karakter olmalı!')
    .required('E-posta zorunlu alan!'),
  message: Yup.string().required('Mesaj zorunlu alan!'),
  category: Yup.string().required('Zorunlu alan!'),
});
const notify = (text) => {
  toast.success(text, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export default function ContactForm() {
  const { user } = useAuth();
  const initialValues = {
    name: '',
    email: user?.email,
    message: '',
    category: 'Üyelik',
  };
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      sendMessages(values)
        .then((res) => {
          notify(res?.message);
          resetForm();
        })
        .catch((err) => {
          notifyError(err.response.data.error);
        });
    },
  });
  return (
    <div className={`sign-user_card ${style.wsContactCard}`}>
      <div className="sign-in-page-data text-left p-3">
        <div className="sign-in-from w-100 m-auto">
          <h5 className="mb-3 text-left channel-logo" style={{ fontSize: '22px', fontWeight: 'bold' }}>
            Bize Ulaşın
          </h5>
          <h6 className="mb-3 text-left">İletişim bilgilerini paylaşın, hemen dönüş yapalım.</h6>
          <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate id="suppport-form">
            <Form.Group>
              <Form.Control
                type="text"
                {...formik.getFieldProps('name')}
                name="name"
                className="form-control mb-0"
                id="exampleInputName"
                placeholder="Ad Soyad"
                required
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-primary" role="alert">
                      {formik.errors.name}
                    </span>
                  </div>
                </div>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="email"
                {...formik.getFieldProps('email')}
                name="email"
                className="form-control mb-0"
                id="exampleInputEmail1"
                placeholder="E-posta"
                autoComplete="off"
                required
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <span className="text-primary" role="alert">
                    {formik.errors.email}
                  </span>
                </div>
              )}
            </Form.Group>

            {/* <Select
                              className=" "
                              placeholder="+90"
                              value={data2.filter((obj) =>
                                selectedValue.includes(obj.value)
                              )} // set selected values
                              {...formik.getFieldProps("code")}
                              name="code"
                              options={data2} // set list of the data
                              isClearable
                            /> */}
            <div className="styleSelect mb-3">
              <select className="" {...formik.getFieldProps('category')} name="category">
                <option
                  style={{
                    background: '#000',
                    color: '#fff',
                  }}
                  value="Üyelik"
                >
                  Üyelik
                </option>
                <option
                  style={{
                    background: '#000',
                    color: '#fff',
                  }}
                  value="Ödemeler"
                >
                  Ödemeler
                </option>
                <option
                  style={{
                    background: '#000',
                    color: '#fff',
                  }}
                  value="Teknik Destek"
                >
                  Teknik Destek
                </option>
              </select>
              {formik.touched.category && formik.errors.category && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-primary" role="alert">
                      {formik.errors.category}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <Form.Group>
              <Form.Control
                as="textarea"
                {...formik.getFieldProps('message')}
                name="message"
                rows="2"
                className="form-control mb-0"
                id="exampleInputText"
                placeholder="Mesajın…"
                required
              />
              {formik.touched.message && formik.errors.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="text-primary" role="alert">
                      {formik.errors.message}
                    </span>
                  </div>
                </div>
              )}
            </Form.Group>

            <div>
              <Button type="submit" className="btn btn-hover btn-primary1 w-100">
                Gönder
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
