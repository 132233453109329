import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// Home
import Homepage from "../Pages/Home";
import Login from "../Pages/Auth/Signin";

import SignUp from "../Pages/Auth/Signup";
import Category from "../Pages/Categories";
import LessonsDetail from "../Pages/LessonsDetail";
import EditProfile from "../Pages/Profile/EditProfile";
import SetPassword from "../Pages/Auth/Signup/SetPassword";
import SelectPlan from "../Pages/Auth/Signup/SelectPlan";
import SetBankCard from "../Pages/Auth/Signup/SetBankCard";
import ResetPassword from "../Pages/Auth/ResetPassword/SetPassword";
import SendCodeToMail from "../Pages/Auth/ResetPassword";
import WhoIsWatch from "../Pages/CreateProfile/WhoIsWatch";

import VerifyCode from "../Pages/Profile/VerifyCode";

import AddProfile from "../Pages/CreateProfile/AddProfile";
import Teachers from "../Pages/Teachers";
import Survey from "../Pages/Survey";
import PracingPlan from "../Pages/PracingPlans";
import MyProgress from "../Pages/MyProgress";
import Completed from "../Pages/MyProgress/Completed";
import AllNotes from "../Pages/MyProgress/AllNotes";
import FollowingTeachers from "../Pages/MyProgress/FollowingTeachers";
import MyCertificatesPage from "../Pages/MyProgress/MyCertificatesPage";
import SavedClasses from "../Pages/MyProgress/SavedClasses";
import Watching from "../Pages/Watching";
import TeacherDetail from "../Pages/TeacherDetail.js";
import AboutUs from "../Pages/AboutUs";
import Support from "../Pages/Support";
import ContactUs from "../Pages/ContactUs";
import PrivacyPolicy1 from "../Pages/PrivacyPolicy";
import TermsConditions from "../Pages/TermsConditions";
import TeacherAdd from "../Pages/Admin/Teacher/addTeach";
import ClassroomAdd from "../Pages/Admin/Classroom/AddClassroom";

import AdminPage from "../Pages/Admin/indix";
import Search from "../Pages/Search/Seacrh";
import Article from "../Pages/Article";
import SinglePage from "../Pages/Article/SingPage";
import AccountSettings from "../Pages/AccountSettings";
import SignUpMobile from "../Pages/Auth/SignupMobile";
import OnlineConditions from "../Pages/OnlineConditions";

const Layout1Route = () => {
  let location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        //  key={location.key}
        classNames="fade"
        timeout={300}>
        <Switch location={location}>
          {/* homepage */}
          <Route path="/" exact component={Homepage} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/sign-up-mobile" component={SignUpMobile} />
          <Route path="/login" component={Login} />
          <Route path="/profile" component={EditProfile} />
          <Route path="/verify-code" component={VerifyCode} />
          <Route path="/set-password" component={SetPassword} />
          <Route path="/select-plan" component={SelectPlan} />
          <Route path="/set-card" component={SetBankCard} />

          <Route path="/send-mail" component={SendCodeToMail} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/who-is-watch" component={WhoIsWatch} />
          <Route path="/add-profile" component={AddProfile} />
          <Route path="/teachers" component={Teachers} />
          <Route path="/survey" component={Survey} />
          <Route path="/uyelik-planlari" exact component={PracingPlan} />
          <Route path="/my-progress" component={MyProgress} />
          <Route path="/completed" component={Completed} />
          <Route path="/all-my-notes" component={AllNotes} />
          <Route path="/following-teachers" component={FollowingTeachers} />
          <Route path="/my-certificates" component={MyCertificatesPage} />
          <Route path="/sevad-classes" component={SavedClasses} />
          {/* <Route path="/watching/:slug" component={Watching}/> */}
          <Route path="/teacher-detail" component={TeacherDetail} />
          <Route path="/hakkimizda" component={AboutUs} />
          <Route path="/sikca-sorulan-sorular" component={Support} />
          <Route path="/iletisim" component={ContactUs} />
          <Route path="/gizlilik-politikasi" component={PrivacyPolicy1} />
          <Route path="/uyelik-sozlesmesi" component={TermsConditions} />
          <Route path="/online-terms-conditions" component={OnlineConditions} />
          <Route path="/add-teacher" component={TeacherAdd} />
          <Route path="/add-classroom" component={ClassroomAdd} />

          <Route path="/admin-page" component={AdminPage} />
          <Route path="/search" component={Search} />
          <Route path="/article" component={Article} />
          <Route path="/single-page" component={SinglePage} />
          <Route path="/account-settings" component={AccountSettings} />
          <Route path="/:techar/:class/:lesson" exact component={Watching} />
          <Route path="/:techar/:slug" exact component={LessonsDetail} />
          <Route path="/:slug" exact component={Category} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Layout1Route;
