import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NoteItem from './components/NoteItem';
import { getAllNotes } from '../../Services/Note';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAuth } from '../../contexts/AuthContext';
import Pagination from '../../components/UI/pagination/Pagination';
export default function AllNotes() {
  const { profile } = useAuth();
  const { status, data, error, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(['AllNotes', profile?.id, ''], getAllNotes, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page) return lastPage?.meta?.current_page + 1;
    },
  });

  return (
    <div>
      <div className="iq-breadcrumb-one   iq-over-dark-50">
        <Container>
          <Row>
            <Col md="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                <div className="w-50">
                  <h4 className="main-title channel-logo w-100 text-left">Tüm Notlarım</h4>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between mr-3">
                    <div to="/profile" className="btn btn-outline-light">
                      Son İzlediklerin
                    </div>
                  </div>

                  <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link>
                </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            {status === 'loading' ? (
              <p>Yükleniyor...</p>
            ) : status === 'error' ? (
              <span>Hata oluştu: {error.message}</span>
            ) : (
              <>
                {data?.pages.map((page) => (
                  <React.Fragment key={page.nextId}>
                    {page?.data?.map((item) => (
                      <Col key={item?.id} md="12" className="mb-4">
                        <NoteItem type="page" item={item} />
                      </Col>
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
            {/* {notes?.map((item) => {
              return (
                <Col key={item?.id} sm="12" className="mb-4">
                  <NoteItem type="page" item={item} />
                </Col>
              );
            })} */}
          </Row>
          <Pagination hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </Container>
      </div>
    </div>
  );
}
