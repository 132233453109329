import React from "react"
import closeIcon from '../../assets/icons/x.png'


const ModalHeader = ({handleClose,titleHeader}) => {


  return (
    <div className=''>
          {/* begin::Close */}
    <div className="d-flex justify-content-end">
    <div
        className='p-1'
        onClick={handleClose}
        style={{cursor: 'pointer',zIndex:'999'}}
      >
      
 

      <img width='30'  src={closeIcon}/>
      </div>
    </div>
    
      {/* end::Close */}
      {/* begin::Modal title */}
      <h5 className='fw-bolder text-center mb-10'>{titleHeader}</h5>
      {/* end::Modal title */}

    
    </div>
  )
}

export {ModalHeader}