import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { useQuery } from "@tanstack/react-query";
import { getClassrooms } from "../../../Services/Classroom";
import "./style.css";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);
export default function VerticalSlider() {
  const { isLoading, error, data } = useQuery(["Discover1"], () =>
    getClassrooms("")
  );

  if (isLoading) return "Yükleniyor...";

  if (error) return "An error has occurred: " + error.message;
  return (
    <div
      id="favorites-contens"
      className="position-reletive"
      style={{ height: "100%", maxHeight: "590px" }}>
      <div
        id="prevV"
        className="position-absolute d-flex justify-content-center w-100"
        style={{ top: "20px", zIndex: "999" }}>
        <div
          className="p-1 border border-1 border-light"
          style={{
            borderRadius: "50%",
            background: "#010101",
            width: "40px",
            height: "40px",
          }}>
          <i className="fa fa-chevron-up"></i>
        </div>
      </div>
      <div
        id="nextV"
        className="position-absolute d-flex justify-content-center w-100 p-3"
        style={{ bottom: "-15px", zIndex: "999" }}>
        <div
          className="p-2 border border-2 border-light"
          style={{
            borderRadius: "50%",
            background: "#010101",
            width: "40px",
            height: "40px",
          }}>
          <i className="fa fa-chevron-down"></i>
        </div>
      </div>

      <Swiper
        direction={"vertical"}
        navigation={{
          prevEl: "#prevV",
          nextEl: "#nextV",
        }}
        breakpoints={{
          320: { slidesPerView: 5.5 },
          550: { slidesPerView: 5.5 },
          991: { slidesPerView: 5.5 },
          1400: { slidesPerView: 5.5 },
        }}
        mousewheel={true}
        loop={false}
        slidesPerView={5.5}
        spaceBetween={20}
        as="ul"
        className="favorites-slider list-inline  row p-0 m-0  overflow-hidden h-100">
        {data?.data?.map((item, index) => {
          return (
            <SwiperSlide key={index} as="li" className="h-100">
              <div
                className="position-relative overflow-hidden "
                style={{ borderRadius: "10px", maxHeight: "300px" }}>
                <div className="img-box ">
                  <img src={item?.banner?.path} className="img-fluid " alt="" />
                </div>
                <div className="bg-shadow"></div>

                <div
                  className="block-description  d-flex justify-content-center align-items-end pt-4 pb-5"
                  style={{ right: "20px" }}>
                  <div
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "20px",
                      maxWidth: "250px",
                    }}
                    className="bg-primary text-center">
                    {item?.category?.name}
                  </div>

                  <div className="movie-time d-flex align-items-start text-end flex-column my-2 iq-ltr-direction">
                    <h3 className="iq-title text-white">{item?.name}</h3>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
