import axios from "axios";

const API = process.env.REACT_APP_BASE_ENDPOIND;
const categories = `${API}categories`;

export const getCategories = async () => {
  const { data } = await axios.get(`${categories}`);
  return data;
};
export const getAdminCategories = async () => {
  const { data } = await axios.get(`${categories}?list_all=1`);
  return data;
};
export const createCategory = (body) => {
  return axios.post(categories, body).then((response) => response.data);
};

export const updateCategory = (item) => {
  const { slug, body } = item;
  return axios
    .put(`${categories}/${slug}`, body)
    .then((response) => response.data);
};
export const updateCategoryBanner = (item) => {
  const { slug, body } = item;
  return axios
    .post(`${categories}/${slug}/banner`, body)
    .then((response) => response.data);
};
export const deleteCategory = (slug) => {
  return axios
    .delete(`${categories}/${slug}`)
    .then((response) => response.data);
};
