import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import style from './style.module.css';
export default function Answer1({ item, handleAddItem }) {
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    setChecked(!checked);
    handleAddItem(e.target.value);
  };

  return (
    <Col sm="6" className="mt-0 mt-md-4 mb-4 mb-md-4">
      <div className={style.wsAnswerCard}>
        <div className="custom-control custom-checkbox d-inline-block">
          <input
            type="checkbox"
            className="custom-control-input"
            defaultChecked={checked}
            id={`customCheck${item?.id}`}
            value={item?.id}
            onChange={handleChange}
          />
          <label className="custom-control-label" htmlFor={`customCheck${item?.id}`}>
            {item?.name}
          </label>
        </div>
      </div>
    </Col>
  );
}
