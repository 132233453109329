import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingPage = (props) => {
  const winWidth = window.screen.width - window.screen.width / 10;
  const winHeight = window.screen.height - (window.screen.height * 20) / 100;
  return (
    <div>
      <ContentLoader
        speed={1}
        width={winWidth}
        height={winHeight}
        // viewBox="0 0 400 460"
        backgroundColor="#3b3b3b"
        foregroundColor="#292929"
        {...props}
        className="m-auto"
      >
        <rect x="0" y="0" rx="2" ry="2" width={winWidth} height="600" />
      </ContentLoader>
    </div>
  );
};

export default LoadingPage;
