import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../../contexts/RequestContext";
import {
  notify,
  notifyError,
} from "../../../components/AlartNotify/AlertNotify";
import {
  createAvatarCategory,
  updateAvatarCategory,
} from "../../../Services/AvatarApi";

const teacherSchema = Yup.object().shape({
  name: Yup.string().min(3, "En az 3 karakter olmalı!").required(),
  order: Yup.number().required(),
});

export default function AddAvatarCategory({
  category,
  handleClose,
  update,
  refetch,
}) {

  const initialValues = {
    ...category,
    name: category?.name,
    order: category?.order,
    parent_id: category?.parent_id,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: teacherSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (update) {
        const body = {
          name: values.name,
          order: Number(values.order),
        };

        updateAvatarCategory(category.id, body)
          .then((res) => {
            notify("Başarılı  güncellendi!");
            refetch();
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            notifyError(err?.response?.data?.error);
          });
      } else {
        try {
          const body = {
            name: values.name,
            order: Number(values.order),
          };
          console.log(body)

          await createAvatarCategory(body)
            .then((res) => {
              notify("Başarılı  eklendi!");
              refetch();
              handleClose();
            })
            .catch((err) => {
              notifyError(err.response.data.message);
            });
        } catch (error) {  console.log("dkf k ")}
      }
    },
  });

  return (
    <>
      <section className="" style={{ background: "transparent" }}>
        <Row
          className="row align-items-center m-profile justify-content-center h-100"
          style={{ background: "transparent", padding: "20px" }}
        >
          <Col lg="12">
            <div className="p-5 text-left">
              <Row>
                <Col className="device-margin">
                  <Form
                    className="mt-4"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <Row>
                      <Col sm="6">
                        <Form.Group className="">
                          <Row className="ml-1 mb-2">
                            <Form.Label
                              htmlFor="name"
                              style={{ color: "white" }}
                            >
                              Kategori İsmi{" "}
                              <span className="text-primary">*</span>
                            </Form.Label>
                          </Row>
                          {/* <Row> */}
                          <Form.Control
                            type="text"
                            {...formik.getFieldProps("name")}
                            name="name"
                            className="form-control mb-0"
                            id="exampleInputName"
                            placeholder="Kategori İsmini gir"
                            autoComplete="off"
                            required
                            //defaultValue={teach?.name}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.name}
                              </span>
                            </div>
                          )}
                          {/* </Row> */}
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group className="">
                          <Row className="ml-1 mb-2">
                            <Form.Label
                              htmlFor="name"
                              style={{ color: "white" }}
                            >
                              Kategori Sırası{" "}
                              <span className="text-primary">*</span>
                            </Form.Label>
                          </Row>
                          {/* <Row> */}
                          <Form.Control
                            type="text"
                            {...formik.getFieldProps("order")}
                            name="order"
                            className="form-control mb-0"
                            id="exampleInputOrder"
                            placeholder="Sırasını gir"
                            autoComplete="off"
                            required
                            //defaultValue={teach?.name}
                          />
                          {formik.touched.order && formik.errors.order && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.order}
                              </span>
                            </div>
                          )}
                          {/* </Row> */}
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <Row>
                        <Col sm="12">
                          <div className="d-flex justify-content-end">
                            <div onClick={handleClose} className="btn btn-link">
                              İptal
                            </div>
                            <button
                              disabled={
                                (formik.isSubmitting ||
                                  !formik.isValid ||
                                  !formik.touched) &&
                                !update
                              }
                              type="submit"
                              className="btn btn-hover"
                            >
                              Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
