import React from "react";

const Pagination = ({ hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  return (
    <div className="d-flex justify-content-center">
      <div>
        {hasNextPage ? (
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            className="btn  btn-outline-light">
            {isFetchingNextPage
              ? "Yükleniyor..."
              : hasNextPage
              ? "Daha Fazla"
              : ""}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Pagination;
