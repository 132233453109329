import React from "react";
import itemImg from "../../../assets/images/article/articleItem.png";
import { Link } from 'react-router-dom';
import '../style.css'
const Blog = (props) => {
  return (
    <div>
      <article className="ws-blog">
        <div className="iq-blog-box text-left ">
          <div className="iq-blog-image">
            <img width="100%" height="auto" src={itemImg} alt="" />
          </div>
          <div className="iq-blog-detail px-3">
            <div className="iq-blog-meta">
             <span className="text-primary text-uppercase" style={{letterSpacing: '4px'}}>Community</span>
            </div>
            <div className="blog-title mt-2 mb-4">
              <h6 className="entry-title ">
                <Link className="h5" to="/single-page">Give Your Photos and Videos a Dose of Weird</Link>
              </h6>
            </div>
            <div className="blog-content">
              <p className="tet-muted">
              When it comes to being creative with photos and video…
              </p>
            </div>
          </div>
        </div>
    
      </article>
    </div>
  );
};

export default Blog;
