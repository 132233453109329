import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import AboutFooter from "./components/AboutFooter";

import style from "./style.module.css";

export default function AboutUs() {
  return (
    <div className={`${style.wsFooterCard}`}>
      <Row>
        <Col sm="12">
          <PageTitle title={"Hakkımızda"} subTitile={""} />
        </Col>
      </Row>
      <Container>
        <Row>
          <Col xs="12" sm="12" className="text-left p-3 ">
            {/* <span className="text-uppercase text-primary ">Hakkımızda</span> */}
            {/* <h3 className="mt-4">
              Lorem ipsum dolor sit amet, sectetur adipiscing elit
            </h3> */}
            <p className="mt-4">
              Genç yaşta yolu entelektüel camianın çok kıymetli isimleriyle
              kesişmiş, onların vizyonlarına şahitlik ede ede kendimizi
              “gelişime” ve daima “ilerleme”ye adamış küçük bir ekip olarak yola
              çıktık. Öyle kıymetli tavsiyeler, öyle farklı bir vizyon ve ilham
              kaynağıydılar ki istedik ki ülkemizin tüm gençleri ve ebeveynleri
              de bu engin dünyadan faydalanabilsin, bizimle yürüsün. İlham
              aldığımız, onlar gibi çalışmak ve fark yaratmak istediğimiz,
              tavsiyelerini soluksuz dinlediğimiz kişilerle toplumu biraz daha
              yaklaştırmanın bir ihtiyaç olduğunu gördük.
              <br />
              <br />
              <br />
              Wise&Rise ailesi olarak, bu kıymetli kişilerin çalışma
              hayatlarını, ilham arayışlarını, bir ömür adadıkları tutkularını
              ve damıttıkları deneyimlerini dünyaya ulaştırma niyetiyle bu
              platformu hayata geçirdik. Alanının yıldızlarının hayata
              bakışlarını, neyi nasıl başardıklarını ve neyi farklı yaptıklarını
              birinci ağızdan dinleyip kendi hayatlarımıza uyarlamanın başarıyı
              beraberinde getireceğine inanıyoruz. Şüphesiz merakını hiç
              yitirmeyen, sorup sorgulayan, farklı bir açıdan bakabilen,
              öğrenciliğin hiç bitmeyen bir yolculuk olduğunu idrak eden her
              insan mutlaka potansiyelini keşfeder. Sizlerle ekibimizi
              genişletip bir aile olarak hep beraber ilerlemeye devam edeceğimiz
              için çok heyecanlıyız.
            </p>
          </Col>
        </Row>
        <AboutFooter />
      </Container>
    </div>
  );
}
