import axios from 'axios';


const API = process.env.REACT_APP_BASE_ENDPOIND;
const notifications = `${API}notifications`;



export const getNotifications = async () =>{
    return await axios.get(`${notifications}`);
} 
export const createNotifications = async (body) =>{
    return await axios.post(notifications,body);
} 
export const updateNotifications = async () =>{
    return await axios.put(`${notifications}`);
} 