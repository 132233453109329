import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import iconMail from '../../../assets/icons/icons-mail.png';
import iconLocation from '../../../assets/icons/icons-map-pin.png';
import ContactForm from './ContactForm';
import style from './style.module.css';

export default function AboutFooter({ classname }) {
  return (
    <div className={` ${classname != 'contact' ? style.wsFooterCard : 'px-4'}`}>
      <Container>
        <Row>
          <Col xs="12" sm="6" className="text-left d-flex flex-column justify-content-center mb-5">
            <div className="text-uppercase text-primary main-title channel-logo">
              <span style={{ color: '#fff', fontWeight: 'bolder', fontSize: '18px' }}>İLETİŞİM</span>
            </div>
            {/* <h5 className="mt-4">Bize Ulaş</h5> */}
            <p className="w-75 pt-2 pb-3" style={{ fontSize: '13px' }}>
              Aşağıdaki bilgiler ile bizimle iletişime geçebilirsin.
            </p>

            <div>
              <div className="d-flex align-items-center mb-2">
                <div className="mr-3">
                  <img width={'20px'} src={iconMail} />
                </div>
                <div>
                  <span className="text-uppercase text-primary " style={{ fontSize: '14px' }}>
                    E-POSTA
                  </span>
                  <h6 className="mt-1 fs-6">info@wisenrise.com</h6>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <div className="mr-3">
                  <img width={'20px'} src={iconLocation} />
                </div>
                <div>
                  <span className="text-uppercase text-primary " style={{ fontSize: '14px' }}>
                    adres
                  </span>
                  <h6 className="mt-1 fs-6">Akat Mah. Cebeci Cad. No:65 Beşiktaş/İstanbul</h6>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" sm="6">
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
