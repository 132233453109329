import React from "react";
import style from "./success.module.css";
const SuccessPage = (props) => {
  return (
    <div className={style.card}>
      <div>
        <i className={style.checkmark}>✓</i>
      </div>
      {/* <h1 className={style.h1}>Başarılı Oluşturuldu!</h1> */}
      <p className={style.p}>
      Başarılı Oluşturuldu,
      <br />
        Mobil uygulamada devam et!
     
      </p>
    </div>
  );
};

export default SuccessPage;
