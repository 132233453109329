import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import menu from '../../assets/icons/icons-more-vertical.png';
import { useAuth } from '../../contexts/AuthContext';
import TeacAdd from '../../Pages/Admin/Teacher/addTeach';
import { ModalHeader } from '../ModalHeader';
import Swal from 'sweetalert2';
import { deleteTeacher } from '../../Services/TeachersApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { delLike, setLike } from '../../Services/Liked';
import TeacherModal from '../TeacherModal';
import { notify, notifyError } from '../AlartNotify/AlertNotify';
import ShereBtn from '../ShereBtn';

export default function TeacherItem({ teacher, type }) {
  const { inLogin, isAdmin, profile } = useAuth();
  const [isLike, setIsLike] = useState(teacher.is_favorite);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [show, setShow] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const queryClient = useQueryClient();
  const mutation = useMutation((id) => deleteTeacher(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('Admin:Teachers');
      notify('Başarılı Silindi!');
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.error);
    },
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const del = (id) => {
    Swal.fire({
      title: 'Onayla!',
      text: 'Silmek istediğine emin misin?',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, Sil',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        mutation.mutate(id);
      } else if (result.isDenied) {
      }
    });
  };
  const setLikes = () => {
    if (!isLike) {
      setLike(teacher.id, 'teachers', profile?.id).then(() => {
        setIsLike(!isLike);
      });
    } else {
      delLike(teacher.id, 'teachers', profile?.id).then(() => {
        setIsLike(!isLike);
      });
    }
  };

  return (
    <div
      className="text-center"
      style={{
        height: 'auto',
        marginRight: '15px',
        margin: 'auto',
        position: 'relative',
      }}
    >
      {inLogin && !isAdmin && (
        <div className={style.heart}>
          {isLike ? (
            <i onClick={setLikes} style={{ color: 'red', fontSize: '20px' }} className="fa fa-heart"></i>
          ) : (
            <i onClick={setLikes} style={{ fontSize: '20px' }} className="fa fa-heart"></i>
          )}
        </div>
      )}
      {isAdmin && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className={style.wsCardMenuIcon}>
          <DropdownToggle tag="div">
            <img width={'20px'} src={menu} alt="" />
          </DropdownToggle>
          <DropdownMenu className="bg-dark ">
            {/* <DropdownItem className="text-white h2">
              {" "}
              <Link to={`/lesson-detail/${teacher?.slug}`}>
                {" "}
                <i className="fa fa-eye mr-2"></i>İncele{" "}
              </Link>
            </DropdownItem> */}
            {inLogin && (
              <DropdownItem onClick={setLikes} className="text-white h2">
                {' '}
                <i className="ri-heart-fill mr-2"></i> {isLike ? 'Favorilerden Kaldır' : 'Favorilere Ekle'}{' '}
              </DropdownItem>
            )}

            {isAdmin && (
              <DropdownItem className="text-white h2" onClick={handleShow}>
                <i className="fa fa-pencil mr-2"></i> Düzenle
              </DropdownItem>
            )}
            {isAdmin && (
              <DropdownItem onClick={() => del(teacher.slug)} className="text-white h2">
                <i className="fa fa-trash mr-2"></i> Sil
              </DropdownItem>
            )}

            <div className="pl-4">
              <ShereBtn link={`teachers/${teacher?.slug}`} type={false} />
            </div>
          </DropdownMenu>
        </Dropdown>
      )}

      <div onClick={handleShow1} className={`${style.imageBox1} cursor-pointer`}>
        {teacher?.photo?.path && (
          <div className={`${style.teacherBg}`}>
            <img src={teacher?.photo?.path} width="auto" height={'auto'} alt={`${teacher?.name}`} />
          </div>
        )}
        {/* {!teacher?.photo && (

        )} */}
      </div>
      <div className="icon-box-content mt-3 cursor-pointer">
        <div className="widget-container text-left d-flex flex-column">
          <div onClick={handleShow1} className="heading-title size-default ">
            {teacher?.name}
          </div>
          <p className="text-muted">{teacher?.categories ? teacher?.categories[0]?.name : ''}</p>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#14161A', overflow: 'hidden' }}>
          <ModalHeader handleClose={handleClose} titleHeader={'Öğretmen Güncelle'} />
          <TeacAdd teach={teacher} handleClose={handleClose} update={true} />
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={show1} onHide={handleClose1} centered className="border-1">
        <Modal.Body style={{ background: '#14161A', overflow: 'hidden' }} className="border-0">
          <TeacherModal item={teacher} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
