import React, { useEffect, useState } from "react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { getTeachers } from "../../Services/TeachersApi";
import { Row, Col, Container } from "react-bootstrap";
import TeacherItem from "../../components/TeacherItem";
import { useQuery } from "@tanstack/react-query";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function OtherTeacherList({ count }) {
  const [teachers, setTeachers] = useState([]);
  const { isLoading, error, data } = useQuery(["Teachers", count], getTeachers);
  useEffect(() => {
    if (data) setTeachers(data.data);
    return () => {};
  }, [data]);
  if (isLoading) return "Yükleniyor...";

  if (error) return "An error has occurred: " + error.message;
  return (
    <Container className="text-center">
      {/* <div className="d-flex align-items-center justify-content-end mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <div
            style={{ width: "50px" }}
            className="d-flex align-items-center justify-content-between mr-3">
            <div id="prev311" className="">
              <i className="fa fa-chevron-left"></i>
            </div>
            <div id="next311" className="">
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div> */}
      <Swiper
        navigation={{
          prevEl: "#prev311",
          nextEl: "#next311",
        }}
        breakpoints={{
          320: { slidesPerView: 2 },
          550: { slidesPerView: 2 },
          991: { slidesPerView: 4 },
          1400: { slidesPerView: 5 },
        }}
        loop={false}
        slidesPerView={5}
        spaceBetween={15}
        as="ul"
        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">
        {teachers?.map((teacher, index) => {
          return (
            <SwiperSlide
              key={index}
              as="li"
              style={{
                marginRight: "15px",
              }}>
              <TeacherItem teacher={teacher} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <Row className="d-flex justify-content-center">
        {teachers?.map((item, index) => {
          return (
            <Col key={index} xs="12" sm="6" md="2">
              <TeacherItem teacher={item} />
            </Col>
          );
        })}
      </Row> */}
    </Container>
  );
}
