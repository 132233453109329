import React, { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import about from '../../assets/images/lessons/about.png';
import NotesModal from './NotesModal';
import style from './style.module.css';
import closeIcon from '../../assets/icons/x.png';

export default function NoteItem({ type, handleClose1, item }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className={`${style.wsNateCard}`} style={{ border: type == 'modal' ? 'none' : '' }}>
        <div>
          <img className={style.Mask} src={type == 'modal' ? item?.banner?.path : item?.video?.thumbnail ? item?.video?.thumbnail?.path : about} />
        </div>
        <div className="text-left w-100 d-flex justify-content-between flex-wrap ">
          <div className="">
            <h6 className="text-left mb-1">{type == 'modal' ? item?.name : item?.video?.name}</h6>
            <span className={type != 'slider' ? 'text-primary' : 'text-muted'}>{type == 'modal' ? item?.teacher?.name : item?.content} </span>
            <span className="text-muted">
              {' '}
              {type == 'modal' ? '·' : ''} {item?.category?.name}
            </span>
          </div>
          {type == 'page' && (
            <div className="pr-4 ">
              <div onClick={handleShow} className="btn btn-hover ">
                Notları Görüntüle (4)
              </div>
            </div>
          )}
          {type == 'modal' && (
            <div className="pr-4 position-absolute" style={{ top: '-5px', right: '-27px' }}>
              <img width="30" onClick={handleClose1} src={closeIcon} />
            </div>
          )}
        </div>
        {type == 'slider' && <div className={`${style.notesCount}`}>{item?.total_note}</div>}
      </div>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#14161A' }}>
          <NotesModal type={'modal'} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
}
