import React, { useState } from "react";
import { useApi } from "../../../contexts/RequestContext";
import { useInfiniteQuery } from "@tanstack/react-query";
import Category from "./Category";
import { Row, Col, Modal, Pagination } from "react-bootstrap";
import { ModalHeader } from "../../../components/ModalHeader";
import AddCategory from "./AddCategory";
import { getAdminCategories } from "../../../Services/Category";

const Categories = ({}) => {
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(["Admin:AllCategories"], getAdminCategories, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
    },
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col xs="12" sm="12" md="9">
      <Row>
        <Col sm="12" className="d-flex justify-content-end mb-3 w-100">
          <div onClick={handleShow} className="btn btn-hover">
            Ekle
          </div>
        </Col>{" "}
      </Row>
      <div>
        <Row>
          {data?.pages.map((page) => (
            <React.Fragment key={page.nextId}>
              {page?.data?.map((item) => (
                <Category key={item?.id} item={item} refetch={refetch} />
              ))}
            </React.Fragment>
          ))}
          <Pagination
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Row>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: "#14161A" }}>
          <ModalHeader
            handleClose={handleClose}
            titleHeader={"Kategori Ekle"}
          />
          <AddCategory
            category={null}
            handleClose={handleClose}
            update={false}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </Col>
  );
};

export default Categories;
