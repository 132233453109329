import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
//img
import './style.css';
import { useApi } from '../../../contexts/RequestContext';
import LesonsList from '../../LessonsDetail/components/LesonsList';

// install Swiper modules
SwiperCore.use([Navigation]);

export default function LessonsSliders() {
  const { videosList } = useApi();

  return (
    <section id="movieshow1" className=" py-0 px-0">
      <Container fluid>
        <Row className="">
          <Col sm="12" className="overflow-hidden">
            <div id="favorites-contens">
              {videosList?.videos?.map((item, index) => {
                return (
                  <LesonsList
                    key={index}
                    item={item}
                    items={videosList}
                    index={index}
                    refetch={() => {}}
                    classroom_id={videosList.id}
                    classroom_slug={videosList.slug}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
