import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function PrivacyPolicy1() {
  return (
    <div>
      <div className=" " style={{ paddingTop: '80px', paddingBottom: '60px' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Gizlilik Politikası</h1>
                  <span>
                    <Link to="/">Anasayfa</Link> /<span className="text-primary">Gizlilik Politikası</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy text-left">
                <div className="mb-1">
                  <h2 className="h4">VERANOVA MEDYA VE DİJİTAL YAYIN HİZMETLERİ A.Ş. KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ</h2>
                  <p className="my-3">
                    6698 sayılı Kişisel Verilerin Korunması Kanunu (<b>“KVKK” </b>veya <b> “Kanun”</b>) uyarınca ‘‘Veri Sorumlusu’’ sıfatını haiz
                    Veranova Medya ve Dijital Yayın Hizmetleri A.Ş. (<b>“Veranova Medya’’</b> veya <b>“Şirket”</b>) olarak; sahibi olduğumuz
                    ‘‘Wise&Rise’’ isimli dijital platform üzerinden bizimle paylaştığınız kişisel verilerinizi, aşağıda ayrıntıları ile açıklanan
                    şartlarda toplamakta, işlemekte ve aktarmaktayız.
                  </p>
                </div>
                <div className="mb-3">
                  <p className="mb-2">
                    İşbu metin ile Veranova Medya, Kanun’da ‘‘İlgili Kişi’’ olarak tanımlanan siz veri sahiplerini, kişisel verilerinizin toplanması,
                    işlenmesi, aktarılması süreçleri ve Kanun kapsamındaki haklarınız hususlarında aydınlatmaktadır.
                  </p>
                  <br />
                  <h3 className="h5 mb-2">1. Veri Sorumlusu</h3>
                  <p className="mb-0">
                    Veranova Medya, kişisel verilerinizin işlenmesi süreçlerinde, Kanun’un 3/1-(ı) maddesinde tanımlanan <i>‘‘Veri Sorumlusu’’</i>{' '}
                    olarak hareket etmek suretiyle kişisel verilerinizin işleme amaçlarını ve vasıtalarını belirlemektedir.
                    <br />
                    <br />
                    Veranova Medya, veri sorumlusu olarak, veri kayıt sistemini kurmak, yönetmek ve veri güvenliğine ilişkin idari ve teknik
                    tedbirleri almakla yükümlüdür. Bu kapsamda, verilerinizi Kanun’un 3/1-(ğ) maddesinde tanımlanan <i>‘‘Veri İşleyen’’</i>
                    sıfatını haiz üçüncü kişilere de işletebilecektir.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h3 className="h5 mb-2">2. İşlenen Kişisel Verileriniz ve Kişisel Verilerinizin İşlenme Amaçları </h3>
                  <p className="mb-0">
                    Wise&Rise üzerinden bizimle paylaştığınız bilgileriniz ve Wise&Rise üzerindeki faaliyetleriniz kapsamında, aşağıda belirtilen
                    kişisel verilerinizi aşağıda sayılan amaçlarla Kanun’un 5. ve 6. maddelerinde belirtilen veri işleme şartları dahilinde
                    işlemektedir.
                    <br />
                    <br />
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th className="w-25 text-center">Veri Sahibi</th>
                          <th className="w-25 text-center">Veri Kategorisi</th>
                          <th>Veri Türleri</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th rowspan="3" className="text-center" style={{ verticalAlign: 'middle' }}>
                            Çevrimiçi <span className="text-primary">Ziyaretçi</span>
                          </th>
                          <td>İletişim</td>
                          <td>E-posta, cep telefonu </td>
                        </tr>
                        <tr>
                          <td>İşlem Güvenliği</td>
                          <td>
                            IP Adresi, İnternet Sitesi Giriş, Çıkış ve Gezinme Bilgisi, Log Kayıtları, Hesap Kullanıcı Bilgileri, Cihaz MAC Adresi
                          </td>
                        </tr>
                        <tr>
                          <td>Pazarlama Bilgileri </td>
                          <td>Çerezler, anket bilgileri</td>
                        </tr>

                        <tr>
                          <th rowspan="6" className="text-center" style={{ verticalAlign: 'middle' }}>
                            Çevrimiçi Abone
                            <span className="text-primary">/Üye</span>
                          </th>
                          <td>Kimlik</td>
                          <td>Ad-Soyad, T.C. kimlik numarası</td>
                        </tr>
                        <tr>
                          <td>İletişim</td>
                          <td>E-posta, telefon/cep telefonu, adres, iş yeri adresi, fatura adresi</td>
                        </tr>
                        <tr>
                          <td>Müşteri İşlem Bilgileri </td>
                          <td>Ürün ve hizmet satın alma geçmişi bilgileri, fatura bilgileri, talep ve şikayet bilgileri</td>
                        </tr>
                        <tr>
                          <td>İşlem Güvenliği </td>
                          <td>IP Adresi, İnternet Sitesi Giriş, Çıkış ve Gezinme Bilgisi, Log Kayıtları,Cihaz MAC Adresi </td>
                        </tr>
                        <tr>
                          <td>Hukuki İşlem Bilgileri </td>
                          <td>
                            Adli, İdari, Düzenleyici ve Denetleme Kuruluşları ile Paylaşılan Bilgiler, İhbarname ve İhtarname Bilgileri, Sözleşme
                            bilgileri{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Pazarlama Bilgileri</td>
                          <td>Çerezler, anket bilgileri </td>
                        </tr>
                      </tbody>
                    </table>
                  </p>
                  <br />
                  <p className="">Yukarıdaki tabloda yer alan kişisel verileriniz, aşağıdaki amaçlarla sınırlı olmak üzere işlenmektedir.</p>
                </div>
                <div className="mb-3 px-5">
                  <ul>
                    <li className="mb-3">
                      <b> Kimlik Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi </li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi </li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi </li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>İletişim Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>İşlem Güvenliği Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Müşteri İşlem Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Finansal Bilgiler</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Hukuki İşlem Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Sözleşme süreçlerinin yürütülmesi</li>
                        <li>Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilebilmesi,</li>
                        <li>Ürün veya hizmetlerin satın alınmasına ve kullanılmasına yönelik süreçlerin gerçekleştirilebilmesi</li>
                        <li>Hizmetlerin ifa edileceği internet sitesinin güvenliğinin sağlanabilmesi</li>
                        <li> Talep ve şikayet süreçlerinin yönetilebilmesi </li>
                        <li>Mali ve finansal yükümlülüklerin yerine getirilebilmesi</li>
                        <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilebilmesi</li>
                        <li> Bilgi güvenliği süreçlerinin yürütülmesi</li>
                        <li> İş faaliyetlerinin yürütülmesi </li>
                        <li>Faaliyetlerin mevzuata uygun olarak sürdürülmesi</li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <b>Pazarlama Bilgileri</b>
                      <ul className="pl-4 mt-2">
                        <li> Reklam/kampanya/promosyon süreçlerinin yürütülmesi </li>
                        <li>Pazarlama analiz çalışmalarının yürütülmesi</li>
                        <li>Müşteri ilişkileri yönetimi süreçlerinin yürütülmesi</li>
                        <li>Müşteri memnuniyetine yönelik aktivitelerin yürütülmesi</li>
                        <li> İş süreçlerinin iyileştirilmesine yönelik önerilerin alınması ve değerlendirilmesi </li>
                      </ul>
                    </li>
                  </ul>
                  <br />
                </div>
                <div className="mb-3">
                  <p>
                    ‘‘İşlenen Kişisel Verileriniz ve Kişisel Verilerinizin İşlenme Amaçları’’ başlığı altındaki tabloda yer alan ve Wise&Rise’ı
                    çevrimiçi ortamdan ziyaretiniz kapsamındaki sıfatınıza göre değişiklik arz eden kişisel verileriniz arasında, ödeme
                    gerçekleştirdiğiniz banka veya kredi kartı bilgileriniz yer almamaktadır. Ödeme işlemini gerçekleştirdiğiniz banka veya kredi
                    kartı bilgileriniz, Şirketimiz tarafından veri işleme faaliyetine konu edilmemektedir. Banka veya kredi kartı bilgileriniz,
                    Wise&Rise’a güvenli ödeme sistemi hizmeti sağlayan kuruluşlar tarafından işlenir ve saklanır.
                  </p>
                </div>
                <div className="mb-4">
                  <h3 className="h5 mb-2">3. Kişisel Verilerinizin Toplanma Yöntemleri ve Hukuki Sebebi </h3>
                  <p className="mb-0">
                    Kişisel verileriniz, işbu aydınlatma metninde açıklanan hukuki sebepler ve işleme amaçları kapsamında, hizmet ve işleme amacına
                    göre farklılık göstermekle birlikte; Wise&Rise’ın internet adresi üzerindeki her türlü işlem için bilgi girişi yaptığınız
                    elektronik formlar, Şirketimize fiziki olarak teslim ettiğiniz belgeler, Wise&Rise’ın internet adresi üzerinden yaptığınız hizmet
                    alım işlemleri, telefon, e-posta ve sair yöntemlerle oluşturduğunuz talep, şikayet, başvuru gibi bilgi ve belge aktarımı
                    faaliyetleri aracılığıyla, otomatik ya da otomatik olmayan yollarla sözlü, yazılı veya elektronik olarak, ‘‘İşlenen Kişisel
                    Verileriniz ve Kişisel Verilerinizin İşlenme Amaçları’’ başlığı altındaki tabloda yer alan amaçlar doğrultusunda, Şirketimiz
                    tarafından sunulan hizmetlerin belirlenen yasal çerçevede sunulabilmesi ve bu kapsamda Şirketimizin sözleşme ve yasadan doğan
                    mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi amacı ile toplanmaktadır.
                  </p>
                </div>
                <div className="mb-4">
                  <h3 className="h5 mb-3">4. Kişisel Verilerinizin Aktarılması</h3>
                  <p className="mb-0">
                    Şirketimiz tarafından toplanan kişisel verileriniz; işbu aydınlatma metninde belirtilen amaçlar ve ilgili yasal düzenlemeler
                    kapsamında aktarılmasını gerektiren sebeplerle sınırlı şekilde; adli, idari, denetleyici, düzenleyici kamu kurum ve kuruluşlarına,
                    iş ortaklarımıza, hissedarlarımıza, hukuki, mali ve vergi danışmanlarımıza, tedarikçilerimize ve denetçilere Kanun’un 8. ve 9.
                    maddelerine uygun olarak aktarılabilecektir. <br /> <br />
                    gerçekleştirilmesi için yurt içinde tutulabileceği gibi yurt dışına da aktarılabilecektir. Yurt dışına aktarılması halinde açık
                    rızanızın alınması veya aksi takdirde kişisel verinin aktarılacağı yabancı ülkede yeterli korumanın bulunması gerekli olup,
                    yeterli korumanın bulunmaması durumunda Türkiye’deki ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı
                    olarak taahhüt etmeleri ve Kişisel Verileri Koruma Kurumu’nun izninin bulunması hususlarına riayet edilecektir.
                  </p>
                </div>
                <div className="mb-4">
                  <h3 className="h5 mb-3">5. Kişisel Verilerinizin Muhafaza Edilme Süresi</h3>
                  <p className="mb-0">
                    Şirketimiz kişisel verilerinizi ancak ilgili mevzuatta belirtildiği veya işlendikleri amaç için gerekli olan süre kadar muhafaza
                    etmektedir. Bu kapsamda, öncelikle ilgili mevzuatta kişisel verilerin saklanması için bir süre belirlenmişse bu süreye uygun
                    davranmakta, bir süre belirlenmemişse kişisel verileri işlendikleri amaç için gerekli olan süre kadar saklamaktadır. Sürenin
                    bitimi veya işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verileriniz Kanun’un 7. maddesi uyarınca
                    silinmekte, yok edilmekte veya anonim hale getirilerek Şirketimizce kullanılmaya devam edilebilmektedir.
                  </p>
                </div>

                <div className="mb-4">
                  <h3 className="h5 mb-3">6. Yasal Haklarınız</h3>
                  <p className="mb-0">
                    Kişisel veri sahibi olarak, Kanun’un 11. maddesi uyarınca, veri sorumlusu olan Veranova Medya’ya başvurarak;
                    <ul className="pl-5 mt-3">
                      <li>Kişisel verinizin işlenip işlenmediğini öğrenme, </li>
                      <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                      <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                      <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                      <li>
                        Kişisel verileriniz eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                        kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                      </li>
                      <li>
                        Kanun’un 7. maddesinde öngörülen şartlar (Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
                        kişisel verinizin işlenmesini gerektiren sebeplerin ortadan kalkması hâli) çerçevesinde kişisel verilerin silinmesini veya yok
                        edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                      </li>
                      <li>
                        İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya
                        çıkmasına itiraz etme,{' '}
                      </li>
                      <li>
                        Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme
                        haklarınız bulunmaktadır. Kanun’un 28. Maddesinde öngörülen istisnai haller saklıdır.
                      </li>
                    </ul>
                    <br /> <br />
                    Yukarıda sayılan haklarınıza ilişkin taleplerinizi, ‘‘Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe” göre, ıslak
                    imzanızın bulunması koşuluyla, www.(...).com/(...) adresinden temin edebileceğiniz “İlgili Kişi Başvuru Formu” metni ile ‘‘Akat
                    Mah. Cebeci Cad. No: 65 Beşiktaş/İSTANBUL’’ adresine; veya info@wisenrise.com. veya (...)@hs01.kep.tr adresine yazılı olarak ve
                    kimlik teyidinizin yapılmasını sağlayarak iletebilirsiniz. Şirketimiz başvuru taleplerini, Kanun’un 13. maddesine uygun olarak,
                    talebin niteliğine göre ve en geç 30 (otuz) gün içinde sonuçlandıracaktır.
                    <br /> <br />
                    İşlemin maliyet gerektirmesi halinde, Kişisel Verilerin Korunması Kurumu tarafından belirlenen tarife uygulanacaktır. Talebin
                    reddedilmesi halinde, ret nedeni/nedenleri yazılı olarak veya elektronik ortamda gerekçelendirilecektir.
                    <br /> <br />
                    Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı talep
                    ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması, talep ettiğiniz
                    konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor iseniz bu konuda özel olarak yetkili olmanız ve yetkinizi
                    belgelendirmeniz, başvurunun kimlik ve adres bilgilerini içermesi ve başvuruya kimliğinizi teyit edici belgelerin eklenmesi
                    gerekmektedir.
                  </p>
                </div>
                <div className="mt-5">
                  <span className="h6 d-block text-center">Saygılarımızla,</span>
                  <h5 className="h4 text-center">VERANOVA MEDYA VE DİJİTAL YAYIN HİZMETLERİ A.Ş. </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
