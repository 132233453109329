import React, { useState } from "react";
import htm12canvas from "html2canvas";
import jsPDF from "jspdf";
import closeIcon from "../../assets/icons/x.png";
import style from "./style.module.css";
import CertificateItem from "./Certificate";

const CertificateModal = ({ teacher, item, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const downloadPDF = () => {
    const capture = document.querySelector(".certificate_v1");
    setLoader(true);
    htm12canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("l", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(`Wisenrise_${teacher?.name}.pdf`);
    });
  };
  const handleShare = () => {
    // Prepare share data
    const imageFile =
      "https://wisenrise.s3.eu-central-1.amazonaws.com/cl…36a8bb90417278c45624ec8f847/ali-yesilirmak-cc.jpg"; // URL of the image
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      imageFile
    )}`; // Compose shareable URL with image

    // Open Twitter share window
    window.open(shareUrl, "_blank");
  };
  return (
    <div className={`p-3 ${style.modal}`}>
      <div
        className={`d-flex justify-content-between align-items-start ${style.header}`}>
        <div
          className="d-flex flex-wrap flex-sm-nowrap  justify-content-center justify-content-sm-start "
          style={{ width: "90%" }}></div>
        <div
          className=""
          style={{ position: "absolute", top: "0", right: "5px" }}>
          <img onClick={handleClose} width="30" src={closeIcon} alt="" />
        </div>
      </div>
      <CertificateItem item={item} />
      <div
        className="d-flex align-items-center r-mb-23 justify-content-end mt-4"
        data-iq-gsap="onStart"
        data-iq-position-y="80"
        data-iq-delay="0.8">
        <div
          onClick={downloadPDF}
          className="btn btn-link btn-outline-light mr-3">
          PDF indir
        </div>
      </div>
    </div>
  );
};

export default CertificateModal;
