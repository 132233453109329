import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useApi } from '../../../contexts/RequestContext';

export default function PageTitile() {
  const { inLogin } = useAuth();
  const { selectCategoryTeacher } = useApi();

  return (
    <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50">
      <Container>
        <Row>
          <Col className="text-left text-left text-xs-center" xs="12" sm="6">
            <h3 className="text-left text-xs-center">Tüm Eğitmenler</h3>
            <span>
              {' '}
              <Link to="/">Anasayfa</Link> / {selectCategoryTeacher ? selectCategoryTeacher?.name : 'Tüm Eğitmenler'}{' '}
            </span>
          </Col>
          {/* <Col sm="6" className="text-right">
            {inLogin && (
              <Link to="/following-teachers" className="btn btn-hover btn-primary1 ">
                Benim Takip Ettiğin Eğitmenler
              </Link>
            )}
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}
