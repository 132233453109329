import React, { useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import './style.css';
import verMenu from '../../assets/icons/icons-more-vertical.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import EditProfile from './EditProfile';
import { deleteProfile, selectProfile } from '../../Services/Profile';
import { useAuth } from '../../contexts/AuthContext';
import Swal from 'sweetalert2';
import { notify, notifyError } from '../../components/AlartNotify/AlertNotify';

export default function ItemCard({ profile }) {
  let history = useHistory();
  const { Profile, Refresh } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    Refresh();
  };
  const handleShow = () => setShow(true);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const select = () => {
    selectProfile(profile?.id)
      .then((data) => {
        Profile(data.data.data);
        if (!data?.data?.data?.is_survey_completed) history.push('/survey');
        else if (localStorage.getItem('ws-currentPath')) history.push(localStorage.getItem('ws-currentPath'));
        else history.push('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function del(id) {
    Swal.fire({
      title: 'Onayla!',
      text: 'Profili silmek istediğinden emin misin?',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, Sil',
      cancelButtonText: 'İptal',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProfile(id)
          .then((data) => {
            notify('Profiliniz başarıyla silindi');
            Refresh();
          })
          .catch((err) => {
            notifyError(err.response.data.message);
          });
      } else if (result.isDenied) {
      }
    });
  }
  return (
    <>
      {' '}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Col xs="12" sm="6" md="4" lg="3" className="text-center mt-3">
        <div className="ws-item-wrapper">
          <div className="ws-item ">
            {/* <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="ws-item-menu">
              <DropdownToggle tag="div">
                <img src={verMenu} alt="" />
              </DropdownToggle>
              <DropdownMenu className="bg-dark ">
                <DropdownItem
                  className="text-white h2 m-0"
                  onClick={handleShow}>
                  <i className="fa fa-pencil mr-2"></i> Düzenle
                </DropdownItem>
                {profile?.is_main ? (
                  <></>
                ) : (
                  <DropdownItem
                    onClick={() => del(profile.id)}
                    className="text-white h2 m-0">
                    <i className="fa fa-trash mr-2"></i> Sil
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown> */}

            <img onClick={select} width="100%" height="100%" src={profile?.avatar?.path} alt={profile?.avatar?.name} />
          </div>
          <div onClick={select} className="mt-4 btn-hover-primary">
            {profile?.name}
          </div>
          <div className="d-flex justify-content-around align-items-center mt-3">
            {profile && (
              <div className=" btn-hover-primary" onClick={handleShow}>
                <i className="fa fa-pencil mr-2 btn-hover-primary"></i> Düzenle
              </div>
            )}
            {!profile?.is_main && (
              <div className=" btn-hover-primary" onClick={() => del(profile.id)}>
                <i className="fa fa-trash mr-2 btn-hover-primary"></i> Sil
              </div>
            )}
          </div>
        </div>
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Body style={{ background: '#000' }}>
            <EditProfile profile={profile} handleClose={handleClose} />
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
}
