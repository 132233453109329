import axios from "axios";

const API = process.env.REACT_APP_BASE_ENDPOIND;
const avatars = `${API}avatars`;
const avatar_categories = `${API}avatar_categories`;

export const getAvatars = async () => {
  return await axios.get(avatars + "?page[number]=0&page[size]=100");
};
export const getAvatarsQ = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${avatars}?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const createAvatar = (body) => {
  return axios.post(avatars, body).then((response) => response.data);
};

export const updateAvatar = (slug, body) => {
  return axios
    .put(`${avatars}/${slug}`, body)
    .then((response) => response.data);
};
export const updateAvatarFile = (slug, body) => {
  return axios
    .post(`${avatars}/${slug}/file`, body)
    .then((response) => response.data);
};
export const deleteAvatar = (slug) => {
  return axios.delete(`${avatars}/${slug}`).then((response) => response.data);
};

export const getAvatarsCategories = async () => {
  return await axios.get(`${API}avatar_categories?page[size]=50`);
};

export const getAvatarCategoriesQ = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${avatar_categories}?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const createAvatarCategory = (body) => {
  return axios.post(avatar_categories, body).then((response) => response.data);
};

export const updateAvatarCategory = (slug, body) => {
  return axios
    .put(`${avatar_categories}/${slug}`, body)
    .then((response) => response.data);
};

export const deleteAvatarCategory = (slug) => {
  return axios
    .delete(`${avatar_categories}/${slug}`)
    .then((response) => response.data);
};
