import React from "react";
import { Link } from "react-router-dom";
import "../style.css";
import bg from "../../../assets/images/article/articleitembg.png";

const MostDevice = (props) => {
  return (
    <div className="ws-most-device" style={{ background: `url(${bg})` }}>
      <div className="text-left left-block">
        <h2>The Most Wanted Device In The World</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et.
        </p>
        <Link className="btn btn-hover">More Read</Link>
      </div>
      <div className="right-block" ></div>
    </div>
  );
};

export default MostDevice;
