import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import "../style.css";

//img
import banner from "../../../assets/images/article/singlesliderbg.png";
const SinglePageSlider = (props) => {
  return (
    <section id="movieshow" className="iq-main-slider p-0">
      <div className="swiper-btn">
        <div id="prev" className="swiper-button swiper-button-prev">
          <i className="ri-arrow-left-s-line"></i>
        </div>
        <div id="next" className="swiper-button swiper-button-next">
          <i className="ri-arrow-right-s-line"></i>
        </div>
      </div>

      <Swiper
        slidesPerView={1.1}
        spaceBetween={20}
        centeredSlides={true}
        navigation={{
          prevEl: "#prev",
          nextEl: "#next",
        }}
        loop={true}
        className=""
      >
        <SwiperSlide>
          <Link to="/movie-details">
            <div className="shows-img">
              <img src={banner} className="w-100 img" alt="" />
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/movie-details">
            <div className="shows-img">
              <img src={banner} className="w-100 img1" alt="" />
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/movie-details">
            <div className="shows-img">
              <img src={banner} className="w-100 img1" alt="" />
            </div>
          </Link>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SinglePageSlider;
