import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Row, Col, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../components/ModalHeader";
import AddAvatarCategory from "./AddAvatarCategory";
import AvatarCategory from "./AvatarCategory";
import { getAvatarCategoriesQ } from "../../../Services/AvatarApi";
import Pagination from "../../../components/UI/pagination/Pagination";

const AvatarCategories = (props) => {
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(["Admin:AllAvatarCategories"], getAvatarCategoriesQ, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
    },
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col xs="12" sm="12" md="9">
      <Row>
        <Col sm="12" className="d-flex justify-content-end mb-3 w-100">
          <div onClick={handleShow} className="btn btn-hover">
            Ekle
          </div>
        </Col>{" "}
      </Row>
      <div>
        {/* <Row>
          {categories?.map((item, index) => {
            return <AvatarCategory key={index} item={item} />;
          })}
        </Row> */}
        {status === "loading" ? (
          <p>Yükleniyor...</p>
        ) : status === "error" ? (
          <span>Error: {error.message}</span>
        ) : (
          <>
            <Row>
              {data?.pages.map((page) => (
                <React.Fragment key={page.nextId}>
                  {page?.data?.map((item) => (
                    <AvatarCategory
                      key={item.id}
                      item={item}
                      refetch={refetch}
                    />
                  ))}
                </React.Fragment>
              ))}
            </Row>
            <Pagination
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </>
        )}
      </div>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: "#14161A" }}>
          <ModalHeader
            handleClose={handleClose}
            titleHeader={"Avatar Kategorisi Ekle"}
          />
          <AddAvatarCategory
            category={null}
            handleClose={handleClose}
            update={false}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </Col>
  );
};

export default AvatarCategories;
