import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function OnlineConditions() {
  return (
    <div>
      <div className=" " style={{ paddingTop: "80px", paddingBottom: "60px" }}>
        <Container style={{ background: "#14161a" }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav
                  aria-label="breadcrumb"
                  className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Abonelik Sözleşmesini</h1>
                  <span>
                    Homepage /{" "}
                    <span className="text-primary">Abonelik Sözleşmesini</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy text-left">
                <h5>
                  ÇEVRİMİÇİ(ONLINE) ABONELİK HİZMETİ SATIN ALIMINA İLİŞKİN
                  MESAFELİ SÖZLEŞME
                </h5>
                <br />
                <div className="mb-3">
                  <p className="my-3">
                    ‘‘SATICI/SAĞLAYICI’’ sıfatıyla 6502 sayılı Tüketicinin
                    Korunması Hakkında Kanun, Mesafeli Sözleşmelere Dair
                    Yönetmelik ve Abonelik Sözleşmeleri Yönetmeliği’ne uygun
                    olarak hazırladığımız işbu ÇEVRİMİÇİ(ONLINE) ABONELİK
                    HİZMETİ SATIN ALIMINA İLİŞKİN MESAFELİ SÖZLEŞME’yi(Bundan
                    böyle kısaca <b>‘‘Sözleşme’’</b> olarak anılacaktır.) siz
                    değerli abone adayımızın onayına sunuyoruz. <br />
                    <br />
                    Bu Sözleşme, internet sitemizdeki/mobil uygulamamızdaki
                    hizmetlerden faydalanabilmeniz ve hizmetlere erişim
                    sağlayabilmeniz için gerekli kuralları içermektedir. Lütfen
                    Sözleşme’nin tüm hükümlerini okuduğunuzdan ve anladığınızdan
                    emin olduktan sonra Sözleşme’yi onaylayınız.
                    <br />
                    <br />
                    Wise&Rise’a erişim sağlayabildiğiniz her cihazda Sözleşme’yi
                    elektronik olarak(‘‘Click Wrap Yöntemi’’ olarak da
                    adlandırılan ‘‘Tıklama Yoluyla Kabul’’ işlemi dahil)
                    onaylamanız , tüm koşulları kabul etmiş olmanız sonucunu
                    doğurur.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="mb-3">MADDE 1 – TARAFLAR </h5>
                  <p className="mb-2">
                    <h6 className="">1.1. SATICI / SAĞLAYICI </h6>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> Ticari Unvan :</div>
                      <div>
                        {" "}
                        VERANOVA MEDYA VE DİJİTAL YAYIN HİZMETLERİ A.Ş.
                      </div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> Vergi No :</div>
                      <div> 9241000351 (Beşiktaş Vergi Dairesi)</div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> MERSİS No :</div>
                      <div> 0924100035100001</div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> E-Posta: </div>
                      <div> info@wisenrise.com</div>
                    </div>
                  </p>
                  <p className="my-4">
                    <h6 className="">1.2. 1.2. ALICI </h6>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> Ad-Soyadı :</div>
                      <div></div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> T.C.K.N.:</div>
                      <div> </div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> Adres:</div>
                      <div></div>
                    </div>
                    <div className="d-flex my-1">
                      <div style={{ width: "120px" }}> E-Posta Adresi: </div>
                      <div></div>
                    </div>
                  </p>
                  <p className="mb-2">
                    <h5 className="mb-3">MADDE 2 – KONU </h5>
                    İşbu Sözleşme’nin konusu; belirsiz süreli ve aylık olarak
                    faturalandırılan abonelik hizmetinin(aylık plan) veya 12(on
                    iki) ay boyunca erişim sağlayan yıllık abonelik hizmetinin
                    (yıllık plan) ALICI tarafından satın alınması karşılığında,
                    kapsamı Sözleşme’nin 3(üç) numaralı maddesinde belirtilen
                    dijital içeriklerin ALICI’nın erişimine açılması ve
                    hizmetlerin sağlanması ile kullanılması sırasında tarafların
                    sahip olduğu hak ve yükümlülüklerin belirlenmesidir.
                  </p>

                  <p className="mb-2">
                    <h5 className="my-3">MADDE 3 – KONU </h5>
                    <h6 className=" d-inline mr-2">3.1.</h6>
                    <b>
                      Belirsiz Süreli, Aylık Olarak Faturalandırılan
                    </b> ve <b> Ön Ödemeli Abonelik Hizmeti</b>(Bundan böyle
                    kısaca <b>‘‘Aylık Plan’’</b> olarak anılacaktır.)
                  </p>
                  <p className="mb-2">
                    <h6 className="mb-2">Kapsamı </h6>
                    <h6 className=" d-inline mr-2"> 3.1.1..</h6>
                    Çeşitli aralıklarla yenilenen; İş Dünyası, Sanat, Kişisel
                    Gelişim, Gastronomi gibi kategorilerdeki içeriklere internet
                    ortamından erişim imkânı sağlar.
                    <br />
                    <br />
                    <h6 className=" d-inline mr-2">3.1.2.</h6>
                    İçeriklere erişim, tek bir cihaz üzerinden erişim imkânı ile
                    sınırlı olarak sağlanır. Hizmet kapsamındaki içeriklere aynı
                    anda 1’den fazla cihaz üzerinden erişmek isteyenler için
                    elverişli/uygun değildir.
                  </p>

                  <p className="mb-2">
                    <h6 className="mb-2">Kapsamı </h6>
                    <h6 className=" d-inline mr-2"> 3.2.1.</h6>
                    Çeşitli aralıklarla yenilenen; İş Dünyası, Sanat, Kişisel
                    Gelişim, Gastronomi gibi kategorilerdeki içeriklere internet
                    ortamından erişim imkânı sağlar.
                    <br />
                    <br />
                    <h6 className=" d-inline mr-2">3.2.2.</h6>
                    İçeriklere aynı anda 4 ayrı cihaz üzerinden erişim imkânı
                    sağlar.
                  </p>

                  <p className="mb-2">
                    <h6 className="mb-2">Kapsamı </h6>
                    <h6 className=" d-inline mr-2"> 3.3.1.</h6>
                    Çeşitli aralıklarla yenilenen; İş Dünyası, Sanat, Kişisel
                    Gelişim, Gastronomi gibi kategorilerdeki içeriklere internet
                    ortamından erişim imkânı sağlar.
                    <br />
                    <br />
                    <h6 className=" d-inline mr-2">3.3.2.</h6>
                    İçeriklere erişim, tek bir cihaz üzerinden erişim imkânı ile
                    sınırlı olarak sağlanır. Hizmet kapsamındaki içeriklere aynı
                    anda 1’den fazla cihaz üzerinden erişmek isteyenler için
                    elverişli/uygun değildir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.4.</h6>
                    Belirli Süreli, Süresi Sona Erdiğinde Kendiliğinden
                    Yenilenen, Ön Ödemeli ve Çoklu Erişime Elverişli Abonelik
                    Hizmeti (Bundan böyle kısaca ‘‘Nitelikli Yıllık Plan’’
                    olarak anılacaktır.)
                  </p>

                  <br />

                  <p className="mb-2">
                    <h6 className="mb-2">Kapsamı </h6>
                    <h6 className=" d-inline mr-2"> 3.4.1.</h6>
                    Çeşitli aralıklarla yenilenen; İş Dünyası, Sanat, Kişisel
                    Gelişim, Gastronomi gibi kategorilerdeki içeriklere internet
                    ortamından erişim imkânı sağlar.
                    <br />
                    <br />
                    <h6 className=" d-inline mr-2">3.4.2.</h6>
                    İçeriklere aynı anda 4 ayrı cihaz üzerinden erişim imkânı
                    sağlar.
                  </p>
                </div>
                <div className="mb-3">
                  <h5 className="my-3">MADDE 4 – ÖDEME ve ÖDEME DENEMELERİ </h5>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">4.1.</h6>
                    Sözleşme konusu abonelik hizmetlerinin ücreti; ‘‘ödeme ve
                    kart bilgileri / (planlarım)’’ başlıklı sayfada ALICI
                    tarafından bilgisi verilen kredi / banka kartından ödeme
                    kuruluşu Iyzico aracılığıyla tahsil edilir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">4.2. </h6>
                    Bireysel Aylık Plan’ı veya Aile Aylık Plan’ı satın
                    aldıysanız; planlarım sayfasından veya üzerinden her daim
                    bilgisine erişebileceğiniz yenileme döneminden önce
                    aboneliğinizi iptal etmediğinizde, bilgisini vermiş
                    olduğunuz kredi / banka kartından otomatik olarak tahsilat
                    gerçekleştirilir. İlgili tahsilatın kart bakiyesinin
                    yetersiz olması nedeniyle gerçekleştirilemediği durumlarda;
                    SATICI/SAĞLAYICI, ödeme kuruluşu aracılığıyla bir süre daha
                    ilgili tahsilatı gerçekleştirmeyi deneyebilir. ALICI bu süre
                    zarfında hizmetlere erişebilir; ancak yenileme döneminin
                    bittiği tarihten itibaren dört gün boyunca ilgili tahsilat
                    tamamlanamaz ise SATICI/SAĞLAYICI hizmetleri ALICI’nın
                    erişimine kapatabilir.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="mb-3"> MADDE 5 – HİZMETİN YENİLENMESİ </h5>
                  <h6 className="mb-3">
                    5.1. Aylık Plan ve Nitelikli Aylık Plan{" "}
                  </h6>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.1.1. </h6>
                    Aylık Plan ve Nitelikli Aylık Plan, ALICI iptal etmediği
                    müddetçe, satın alım tarihini izleyen her ayın aynı
                    döneminde, bedeli ‘‘ödeme ve kart bilgileri / planlarım’’
                    başlıklı sayfada ALICI tarafından bilgisi verilen
                    kredi/banka kartından ödeme kuruluşu Iyzico aracılığıyla
                    tahsil edilmek suretiyle kendiliğinden yenilenir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.1.2. </h6>
                    ALICI, aboneliğinin bir sonraki ay yenilenmesini talep
                    etmiyorsa, planlarım bölümünden abonelik planını iptal
                    etmekle yükümlüdür.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.1.3. </h6>
                    ALICI, Aylık Plan veya Nitelikli Aylık Plan türündeki
                    aboneliğini iptal ettiğinde, ilgili ayın bakiye süresi
                    boyunca hizmet konusu içeriklere erişim sağlayabilir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.1.4. </h6>
                    Sözleşme’nin 4(dört) numaralı maddesinde de belirtilen ödeme
                    denemesi çabalarının olumlu yönde sonuç vermemesi durumunda,
                    SATICI/SAĞLAYICI Sözleşme kapsamındaki hizmeti derhal sona
                    erdirebilir.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h6 className="mb-3">
                    5.1. Yıllık Plan ve Nitelikli Yıllık Plan{" "}
                  </h6>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.2.1. </h6>
                    Yıllık Plan ve Nitelikli Yıllık Plan, ALICI iptal etmediği
                    müddetçe, satın alım tarihinden tam 1(bir) yıl sonrasına
                    tekabül eden tarihte, SAĞLAYICI tarafından ALICI’ya en geç
                    30(otuz) gün öncesinden e-posta yoluyla bildiren fiyat
                    üzerinden, ‘‘ ödeme ve kart bilgileri / planlarım’’ başlıklı
                    sayfada ALICI tarafından bilgisi verilen kredi/banka
                    kartından ödeme kuruluşu Iyzico aracılığıyla tahsil edilmek
                    suretiyle kendiliğinden yenilenir. SAĞLAYICI’nın 4. maddede
                    düzenlenen ödeme denemelerine ilişkin hakları saklıdır.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.2.2. </h6>
                    ALICI, aboneliğinin yenilenmesini talep etmiyorsa, planlarım
                    bölümünden abonelik planını iptal etmekle yükümlüdür.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.2.3. </h6>
                    ALICI, Yıllık Plan veya Nitelikli Yıllık Plan türündeki
                    aboneliğini iptal ettiğinde, iptal ettiği takvim günündeki
                    ayın sonuna kadar hizmet konusu içeriklere erişim
                    sağlayabilir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2"> 5.2.4. </h6>
                    Sözleşme’nin 4(dört) numaralı maddesinde de belirtilen ödeme
                    denemesi çabalarının olumlu yönde sonuç vermemesi durumunda,
                    SATICI/SAĞLAYICI Sözleşme kapsamındaki hizmeti derhal sona
                    erdirebilir.
                  </p>
                </div>
                <div className="mb-3">
                  <h5 className="my-3">
                    {" "}
                    MADDE 6 – SATICI/SAĞLAYICI’NIN AYLIK PLAN’A ve NİTELİKLİ
                    AYLIK PLAN’A İLİŞKİN HİZMET ÜCRETLERİNDE DEĞİŞİKLİK YAPMA
                    HAKKI
                  </h5>
                  <p className="mb-4">
                    SATICI/SAĞLAYICI, ALICI’nın Aylık Plan’ının / Nitelikli
                    Aylık Planı’nın bir sonraki yenileme döneminde geçerli olmak
                    üzere Aylık Plan / Nitelikli Aylık Plan ücretlerinde
                    değişiklik yapabilir. SATICI/SAĞLAYICI, Aylık Plan /
                    Nitelikli Aylık Plan ücretinde yapacağı değişikliği
                    öncesinde ALICI’ya internet sitesi veya e-posta aracılığıyla
                    bildirir. ALICI’nın değişiklik yapılmış ücret üzerinden
                    gerçekleştirdiği abonelik planı yenilemesine itiraz hakkı
                    mevcut değildir.
                  </p>
                </div>
                <div className="mb-3">
                  <h5 className="my-3">
                    {" "}
                    MADDE 7 - SATICI/SAĞLAYICI’NIN YILLIK PLAN’A ve NİTELİKLİ
                    PLAN’A İLİŞKİN HİZMET ÜCRETLERİNDE DEĞİŞİKLİK YAPMA HAKKI
                  </h5>
                  <p className="mb-4">
                    SATICI/SAĞLAYICI, ALICI’nın Yıllık Plan’ının / Nitelikli
                    Yıllık Planı’nın bir sonraki yenileme döneminde geçerli
                    olmak üzere Yıllık Plan / Nitelikli Yıllık Plan ücretlerinde
                    değişiklik yapabilir. SATICI/SAĞLAYICI, Yıllık Plan /
                    Nitelikli Yıllık Plan ücretinde yapacağı değişikliği en geç
                    30(otuz) gün öncesinde ALICI’ya internet sitesi veya e-posta
                    aracılığıyla bildirir. ALICI’nın değişiklik yapılmış ücret
                    üzerinden gerçekleştirdiği abonelik planı yenilemesine
                    itiraz hakkı mevcut değildir.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3">
                    {" "}
                    MADDE 8 – HİZMETİN SUNULACAĞI YER ve ZAMAN{" "}
                  </h5>
                  <p className="mb-4">
                    ALICI tarafından hizmetin satın alınmasının ardından dijital
                    nitelikteki içeriklerin SATICI/SAĞLAYICI tarafından
                    ALICI’nın erişimine sunulacağı yer www.wisenrise.com ve
                    Wise&Rise mobil uygulamasıdır. Hizmete ilişkin ödeme,
                    ALICI’ya ait ödeme aracından kaynaklanan ‘‘bakiye
                    yetersizliği’’ dahil her türlü teknik problem nedeniyle
                    tamamlanamadığında; dijital içerikler ALICI’nın erişimine
                    açılmayacaktır.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> MADDE 9 – CAYMA HAKKI</h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.1.</h6>
                    Yürürlükte bulunan Mesafeli Sözleşmeler Yönetmeliği’nin 4.
                    maddesi uyarınca dijital içerik; ‘‘Bilgisayar programı,
                    uygulama, oyun, müzik, video ve metin gibi dijital şekilde
                    sunulan her türlü veri ’’ olarak tanımlanmıştır. Aynı
                    yönetmelik uyarınca kalıcı veri saklayıcısı, ‘‘ Tüketicinin
                    gönderdiği veya kendisine gönderilen bilgiyi, bu bilginin
                    amacına uygun olarak makul bir süre incelemesine elverecek
                    şekilde kaydedilmesini ve değiştirilmeden kopyalanmasını
                    sağlayan ve bu bilgiye aynen ulaşılmasına imkan veren kısa
                    mesaj, elektronik posta, internet, disk, CD, DVD, hafıza
                    kartı ve benzeri her türlü araç veya ortam’’ olarak
                    tanımlanmıştır. SATICI/SAĞLAYICI tarafından bu Sözleşme
                    kapsamında ALICI’ya sunulan hizmet, bahse konu yönetmelikte
                    tanımlanan dijital içerik ve kalıcı veri saklayıcısı ile
                    iletilecek hizmet kapsamındadır.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.2.</h6>
                    Mesafeli Sözleşmeler Yönetmeliği’nin 15. (on beşinci)
                    maddesinin ‘‘ğ’’ bendinde yer alan ‘‘Elektronik ortamda
                    anında ifa edilen hizmetler veya tüketiciye anında teslim
                    edilen gayri maddi mallara ilişkin sözleşmeler’’ bakımından
                    tüketicinin cayma hakkının bulunmadığı düzenlenmektedir.
                    ALICI, SATICI/SAĞLAYICI tarafından kendisine sağlanan
                    hizmetlerin Mesafeli Sözleşmeler Yönetmeliği’nin 15. (on
                    beşinci) maddesinin ‘‘ğ’’ bendi kapsamında olduğunu ve
                    Wise&Rise’a giriş yaparak dijital içeriklere eriştiği andan
                    itibaren cayma hakkının bulunmadığını peşinen kabul eder.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">
                    {" "}
                    MADDE 10 – ÖN BİLGİLENDİRMEYE İLİŞKİN TEYİT
                  </h5>
                  <p className="mb-4">
                    ALICI işbu Sözleşme’yi onayladığında Mesafeli Sözleşmeler
                    Yönetmeliğinin 6.( altıncı) maddesinde belirtilen usullere
                    uygun olarak SATICI/SAĞLAYICI tarafından kendisine ön
                    bilgilendirmede bulunulduğunu kabul etmiş sayılır.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3">
                    MADDE 11 – BİLGİ GÜVENLİĞİ ve KİŞİSEL VERİLER{" "}
                  </h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">11.1</h6>
                    ALICI tarafından Wise&Rise’a girilen bilgilerin ve
                    işlemlerin güvenliği için gerekli önlemler, SATICI/SAĞLAYICI
                    tarafındaki sistem altyapısında, bilgi ve işlemin mahiyetine
                    göre teknik imkanları ölçüsünde alınmıştır. Ancak söz konusu
                    bilgiler ALICI tarafından ve ALICI’ya ait cihaz üzerinden
                    girildiğinden, bu bilgilerin korunması ve bu bilgilere
                    ilgisiz kişilerce erişilememesi için, virüs ve benzeri
                    zararları uygulamalara ilişkin olanlar dahil, gerekli
                    tedbirlerin alınması sorumluluğu ALICI’ya aittir.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">11.2</h6>
                    Detayları (KVKK Aydınlatma Metni)’nde izah edildiği üzere;
                    ALICI’nın abonelik hizmetini satın aldığı esnada paylaştığı
                    kişisel veriler ilgili yasalara, Kişisel Verileri Koruma
                    Kurulu kararlarına ve diğer tüm düzenlemelere uygun şekilde
                    temin edilir,
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> MADDE 12 – FİKRİ MÜLKİYET HAKLARI </h5>
                  <p className="mb-4">
                    ALICI’nın işbu Sözleşme kapsamındaki hizmetleri satın
                    almasıyla birlikte erişim hakkı elde edeceği dijital
                    içeriklere ilişkin olarak herhangi bir lisans hakkı mevcut
                    değildir ve bu Sözleşme’nin akdedilmesi ALICI’nın söz konusu
                    içerikler üzerinde lisans hakkı sağlamaz. ALICI’nın erişim
                    sağlayacağı tüm görsel/işitsel içeriklerin tüm telif hakları
                    Wise&Rise’a ve/veya içeriğin üretilmesine katkı sunan iş
                    ortaklarına aittir. ALICI’nın erişimine açılacak olan hiçbir
                    içeriği işleme, çoğaltma, yayma, temsil, umuma iletim,
                    kiralama ve/veya satma hakkı yoktur.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">
                    MADDE 13 – SORUMLULUĞUN SINIRLANDIRILMASI{" "}
                  </h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 13.1</h6>
                    SATIC/SAĞLAYICI, sunduğu hizmetler kapsamındaki dijital
                    içeriklerde yer alan bilgi, öneri ve tavsiyelerin bilimsel
                    ve/veya yorumsal doğruluğundan sorumlu değildir.
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 13.2</h6>
                    ALICI, Wise&Rise’da sunulan bilgi ve/veya hizmetlerde
                    eksiklik, iletişim sorunları, teknik problemler, altyapı
                    ve/veya internet arızaları, elektrik kesintisi veya başkaca
                    problemler olabileceğini kabul etmekte olup, bu türde
                    problemler oluşması durumunda SATICI/SAĞLAYICI gerek satış
                    başlamadan önce, gerek satış esnasında veya satış yapılmış
                    olsa dahi ALICI’ya herhangi bir bildirimde bulunmaya gerek
                    olmaksızın ve/veya sebep göstermeksizin satışı durdurmaya
                    ve/veya sona erdirmeye ve/veya iptal etmeye yetkilidir.
                    ALICI bu nedenlerle SATICI/SAĞLAYICI’dan hiçbir nam altında
                    hak veya alacak talep edemez.
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 13.3</h6>
                    SATICI/SAĞLAYICI hizmetin hatasız olacağını veya sürekli
                    sağlanacağını veya hizmetin virüs ve diğer zararlı
                    unsurlardan arınmış olduğunu garanti etmemektedir.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 13.4</h6>
                    SATICI/SAĞLAYICI’nın, ağır kusur veya kast halleri hariç
                    olmak üzere, ALICI’nın doğrudan ve/veya dolaylı
                    zararlarından sorumluluğu bulunmamaktadır.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">
                    MADDE 14 – KREDİ KARTI / BANKA KARTI BİLGİLERİNİN SAKLANMASI{" "}
                  </h5>
                  <p className="mb-4">
                    ALICI’nın kredi/banka kartı bilgileri SATICI/SAĞLAYICI
                    tarafından saklanmamaktadır. ALICI’nın kredi/banka kartı
                    bilgileri Türkiye Cumhuriyet Merkez Bankası tarafından
                    yetkilendirilen aracı ödeme kuruluşu olan ve güvenli ödeme
                    sağlayan Iyzico tarafından saklanacaktır.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">
                    MADDE 15 – SÖZLEŞMENİN FESHİ ve FESHİN SONUÇLARI
                  </h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 15.1</h6>
                    Satın alınan hizmetin Aylık Plan, Nitelikli Aylık Plan,
                    Yıllık Plan veya Nitelikli Yıllık Plan dahilinde olup
                    olmadığı fark etmeksizin; ALICI’nın işbu Sözleşme’den
                    kaynaklanan yükümlülüklerinden herhangi birine kısmen
                    ve/veya tamamen aykırı davranması durumunda,
                    SATICI/SAĞLAYICI Sözleşme’yi feshedebilir ve ALICI’nın
                    Sözleşme konusu dijital içeriklere erişim hakkını fesih
                    tarihi itibariyle sonlandırabilir.
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 15.2</h6>
                    ALICI, Aylık Plan’ı ve Nitelikli Aylık Plan’ı dilediği zaman
                    iptal ederek Sözleşme’yi feshedebilir. Aylık Plan’ın /
                    Nitelikli Aylık Plan’ın iptal edilmesi durumunda ALICI
                    ilgili aydaki bakiye süre boyunca Sözleşme konusu dijital
                    içeriklere erişim sağlayabilir.
                  </p>

                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 15.3</h6>
                    ALICI, Yıllık Plan’ı ve Nitelikli Yıllık Plan’ı iptal ederek
                    Sözleşme’yi feshedebilir. Şu kadar ki; Yıllık Plan’ın ve
                    Nitelikli Yıllık Plan’ın ALICI tarafından süresinden önce
                    iptal edilmesi durumunda; SATICI/SAĞLAYICI, ALICI’nın
                    aboneliğine son verdiği tarihe kadar ALICI’nın Yıllık Plan /
                    Nitelikli Yıllık Plan kapsamındaki taahhüdüne istinaden
                    yapmış olduğu tüm indirimleri ALICI’dan talep edebilir.
                    ALICI’nın Yıllık Plan veya Nitelikli Yıllık Plan kapsamında
                    SATICI/SAĞLAYICI’ya ödemeyi taahhüt ettiği bedelin henüz
                    tahakkuk etmemiş kısmının toplamının bu bedelden düşük
                    olması halinde, ALICI’nın lehine olan tutar ALICI’dan tahsil
                    edilir. ALICI, Yıllık Plan / Nitelikli Yıllık Plan
                    kapsamındaki taahhüdüne istinaden yapmış olduğu indirimlerin
                    (veya SATICI/SAĞLAYICI’ya ödemeyi taahhüt ettiği bedelin
                    henüz tahakkuk etmemiş kısmının toplamının bu bedelden düşük
                    olması halinde, ALICI’nın lehine olan tutarın)
                    SATICI/SAĞLAYICI tarafından, ‘‘ödeme ve kart bilgileri /
                    planlarım’’ başlıklı sayfada ALICI tarafından bilgisi
                    verilen kredi / banka kartından ödeme kuruluşu Iyzico
                    aracılığıyla tahsil edilmesine muvafakatinin bulunduğunu
                    kabul ve beyan eder. Yıllık Plan’ın veya Nitelikli Yıllık
                    Plan’ın iptal edilmesi durumunda ALICI, Yıllık Plan’ı veya
                    Nitelikli Yıllık Plan’ı iptal ettiği takvim günündeki ayın
                    sonuna kadar Sözleşme konusu dijital içeriklere erişim
                    sağlayabilir.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2"> 13.4</h6>
                    SATICI/SAĞLAYICI’nın, ağır kusur veya kast halleri hariç
                    olmak üzere, ALICI’nın doğrudan ve/veya dolaylı
                    zararlarından sorumluluğu bulunmamaktadır.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">MADDE 16 – UYUŞMAZLIKLARIN ÇÖZÜMÜ</h5>
                  <p className="mb-4">
                    Bu Sözleşme’den doğabilecek uyuşmazlıklarda, Ticaret
                    Bakanlığı’nca yasa gereği her yıl belirlenen/ilan edilen
                    parasal sınırlar dahilinde İl ve İlçe Tüketici Hakem
                    Heyetleri, bu sınırları aşan durumlarda Tüketici Mahkemeleri
                    yetkili ve görevlidir. ALICI, bu çerçevede, kendisinin veya
                    dilerse SATICI’nın yerleşim yerindeki Hakem Heyetleri ve
                    Tüketici Mahkemelerine başvurabilir.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">MADDE 17 – DELİL SÖZLEŞMESİ</h5>
                  <p className="mb-4">
                    İşbu Sözleşme’den doğabilecek tüm uyuşmazlıklarda
                    SATICI/SAĞLAYICI’ya ait ticari defter kayıtları, kalıcı veri
                    saklayıcıları, veri tabanları, sunucu ve bilgisayar
                    kayıtları ile tüm elektronik kayıtlar kesin delil teşkil
                    eder. ALICI, Sözleşme’yi onaylayarak işbu hükmün Hukuk
                    Muhakemeleri Kanunu’nun 193. maddesi uyarınca delil
                    sözleşmesi olduğunu kabul eder.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">MADDE 18 – TEBLİGATLAR</h5>
                  <p className="mb-4">
                    SATICI/SAĞLAYICI ile ALICI arasındaki her türlü bildirim ve
                    iletişim, mevzuatta sayılan zorunlu haller dışında, işbu
                    Sözleşme’de yazılı e-posta adresleri aracılığıyla
                    yapılacaktır.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">MADDE 19 – İMZA VE YÜRÜRLÜK</h5>
                  <p className="mb-4">
                    Toplam 19(on dokuz) maddeden ibaret bu Sözleşme; ALICI
                    tarafından elektronik ortamda onaylanmak suretiyle
                    kurulmuş/imzalanmış ve yürürlüğe girmiştir. Sözleşme’nin bir
                    örneği, SATICI/SAĞLAYICI tarafından ALICI’ya elektronik
                    posta aracılığıyla gönderilir.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
