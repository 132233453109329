import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as LikeIcon } from "../../../../assets/icons/likeIcon.svg";
import CustomToggle from "../../../../components/dropdowns";
import Notification from "./components/Notification";

const LeftBtn = (props) => {
  return (
    <>
      <Dropdown as="li" className="nav-item">
        <Dropdown.Toggle
          as={CustomToggle}
          variant="search-toggle position-relative">
          <Link to={"/following-teachers"}>
            <LikeIcon fill={"#fff"} className="hover-primary mr-2" />
          </Link>

          {/* <span className="bg-danger dots"></span> */}
        </Dropdown.Toggle>
        {/* <Dropdown.Menu className="iq-sub-dropdown" align="right">
          <Card className="shadow-none m-0">
            <Card.Body>
              <Link to="#" className="iq-sub-card">
                <div className="media align-items-center">
                  <img src={thumb1} className="img-fluid mr-3" alt="streamit" />
                  <div className="media-body">
                    <h6 className="mb-0 ">Boot Bitty</h6>
                    <small className="font-size-12"> just now</small>
                  </div>
                </div>
              </Link>
              <Link to="#" className="iq-sub-card">
                <div className="media align-items-center">
                  <img src={thumb2} className="img-fluid mr-3" alt="streamit" />
                  <div className="media-body">
                    <h6 className="mb-0 ">The Last Breath</h6>
                    <small className="font-size-12">15 minutes ago</small>
                  </div>
                </div>
              </Link>
              <Link to="#" className="iq-sub-card">
                <div className="media align-items-center">
                  <img src={thumb3} className="img-fluid mr-3" alt="streamit" />
                  <div className="media-body">
                    <h6 className="mb-0 ">The Hero Camp</h6>
                    <small className="font-size-12">1 hour ago</small>
                  </div>
                </div>
              </Link>
            </Card.Body>
          </Card>
        </Dropdown.Menu> */}
      </Dropdown>

      <Notification />
    </>
  );
};

export default LeftBtn;
