import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useInfiniteQuery } from "@tanstack/react-query";
import ItemCW from "../../../components/ContinuesWatching/ItemCW";
import {
  getClassroomsComplated,
  getClassroomsQ,
} from "../../../Services/Classroom";
import { getLikes } from "../../../Services/Liked";
import { useAuth } from "../../../contexts/AuthContext";

export default function AllClasses() {
  const { profile } = useAuth();
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
    ["AllCategories", profile?.id, "classrooms"],
    getClassroomsComplated,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
          return lastPage?.meta?.current_page + 1;
      },
    }
  );
  return (
    <section id="iq-tvthrillers" className="s-margin mb-5">
      <Container>
        <Row>
          <Col sm="12" className="overflow-hidden">
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
              <div className="w-0 w-xs-5 ws-md-3">
                <h4 className="main-title channel-logo w-100 text-left">
                  Tamamladığım Eğitimler
                </h4>
                <p className="w-100 text-left mt-2">
                  Takip ettiğin eğitimleri görüntüleyip listeleyebilirsin.
                </p>
              </div>

              <div className="d-flex align-items-center justify-content-between">
              {data?.pages[0]?.data?.length > 0 && ( <div className="d-flex align-items-center justify-content-between mr-3">
                  <Link to="/completed" className="btn btn-hover">
                    Tümünü Gör
                  </Link>
                </div>)}

                {/* <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {status === "loading" ? (
            <p>Yükleniyor...</p>
          ) : status === "error" ? (
            <span>Error: {error.message}</span>
          ) : (
            <>
              {data?.pages.map((page) => (
                <React.Fragment key={page.nextId}>
                  <Row>
                    {page?.data?.map((item) => (
                      <Col
                        key={item.id}
                        xs="12"
                        sm="6"
                        md="3"
                        lg="3"
                        className="mb-5"
                        style={{
                          minWidth: "300px",
                          height: "505px",
                          minHeight: "505",
                        }}>
                        <ItemCW vMax="375px" hMax="505px" item={item} />
                      </Col>
                    ))}
                  </Row>
                </React.Fragment>
              ))}
            </>
          )}
        </Row>
        <div className="d-flex justify-content-center w-100 mt-5">
          {data?.pages[0]?.data?.length < 1 && (
            <div>
              <button
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                className="btn  btn-outline-light">
                İçerik bulunamadı
              </button>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}
