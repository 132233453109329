import React from "react";
import menu from "../../../assets/icons/icons-more-vertical.png";
import { ReactComponent as MessageIcon } from "../../../assets/icons/message-circle.svg";
import "../style.css";
import { Link } from "react-router-dom";
const CommentsBlog = (props) => {
  return (
    <div >
      <div className="widget-area mb-0">
        <div className="iq-widget-menu widget d-flex justify-content-between mb-0 pb-0">
          <h6 className="">Most popular</h6>

          <img className="menu-horizontal" src={menu} />
        </div>
        <div className="iq-widget-menu widget mt-4 mb-0 pb-0">
          <div className="list-inline iq-widget-menu">
            <ul className="iq-post">
              <li className="mb-5 pb-5">
                <div className="post-img">
                  <div className="post-blog">
                    <div className="blog-box">
                      <Link className="new-link mb-2" to="#">
                        <h6>Football Coaches Are Turning</h6>
                      </Link>
                      <p className="mt-3">
                        N 1996, IBM’S Deep Blue became the first supercomputer
                        to defeat a chess grandmaster, Garry Kasparov, in a
                        game. A year later Deep…
                 
                     
                       
                      </p>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">2 hours ago</span>
                        <div>
                          <MessageIcon className="mr-1" />
                          34
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="mb-0 pb-5">
                <div className="post-img">
                  <div className="post-blog">
                    <div className="blog-box">
                      <Link className="new-link mb-2" to="#">
                        <h6>
                          Inside the Incredible Visual Effects of “Star Wars"
                        </h6>
                      </Link>
                      <p className="mt-3">
                        In the intervening year and a half, small quadcopter
                        drones have become even more affordable and more broadly
                        available.
                      </p>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">2 hours ago</span>
                        <div>
                          <MessageIcon className="mr-1" />
                          34
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="widget-area last-item p-0 mt-0">
        <div className="iq-widget-menu widget ">
          <div className="list-inline iq-widget-menu">
            <ul className="iq-post">
              <li className="mb-0 mt-4">
       
                <div className="post-img">
                  <div className="post-blog">
                    <div className="blog-box">
                      <Link className="new-link mb-2" to="#">
                        <h6>
                          Inside the Incredible Visual Effects of “Star Wars"
                        </h6>
                      </Link>
                      <p className="mt-3">
                        In the intervening year and a half, small quadcopter
                        drones have…
                      </p>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">2 hours ago</span>
                        <div>
                          <MessageIcon className="mr-1" />
                          34
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsBlog;
