import React, { memo, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import style from './style.module.css';
import { deleteNote, getNotes } from '../../../Services/Note';
import './style.css';
import { useApi } from '../../../contexts/RequestContext';
import { notify, notifyError } from '../../../components/AlartNotify/AlertNotify';

function NoteItem({ item, setCreateNote, setUpdateNode, setNewNote, setNoteId }) {
  const { Refresh, refresh } = useApi();
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getNotes('0', `?video_id=${item.id}&`).then((res) => {
      setNotes(res?.data);
    });

    return () => {};
  }, [item, refresh]);
  const del = (id) => {
    Swal.fire({
      title: 'Onayla!',
      text: 'Silmek istediğine emin misin?',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, Sil',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNote(id)
          .then((res) => {
            notify('Başarılı Silindi');
            Refresh();
          })
          .catch((err) => {
            notifyError(err.response.data.error);
          });
      } else if (result.isDenied) {
      }
    });
  };
  if (notes?.length < 1) return null;
  return (
    <div className={`${style.addCard1} mt-3`}>
      {notes?.map((item, index) => {
        return (
          <div key={index} className={`w-100 ${style.wsNoteCart}`}>
            <div className={`d-flex justify-content-between w-100 mb-1 mt-2`}>
              <span>{item?.duration}</span>
              <div>
                <i
                  onClick={() => {
                    setCreateNote(true);
                    setUpdateNode(true);
                    setNewNote(item.content);
                    setNoteId(item?.id);
                  }}
                  className="fa fa-pencil mr-2"
                ></i>
                <i onClick={() => del(item?.id)} className="fa fa-trash mr-2 ml-2"></i>
              </div>
            </div>
            <div className={`text-left ${style.createText}`}>{item?.content}</div>
          </div>
        );
      })}
    </div>
  );
}

export default memo(NoteItem);
