import React, { useState, useEffect } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { getClassrooms } from '../../../Services/Classroom';
import './style.css';
import VideoModal from '../../../components/VideoModal';
import NewVideoTitle from '../../../components/VideoComponents/NewVideoTitle';

const NewClassroomBlock = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [item, setItem] = useState();
  useEffect(() => {
    getClassrooms('?page[size]=1')
      .then((res) => {
        setItem(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);

  return (
    <>
      <div
        className="slick-bg slick-bg-new-card"
        style={{
          backgroundImage: `url(${item?.banner?.path})`,
          backgroundPositionX: '20%',
          backgroundSize: 'cover',
        }}
      >
        <Row className="d-flex align-items-center h-100">
          <Col xs="4" sm="6" md="8" className="text-center">
            <div onClick={handleShow} className="video-open playbtn d-none d-md-block cursor-pointer">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="80px"
                height="80px"
                viewBox="0 0 213.7 213.7"
                enableBackground="new 0 0 213.7 213.7"
                xmlSpace="preserve"
              >
                <polygon
                  className="triangle"
                  fill="none"
                  strokeWidth="7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points="73.5,62.5 148.5,105.8 73.5,149.1 "
                />
                <circle
                  className="circle"
                  fill="none"
                  strokeWidth="7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  cx="106.8"
                  cy="106.8"
                  r="103.3"
                />
              </svg>
              <span className="w-trailor"></span>
            </div>
          </Col>
          <Col xs="8" sm="6" md="4" className="d-flex">
            <NewVideoTitle item={item} news={true} />
          </Col>
        </Row>
      </div>

      <Modal size="md" show={show} onHide={handleClose} centered style={{ background: '#000', height: '100vh', margin: 0 }}>
        <Modal.Body style={{ background: '#000', height: '100vh', border: 0, padding: 0 }}>
          <VideoModal handleClose={handleClose} item={item} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewClassroomBlock;
