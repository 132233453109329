import React from 'react';
import closeIcon from '../../assets/icons/x.png';
import style from './style.module.css';
import { Link } from 'react-router-dom';

const TeacherModal = ({ item, handleClose }) => {
  return (
    <div className={`p-3 ${style.modal}`}>
      <div className={`d-flex justify-content-between align-items-start ${style.header}`}>
        <div className="d-flex   justify-content-center" style={{ width: '90%' }}>
          <div className="mr-4 float-start">
            <div className={style.wsAbout} style={{ cursor: 'pointer' }}>
              <img src={item?.photo?.path} title={item?.name} alt={item?.name} />
            </div>
            <div className="position-absolute top-4"></div>
          </div>
          <div className="text-left">
            {item?.classrooms?.length > 0 ? (
              <Link to={`/${item?.slug}/${item?.classrooms[0]?.slug}`}>
                <h5 style={{ cursor: 'pointer' }} className="slider-text title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">
                  {item?.name}
                </h5>
              </Link>
            ) : (
              <h5 style={{ cursor: 'pointer' }} className="slider-text title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">
                {item?.name}
              </h5>
            )}

            {/* <h6 className="text-body mt-2">{item?.categories?.map((item)=>{
              return item?.name+', ';
            })}</h6> */}
            <div className="d-flex  mt-3">
              <div>
                <span className="text-primary">
                  {item?.categories
                    ?.map((item) => {
                      return item?.name;
                    })
                    .toString()}
                </span>
              </div>
            </div>
            <div className="d-none d-md-block">{item?.description}</div>
          </div>
        </div>

        <div className="" style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <img width="30" onClick={handleClose} src={closeIcon} />
        </div>
      </div>
      <div className="d-block d-md-none mt-3">{item?.description}</div>
    </div>
  );
};

export default TeacherModal;
