import React from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/x.png';
import './style.css';
import { useAuth } from '../../contexts/AuthContext';
import { useApi } from '../../contexts/RequestContext';

const LeftMenu = () => {
  const { openMenu, inLogin, profile, isAdmin } = useAuth();
  const { setCat } = useApi();

  return (
    <div className="menu-page">
      <div className="menu-page-header">
        <img onClick={openMenu} width="30" src={closeIcon} />
      </div>
      <div className="menu-page-body">
        <ul>
          <li className="menu-item">
            <Link onClick={openMenu} to="/" title="Anasayfa">
              Anasayfa
            </Link>
          </li>
          {(!inLogin || (inLogin && profile !== null)) && (
            <li className="menu-item ">
              <Link
                to="/kategoriler"
                onClick={() => {
                  setCat('kategoriler');
                  openMenu();
                }}
                title={'Kategoriler'}
              >
                Kategoriler
              </Link>
            </li>
          )}

          {!inLogin && (
            <li className="menu-item">
              <Link onClick={openMenu} to="/uyelik-planlari" title="Planlar">
                Planlar
              </Link>
            </li>
          )}

          {inLogin && profile !== null && (
            <>
              <li className="menu-item">
                <Link
                  onClick={() => {
                    setCat('kategoriler');
                    openMenu();
                  }}
                  to="/kategoriler"
                  title="Keşfet"
                >
                  Keşfet
                </Link>
              </li>
              {/* {isAdmin && (
                <li className="menu-item">
                  <Link onClick={openMenu} to="/admin-page">
                    Admin
                  </Link>
                </li>
              )} */}
            </>
          )}
          <li className="menu-item">
            <Link onClick={openMenu} to="/iletisim" title="Bize Ulaşın">
              İletişim
            </Link>
          </li>
          <li>
            <Link onClick={openMenu} to="/sikca-sorulan-sorular" title="Sıkça Sorulan Sorular">
              Sıkça Sorulan Sorular
            </Link>
          </li>
          <li>
            <Link onClick={openMenu} to="/iletisim" title="Yardım Merkezi ">
              Yardım Merkezi
            </Link>
          </li>
          <li>
            <Link onClick={openMenu} to="/uyelik-sozlesmesi" title="Abonelik Şartları">
              Abonelik Şartları
            </Link>
          </li>
          <li>
            <Link onClick={openMenu} to="/gizlilik-politikasi" title="Gizlilik Politikası">
              Gizlilik Politikası
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LeftMenu;
