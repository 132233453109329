import React from "react";
import { Link } from "react-router-dom";

export const LoginBtn = () => {
  return (
    <div className=" d-flex align-items-center">
      {" "}
      <Link to="/login"      role="button" className="btn  btn-link">
        Giriş
      </Link>
      <Link
        to="/sign-up"
        role="button"
        className="btn btn-hover  "
      
      >
        
        Kayıt Ol
      </Link>
    </div>
  );
};
