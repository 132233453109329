import axios from 'axios'



const API = process.env.REACT_APP_BASE_ENDPOIND;
const likes = `${API}profiles`



export const  getLikes = async ({pageParam=0,queryKey}) =>{
  
    const type = queryKey[2];
    const id = queryKey[1]
    const {data} = await axios.get(`${likes}/${id}/likes/${type}?page[number]=${pageParam}&page[size]=12`);
    return data;
}  
export const setLike = (id, type,profileId ) => {
    return axios
      .post(`${likes}/${profileId}/likes/${type}/${id}`)
      .then((response) => response.data)

  }
  export const delLike = (id, type,profileId ) => {
    return axios
      .delete(`${likes}/${profileId}/likes/${type}/${id}`)
      .then((response) => response.data)

  }