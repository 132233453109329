import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import about from "../../../assets/images/lessons/about.png";
import style from "./style.module.css";
import closeIcon from "../../../assets/icons/x.png";

export default function NoteItemHeader({ type, handleClose1, item }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className={`${style.wsNateCard}`} style={{ border: "none" }}>
        <div className="mt-4 mt-md-2">
          <img
            className={style.Mask}
            src={item?.thumbnail ? item?.thumbnail?.path : about}
          />
        </div>
        <div className="text-left w-100 d-flex mt-4 mt-md-2 justify-content-between flex-wrap">
          <div className="">
            <h6 className="text-left mb-1">{item?.name}</h6>
            <span className={"text-muted"}>{item?.teacher?.name} </span>
            <span className="text-muted">
              {" "}
              {"·"} {item?.category?.name}
            </span>
          </div>
        </div>
        <div
          className=""
          style={{ position: "absolute", top: "0", right: "5px" }}>
          <img width="30" onClick={handleClose1} src={closeIcon} />
        </div>
      </div>
    </>
  );
}
