import React, { useEffect } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import '../style.css'

const InputMessage = (props) => {
  const textRef = React.useRef();
  const [value, setValue] = React.useState();
  const onChnage = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [value]);
  return (
    <div>
      <Form>
        <FormGroup className="form-input">
          <FormControl
            type="textarea"
            as="textarea"
            rows={1}
            placeholder="Write a comment"
            className="pl-4 FormControl"
            style={{borderRadius:'10px'}}
          />
           <i className="fa fa-paper-plane"></i>
        </FormGroup>
      </Form>
    </div>
  );
};

export default InputMessage;
