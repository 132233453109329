import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Nav, Tab } from 'react-bootstrap';
import IconUser from '../../../assets/icons/icons-user.svg';
import { gsap } from 'gsap';
import '../style.css';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { useAuth } from '../../../contexts/AuthContext';
import bg from '../../../assets/images/bg/r3.jpg';
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: '',
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '');

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const FooterCard = () => {
  const { inLogin } = useAuth();

  const animationInit = () => {
    if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {
      const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };
  return (
    <>
      <div id="home-slider" className="slider ">
        <div
          className="slide slick-bg p-0 pt-5 w-100"
          style={{
            maxHeight: '305px',
            background: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center right',
            backgroundSize: '70%',
          }}
        >
          <Container className="">
            <div className=" my-5">
              <h4 className="slider-text big-title title text-uppercase" style={{ lineHieght: '50px' }}>
                Sıkça Sorulan Sorular
              </h4>
              <div className="d-flex flex-wrap align-items-center text-start"></div>
              <div className="d-flex align-items-center r-mb-23 mt-3" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                <Link to="/sikca-sorulan-sorular" className="btn btn-hover">
                  Görüntüle
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default FooterCard;
