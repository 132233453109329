import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function TermsConditions() {
  return (
    <div>
      <div className=" " style={{ paddingTop: "80px", paddingBottom: "60px" }}>
        <Container style={{ background: "#14161a" }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav
                  aria-label="breadcrumb"
                  className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Üyelik Sözleşmesi</h1>
                  <span>
                    Homepage /{" "}
                    <span className="text-primary">Üyelik Sözleşmesi</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy text-left">
                {/* <h5>
                  Online Üyelik Sözleşmesi En son güncellenme tarihi 3 Aralık,
                  2022
                </h5> */}
                <br />
                <div className="mb-3">
                  <h5> 1. TARAFLAR</h5>
                  <p className="my-3">
                    İşbu Üyelik Sözleşmesi (<b>‘‘Sözleşme’’</b> ) Veranova Medya
                    ve Dijital Yayın Hizmetleri A.Ş.’nin (“Veranova Medya”)
                    sahibi olduğu <b>‘‘Wise&Rise’’</b> isimli dijital platform(
                    <b>‘‘Wise&Rise’’</b> ) aracılığıyla Veranova Medya’nın
                    sunacağı hizmetlerden faydalanmak isteyen kullanıcı ile
                    Veranova Medya arasındaki üyelik koşullarına ilişkin usul ve
                    esasları belirlemek amacıyla düzenlenmiştir.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="mb-3"> 2. ÜYELİK</h5>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">2.1.</h6> Veranova Medya,
                    sahibi olduğu Wise&Rise isimli dijital platforma üye olan
                    kişilere; internete bağlı telefon, bilgisayar, televizyon
                    gibi Wise&Rise’a erişme imkânı sağlayan tüm cihazlar
                    aracılığıyla internet teknolojisi üzerinden, çeşitli
                    konularda uzmanlığı / yetkinliği bulunan eğitmenlerin icra
                    ettiği / yer aldığı içeriklere erişilebilmesini sağlayan bir
                    üyelik hizmeti sunar. Bu üyelik hizmeti,{" "}
                    <span className=" text-primary">tercih ettiğiniz</span>{" "}
                    abonelik türüne göre sınırlı sayıdaki bazı kişilerin
                    kullanımına sunma imkânı tanıyabilmekle birlikte, kural
                    olarak kişiye özel niteliktedir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">2.2.</h6> Veranova Medya
                    tarafından belirlenecek bazı Wise&Rise içeriklerinin, üyeler
                    tarafından cihaza indirmek(download) suretiyle
                    çevrimdışı(offline) olarak erişilebilmesi mümkündür.
                    Veranova Medya, üyelerinin çevrimdışı olarak erişebilmesine
                    imkân sağladığı içeriklerine{" "}
                    <i className="font-italic">
                      {" "}
                      -sayılanlarla sınırlı olmamak üzere-
                    </i>{" "}
                    izleme sayısı, periyodu ve erişim süresi gibi kısıtlamalar
                    uygulayabilir.
                  </p>

                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">2.3.</h6>
                    Okudum,onaylıyorum yaptığınızda / butonuna tıkladığınızda,
                    Sözleşme’nin tüm maddelerinin her satırını okuduğunuzu ve
                    tam olarak anladığınızı kabul etmiş sayılırsınız. Bu nedenle
                    Sözleşme’deki tüm hükümleri lütfen dikkatlice okuyunuz.
                    Okudum,onaylıyorum yaptığınıza / butonuna tıkladığınıza
                    ilişkin beyanınızın (dijital ortamdaki eyleminizin)
                    Wise&Rise’ın bilişim sistemlerine ulaşmasıyla birlikte
                    üyelik işlemi gerçekleşmiş ve tamamlanmış olur.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">2.4.</h6>
                    On sekiz(18) yaşından küçük kişilerin Wise&Rise’a üye olması
                    Veranova Medya tarafından kabul edilmemektedir. 18 yaşından
                    küçük kişilerin Wise & Rise içeriklerinden yararlanabilmesi
                    için bir yetişkin gözetiminde olmaları gerekmektedir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">2.5.</h6>
                    Wise&Rise üyesinin, Veranova Medya tarafından Wise&Rise
                    üzerinden verilen hizmeti kullanabilmesi için,
                    ‘‘www.wisenrise.com’’ adresine ulaşmasına ve adreste yer
                    alan görsel-işitsel kayıtları/videoları izleyebilmesine
                    imkân sağlayan bir internet erişimine ve cihaza sahip olması
                    gerekir.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">2.6.</h6>
                    Wise&Rise üyeliği (1) üyenin işbu Sözleşme şartlarına
                    uyduğu, (2) eğer üye ile Veranova Medya arasında ‘‘Çevrimiçi
                    Abonelik Hizmeti Satın Alımına İlişkin Mesafeli Sözleşme’’(
                    <b>‘‘Mesafeli Sözleşme’’</b>) akdedildiyse Mesafeli Sözleşme
                    şartlarına uyduğu, (3) üyenin hizmet alma iradesi devam
                    ettiği müddetçe; Veranova Medya tarafından belirlenen süre
                    boyunca devam eder. Şüpheye mahal vermeme adına; Veranova
                    Medya ile üye arasında Mesafeli Sözleşme akdedilmesi,
                    kişinin Wise&Rise’a üye olabilmesi için şart
                    koşulmamaktadır.
                  </p>

                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="mb-3">3. ÜYENİN SORUMLULUKLARI</h5>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.1.</h6>
                    Kişinin işbu Sözleşme ile Wise&Rise’a üye olması, kişiye
                    Wise&Rise üzerindeki videolar, yazılı metin dokümanları
                    üzerinde hiçbir surette lisans hakkı tanımaz. Üyelerin
                    erişim sağlayacağı tüm görsel-işitsel ve/veya yazılı
                    içeriklerin tüm telif hakları Veranova Medya’ya veya
                    içeriğin üretilmesine katkı sağlayan iş ortaklarına aittir.
                    Üyelerin erişimine açılacak olan hiçbir içeriği işleme,
                    çoğaltma, yayma, temsil, umuma iletim, kiralama ve/veya
                    satma hakkı yoktur.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.2.</h6>
                    Üye, üyelik süreci boyunca yürürlükteki tüm mevzuat
                    hükümlerine uygun davranacağını, gerek mevzuat gerekse
                    Veranova Medya’nın işbu Sözleşme’de belirtilen haklarını
                    kullanması neticesinde ortaya çıkabilecek sınırlamalara
                    riayet edeceğini kabul eder.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.3.</h6>
                    Üye, Wise&Rise üzerinde oluşturulmuş korumaların ve
                    kısıtlamaların hiçbirini devre dışı bırakmayacağını,
                    herhangi bir şekilde işlevselliklerini yitirmelerini
                    sağlamayacağını taahhüt eder.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.4.</h6>
                    Üye, Wise&Rise’a erişmek için robot, örümcek, kazıyıcı ve
                    başkaca otomatik yollar kullanmayacaktır. Üye, Wise&Rise
                    üzerinden erişilebilen herhangi bir yazılımı ya da diğer
                    ürünleri veya süreçleri kaynak koda dönüştürmemeyi, bunlar
                    üzerinde parçalara ayırma işlemi yapmamayı; Wise&Rise’ın
                    içeriğine herhangi bir kod veya ürün yerleştirmemeyi veya
                    herhangi bir şekilde manipüle etmemeyi; herhangi bir veri
                    oluşturma, veri toplama ya da çıkarma yöntemi kullanmamayı;
                    her türlü yazılım virüsü ya da herhangi başka bir bilgisayar
                    kodu, dosyaları veya programları dâhil olmak üzere,
                    Wise&Rise ile ilişkili herhangi bir bilgisayar yazılımı ya
                    da donanımı veya telekomünikasyon ekipmanının işlevselliğini
                    kesintiye uğratmak, zedelemek veya sınırlandırmak için
                    tasarlanmış herhangi bir materyali yüklememeyi,
                    göndermemeyi, e-posta ile veya başka şekilde göndermemeyi
                    veya iletmemeyi kabul eder.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.5.</h6>
                    Üyenin işbu Sözleşme’de belirtilen veya yürürlükteki
                    mevzuattan kaynaklı başkaca yükümlülüklerini ihlal etmesi
                    halinde, üyeliği derhal sonlandırılabilecek olup Veranova
                    Medya’nın fazlaya ilişkin her türlü talep ve dava hakkı
                    saklı olduğu gibi üyenin üçüncü kişilere vermiş olduğu
                    zararlara ilişkin sorumluluğu bulunmamaktadır.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.6.</h6>
                    Üye, Wise&Rise üzerinden oluşturduğu hesap aracılığıyla
                    gerçekleşen tüm işlemlerden sorumludur. Üye, hesabın
                    kontrolünü sürdürmek ve başkalarının hesaba erişmesini
                    önlemek adına, hesabın şifresini kimseyle paylaşmamalıdır ve
                    Wise&Rise’a giriş yaptığı/oturum açtığı tüm cihazların
                    kontrolünü elinde bulundurmalıdır.{" "}
                    <span className="text-primary">
                      {" "}
                      Şu kadar ki; Üyenin çoklu kullanıma elverişli ‘‘Aile’’
                      paketini satın alması halinde, paketini birlikte kullanmak
                      üzere e-posta adresini ve şifresini paylaştığı kişilerle
                      yapacağı bilgi paylaşımı işbu hükmün ihlali teşkil
                      etmeyecektir.
                    </span>
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.7.</h6>
                    Üye; reşit olduğunu, Wise&Rise üzerinde üyelik oluşturma
                    işlemleri sırasında Wise&Rise’a sağladığı her türlü bilginin
                    doğru ve güncel olduğunu ve söz konusu bilgilerin güncel
                    ve/veya doğru olmamasından kaynaklanabilecek her türlü
                    sorumluluğun ve yükümlülüğün bizzat kendisine ait olduğunu
                    kabul ve taahhüt eder.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.8.</h6>
                    Üye, Wise&Rise üzerinde üyelik oluşturma işlemleri sırasında
                    belirlediği şifresinin gizli kalması için gerekli dikkat ve
                    özeni göstereceğini, şifresini herhangi bir üçüncü şahsa
                    açıklamayacağını, kullandırmayacağını, şifresinin yetkisiz
                    üçüncü şahıslar tarafından ele geçirildiğini öğrenmesi veya
                    buna ilişkin şüpheli bir durum oluşması halinde derhal
                    Veranova Medya’ya info@wisenrise.com e-posta adresi
                    üzerinden bildirimde bulunacağını, şifresinin üçüncü
                    şahıslar tarafından kullanılması sebebiyle doğacak
                    zararlardan Veranova Medya’nın sorumlu olmayacağını kabul ve
                    taahhüt eder.
                    <span className="text-primary">
                      {" "}
                      Şu kadar ki; Üyenin çoklu kullanıma elverişli ‘‘Aile’’
                      paketini satın alması halinde, paketini birlikte kullanmak
                      üzere e-posta adresini ve şifresini paylaştığı kişilerle
                      yapacağı bilgi paylaşımı işbu hükmün ihlali teşkil
                      etmeyecektir.
                    </span>
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.9.</h6>
                    Üye, Wise&Rise üzerinden sağlanacak içeriklere, içeriklerin
                    çeşitliliğine, sayısına ve içeriklerde yer alan tüm bilgi,
                    öneri ve tavsiyelerin bilimsel ve/veya yorumsal doğruluğuna
                    ilişkin olarak Veranova Medya tarafından hiçbir taahhütte
                    bulunulmadığını, Veranova Medya’nın Wise&Rise üzerindeki
                    içerikleri dilediği şekilde artırma, azaltma ve değiştirme
                    hakkına sahip olduğunu kabul, beyan ve taahhüt eder.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.10.</h6>
                    Üye ile Veranova Medya arasında -işbu Sözleşme’ye ilaveten-
                    Mesafeli Sözleşme akdedilmesi durumunda; işbu Sözleşme,
                    Mesafeli Sözleşme ile birlikte geçerli olmaya devam eder.
                    Anılan iki sözleşme arasında çelişki olması halinde,
                    <span className="text-primary">Mesafeli</span> Sözleşme
                    öncelikli olarak uygulanır.
                  </p>
                  <p className="mb-2">
                    <h6 className=" d-inline mr-2">3.11.</h6>
                    Üye, üyeliğe konu dijital içerik hizmetlerin temel
                    nitelikleri ve diğer tüm hususlarda bilgi sahibi olduğunu ve
                    işbu Sözleşme’nin ve Sözleşme’de yer alan üyelik
                    koşullarının bağlayıcı olduğunu kabul ve beyan eder.
                  </p>
                  <br />
                </div>
                <div className="mb-3">
                  <h5 className="mb-3"> 4. ÜYELİK KOŞULLARINDA DEĞİŞİKLİK </h5>
                  <p className="">
                    Veranova Medya’nın işbu Sözleşme ile düzenlenen üyelik
                    koşullarında değişiklik yapma hakkı saklıdır. Şu kadar ki;
                    Sözleşme’de yapılan herhangi bir değişiklik, üyeye bildirim
                    yapılmasını takip eden en erken 30 (otuz) gün sonra geçerli
                    olacaktır. Sözleşme’de düzenlenen üyelik koşullarındaki
                    değişikliğin Üye tarafından kabul edilmemesi durumunda;
                    Veranova Medya, üyeliği askıya alma veya sözleşmeyi feshetme
                    hakkına sahip olacaktır.
                  </p>
                  <br />
                </div>
                <div className="mb-0">
                  <h5 className="mb-3">
                    {" "}
                    5. ÜYELİK HAKKININ DEVREDİLEMEZLİĞİ{" "}
                  </h5>
                  <p className="mb-4">
                    İşbu Sözleşme ile üyeye sağlanan üyelik hakkı, yalnızca
                    ücretsiz içeriklerin kullanıcı tarafından görüntülenmesine
                    izin veren sınırlı bir hak olup üçüncü kişilere
                    devredilemez.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> 6. BİREYSEL AMAÇLA KULLANIM</h5>
                  <p className="mb-4">
                    Wise&Rise üzerinden üyeye sağlanan her türlü içerik, üyenin
                    kişisel ve ticari nitelikte olmayan kullanımına yöneliktir.
                    Üye, Wise&Rise’da yer alan içeriklerin işbu Sözleşme
                    kapsamında sadece bireysel amaç ile kullanılabileceğini ve
                    ticari nitelikteki kullanımlara konu edilemeyeceğini kabul
                    ve taahhüt eder.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3">
                    {" "}
                    7. KİŞİSEL VERİLERİN KORUNMASI ve ÇEREZLER
                  </h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">7.1.</h6>
                    Üyenin Wise&Rise içeriklerine göz atabilmesi ve üyeye daha
                    iyi bir hizmet sunulabilmesi adına, www.wisenrise.com
                    internet adresi üzerinden giriş yaptığınız bilgiler
                    doğrultusunda ad-soyad, doğum tarihi, telefon numarası,
                    e-posta adresi gibi kişisel veriler kaydedilmektedir.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">7.2.</h6>
                    Wise&Rise üyelerinin kişisel verilerinin işlenmesi
                    hakkındaki detaylı bilgiye, www.wisenrise.com adresinde yer
                    alan KVKK Aydınlatma Metninden ulaşabilirsiniz.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">7.3.</h6>
                    Üye, Wise&Rise’da yer alan çerez(tanımlama bilgisi)
                    uygulamalarını kabul etmesi halinde, çerezler(tanımlama
                    bilgileri) www.wisenrise.com adresinde yer alan Çerez
                    Politikası uyarınca üyenin cihazına yerleştirilir.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> 8. MUHTELİF HÜKÜMLER</h5>
                  <p className="mb-4">
                    Sözleşme’nin herhangi bir veya birden fazla hükmünün
                    geçersiz, yasalara aykırı veya uygulanamaz ilan edilmesi
                    durumunda, kalan hükümlerin geçerliliği bu durumdan
                    etkilenmeyecektir.
                  </p>
                  <p className="mb-4">
                    Üyelik ile ilgili her türlü bilgi, üyeye yalnızca elektronik
                    ortamda ve elektronik formatta aktarılacaktır. Üye
                    tarafından sağlanmış olan iletişim bilgilerinde bir
                    değişiklik olması halinde; üye, bu değişikliği derhal
                    Veranova Medya’ya info@wisenrise.com e-posta adresi
                    üzerinden bildirmelidir.
                  </p>
                  <p className="mb-4">
                    Üye, internette yaşanan kesintiler, hız düşmesi ve benzeri
                    sebeplerle hizmette yaşanacak kesinti, görüntü kalitesi ve
                    güvenlik problemlerinden ve/veya ülkede genel bir
                    karışıklığın ortaya çıkması savaş, terör, grev, genel
                    elektrik kesintisi, deprem, su baskını, pandemi, olağanüstü
                    hal, siber saldırı, cihazlara virüs bulaşmış olması veya
                    benzeri bir mücbir sebeple hizmetin verilmesine ilişkin
                    yaşanacak sorunlardan Veranova Medya’nın sorumlu
                    olmayacağını kabul ve taahhüt eder.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3">
                    {" "}
                    9. SORUMLULUK SINIRLAMASI ve GARANTİ REDDİ
                  </h5>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.1.</h6>
                    Wise&Rise üzerinden sağlanacak içeriklere, içeriklerin
                    çeşitliliğine, sayısına ve içeriklerde yer alan tüm bilgi,
                    öneri ve tavsiyelerin bilimsel ve/veya yorumsal doğruluğuna
                    ilişkin olarak Veranova Medya tarafından herhangi bir
                    surette taahhütte bulunulmamaktadır.
                  </p>
                  <p className="mb-4">
                    <h6 className=" d-inline mr-2">9.2.</h6>
                    Veranova Medya’nın, Wise&Rise’da yer alan ve üçüncü
                    taraflara ait bağlantılı linklerde yer alan reklamların
                    konusunu teşkil edebilecek ürün ve/veya hizmetlere ilişkin
                    hiçbir sorumluluğu bulunmamaktadır.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3">
                    {" "}
                    10. SÖZLEŞMENİN FESHİ ve ÜYELİK İPTALİ{" "}
                  </h5>
                  <p className="mb-4">
                    Hem üye hem de Veranova Medya, işbu Sözleşme’yi herhangi bir
                    gerekçe belirtmeksizin tek taraflı şekilde feshetmek
                    suretiyle üyeliği iptal edebilir. Üyeliğin iptal edilmesi
                    halinde, Wise&Rise’da yer alan içerikler üyenin erişimine
                    kapatılır. Mesafeli Sözleşme hükümleri saklıdır.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> 11. DEVİR </h5>
                  <p className="mb-4">
                    Veranova Medya, işbu Sözleşme’nin üye tarafından kabul
                    edilmesiyle kurulan üyelik ilişkisine dair haklarını ve
                    yükümlülüklerini herhangi bir zamanda bir başkasına devir ve
                    temlik edebilir.
                  </p>
                </div>
                <div className="mb-0">
                  <h5 className="mb-3"> 12. UYUŞMAZLIKLARIN ÇÖZÜMÜ </h5>
                  <p className="mb-4">
                    İşbu Sözleşme’den kaynaklanabilecek ihtilaflarda İstanbul
                    Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkilidir.
                  </p>
                </div>

                <div className="mb-0">
                  <h5 className="mb-3"> 13. YÜRÜRLÜK </h5>
                  <p className="mb-4">
                    İşbu Sözleşme, üye tarafından okunarak -elektronik ortamda-
                    kabul edildiği ve kabul beyanının Wise&Rise bilişim
                    sistemlerine ulaştığı anda yürürlüğe girecek ve taraflardan
                    herhangi biri tarafından feshedilinceye dek yürürlükte
                    kalacaktır.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
