import React, { useState } from 'react';
import { Dropdown, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../../../../components/Card';
import CustomToggle from '../../../../components/dropdowns';
import iconSetting from '../../../../assets/icons/icons-settings.png';
import iconBiyo from '../../../../assets/icons/icons-life-buoy.png';
import iconCreditCard from '../../../../assets/icons/icons-credit-card.png';
import { useAuth } from '../../../../contexts/AuthContext';
import './style.css';
import LeftBtn from './LeftBtn';
import EditProfile from '../../../../Pages/CreateProfile/EditProfile';

export const Profile = () => {
  const { profile, logout, user } = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#000' }}>
          <EditProfile profile={profile} handleClose={handleClose} isSetting={true} />
        </Modal.Body>
      </Modal>
      {profile !== null && <LeftBtn />}
      <Dropdown as="li" className="nav-item nav-icon">
        <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle">
          <div className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active" data-toggle="search-toggle">
            {profile ? (
              <img src={profile?.avatar?.path} className="img-fluid avatar-30 rounded" alt={profile?.avatar?.name} />
            ) : (
              <div
                className="img-fluid avatar-50 rounded-circle d-flex justify-content-center align-items-center"
                style={{ fontSize: '18px', background: '#272C32' }}
              >
                {user?.email?.toUpperCase()?.slice(0, 1)}
                {user?.email?.toUpperCase()?.slice(1, 2)}
              </div>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="iq-sub-dropdown iq-user-dropdown" align="right">
          <Card className="shadow-none m-0 p-1">
            <Card.Header className="p-3 pb-1 border-bottom" style={{ opacity: '.1', borderBottom: '1px' }}>
              <Row>
                <Col xs="3" className=" text-xs-start text-md-right">
                  <div className=" d-inline-block ">
                    {profile ? (
                      <img src={profile?.avatar?.path} className="profile-pic avatar-50 rounded img-fluid" alt="user" />
                    ) : (
                      <div
                        className="profile-pic avatar-50 rounded-circle text-white d-flex justify-content-center align-items-center"
                        style={{ fontSize: '23px', background: '#BE161A' }}
                      >
                        {user?.email?.toUpperCase()?.slice(0, 1)}
                        {user?.email?.toUpperCase()?.slice(1, 2)}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs="9" className="d-flex align-items-center " style={{ overflow: 'hidden' }}>
                  <div className="text-left w-100">
                    <h6> {profile ? profile?.name : `${user?.name}`}</h6>
                    <p className="mt-1 text-ellipsis" style={{ color: ' rgba(255, 255, 255, 0.502817)' }}>
                      {' '}
                      {user?.email}
                    </p>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0 pl-3 pr-3">
              <Link to="/who-is-watch" className="iq-sub-card setting-dropdown">
                <div className="media align-items-center">
                  <div className="right-icon">
                    <i className="ri-file-user-line text-light"></i>
                  </div>
                  <div className="media-body ml-3">
                    <h6 className="my-0 ">Profil{user?.plan_type !== 'Individual' && 'i Değiştir'} </h6>
                  </div>
                </div>
              </Link>
              {profile?.is_main && (
                <Link to="/profile" className="iq-sub-card setting-dropdown">
                  <div className="media align-items-center">
                    <div className="right-icon">
                      <img width={'20px'} src={iconSetting} alt={'settings'} />
                    </div>
                    <div className="media-body ml-3">
                      <h6 className="my-0 ">Hesap Ayarları</h6>
                    </div>
                  </div>
                </Link>
              )}
              {profile && !profile?.is_main && (
                <div onClick={handleShow} className="iq-sub-card setting-dropdown cursor-pointer">
                  <div className="media align-items-center">
                    <div className="right-icon">
                      <img width={'20px'} src={iconSetting} alt={'settings'} />
                    </div>
                    <div className="media-body ml-3">
                      <h6 className="my-0 ">Profili Düzenle</h6>
                    </div>
                  </div>
                </div>
              )}

              <Link to="/uyelik-planlari" className="iq-sub-card setting-dropdown">
                <div className="media align-items-center">
                  <div className="right-icon">
                    <img width={'20px'} src={iconCreditCard} alt={'credit-card'} />
                  </div>
                  <div className="media-body ml-3">
                    <h6 className="my-0 ">Planlarım</h6>
                  </div>
                </div>
              </Link>
              <Link to="/sikca-sorulan-sorular" className="iq-sub-card setting-dropdown">
                <div className="media align-items-center">
                  <div className="right-icon">
                    <img width={'20px'} src={iconBiyo} alt={'biyo'} />
                  </div>
                  <div className="media-body ml-3">
                    <h6 className="my-0 ">Yardım Destek</h6>
                  </div>
                </div>
              </Link>
              {/* <Link to="/login" className="iq-sub-card setting-dropdown">
                <div className="media align-items-center" onClick={logout}>
                  <div className="right-icon">
                    <img width={"20px"} src={iconLoguot} />
                  </div>
                  <div className="media-body ml-3">
                    <h6 className="my-0 ">Çıkış Yap</h6>
                  </div>
                </div>
              </Link> */}
              <div className="w-100 text-center">
                <div className="btn btn-hover mt-3 w-100 mb-4" style={{ borderRadius: '6px' }} onClick={logout}>
                  Çıkış Yap
                </div>
              </div>
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
