import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeIcon from '../../assets/icons/icons-x.png';
import './style.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAvatars } from '../../Services/AvatarApi';
import { updateProfile } from '../../Services/Profile';
import { useAuth } from '../../contexts/AuthContext';
import { notify, notifyError } from '../../components/AlartNotify/AlertNotify';

const SignuoSchema = Yup.object().shape({
  name: Yup.string().trim().min(3, 'İsim en az 3 karakter olmalı!').max(50, 'En fazla 50 karakter olmalı!').required('İsim zorunlu alan!'),
});

const EditProfile = ({ profile, handleClose, isSetting }) => {
  const { Profile, Refresh } = useAuth();
  const initialValues = {
    name: profile?.name,
    avatar: profile?.avatar?.name,
    language: profile?.language,
    timezone: profile?.timezone,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: SignuoSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let body = {};
      if (avatarName) {
        body = {
          name: values?.name,
          avatar_id: avatarName,
          language: values?.language,
          timezone: values?.timezone,
        };
      } else {
        body = {
          name: values?.name,
          language: values?.language,
          timezone: values?.timezone,
        };
      }

      updateProfile(profile?.id, body)
        .then((data) => {
          notify('Başarı İle Güncellendi');
          if (isSetting) Profile(data.data?.data);
          Refresh();
          handleClose();
        })
        .catch((err) => {
          notifyError(err?.response?.data?.message);
        });

      //history.push("/set-password");
    },
  });
  const [avatar, setAvatar] = useState(profile?.avatar?.path);
  const [avatarName, setAvatarName] = useState(null);
  const [listAvatar, setListAvatar] = useState([]);
  const [isVisible, setIsVisible] = useState('none');

  useEffect(() => {
    getAvatars()
      .then((data) => {
        setListAvatar(data?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Container>
        <Row className="justify-content-center align-items-center ">
          <Col md="11" className="align-self-center">
            <div className="sign-user_card ">
              <div className="sign-in-page-data">
                <div className="close">
                  <img onClick={handleClose} src={closeIcon} alt={'close-icon'} />
                </div>
                <div className="sign-in-from w-100 m-auto">
                  <h5 className="mb-3 text-center">Profili Düzenle</h5>
                  <h6 className="mb-3 text-center">Profil ismini ve emojini seçerek profil oluşturabilirsin.</h6>
                  <div className="d-flex justify-content-center">
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsVisible(isVisible === 'none' ? 'block' : 'none');
                      }}
                      width={'90'}
                      height={'90'}
                      src={avatar}
                    />
                  </div>
                  <Form onSubmit={formik.handleSubmit} noValidate>
                    <Row className="mb-3">
                      <Col md="12">
                        <Form.Group className="emoji">
                          <Form.Label>İsim ve Soy İsmi</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control mb-0 "
                            id="exampleInputname2"
                            {...formik.getFieldProps('name')}
                            name="name"
                            placeholder="İsim Gir"
                            autoComplete="off"
                            required
                          />
                          <div
                            className="ws-select-icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setIsVisible(isVisible === 'none' ? 'block' : 'none');
                            }}
                          >
                            <img src={avatar} />
                          </div>

                          {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.name}
                              </span>
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="ws-emoji-edit-card" style={{ display: isVisible }}>
                          <div className="ws-emoji-card-body">
                            <Row className="ws-emoji-card-body-list">
                              {listAvatar?.map((item, index) => {
                                return (
                                  <Col
                                    key={index}
                                    xs="4"
                                    sm="3"
                                    md="3"
                                    className="mt-3 position-relative"
                                    onClick={() => {
                                      setAvatarName(item?.id);
                                      setAvatar(item?.file?.path);
                                      setIsVisible(isVisible === 'none' ? 'block' : 'none');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {' '}
                                    <img src={item?.file?.path} width="80px" />
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="6">
                        {' '}
                        <Button onClick={handleClose} className="btn btn-outline-light w-100">
                          İptal
                        </Button>
                      </Col>
                      <Col xs="6">
                        {' '}
                        <Button type="submit" className="btn btn-hover btn-primary1 w-100">
                          Kaydet
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProfile;
