import axios from 'axios'
const API = process.env.REACT_APP_BASE_ENDPOIND;
const messages = `${API}messages`




export const sendMessages = async (body) =>{
    const {data} = await axios.post(`${messages}`,body);

    return data;
}  