import "./style.css";
import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OtpInput from "react-verification-code-input";

const useStyles = makeStyles({
  root: {
    width: "100% !important",
    "& input": {
      padding: "8px",
      margin: "8px",
      border: "1px solid #292828 !important",
      borderRadius: "5px !important",
      backgroundColor: "#1f1d1d",
      fontSize: "30px",
      fontWeight: 500,
      color: "white"
    },
    "& input:focus": {
      border: "2px solid #292828 !important",
      caretColor: "#292524"
    },
    "& p": {
      color: "#292524"
    }
  }
});

export default function App() {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  //const placeholder = ["*", "*", "*", "*"];

  // const handleOnComplete = (value: string) => {
  //   console.log("complete", value);
  //   setValues(value.split(""));
  // };

  const handleOnChange = (value) => {
    if (value?.length === 5) {
      setValues(value?.split(""));
    }
    if (value?.length === 0) {
      setValues([]);
    }
    setValues(["1", "3", "5", "6", "7"]);
  };


  return (
    <OtpInput
      values={values}
      title={""}
      type={"number"}
      fields={5}
      fieldWidth={60}
      fieldHeight={60}
      autoFocus={true}
      loading={false}
      className={classes.root}
      //placeholder={placeholder}
      onChange={(value) => handleOnChange(value)}
      //onComplete={(value) => handleOnComplete(value)}
    />
  );
}