import React, { useState } from "react";
import { delLike, setLike } from "../../../Services/Liked";
import { useAuth } from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddToSaveBtn = ({ item }) => {
  const { profile } = useAuth();
  const [isLike, setIsLike] = useState(item?.is_favorite);
  const notify = (text) => {
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const setLikes = (item) => {
    if (!isLike) {
      setLike(item.id, "classrooms", profile?.id).then(() => {
        setIsLike(!isLike);
        notify("Favorilere Eklendi");
      });
    } else {
      delLike(item.id, "classrooms", profile?.id).then(() => {
        setIsLike(!isLike);
        notify("Favorilerden Çıkarıldı.");
      });
    }
  };
  return (
    <>
      <div>
        <div
          onClick={() => setLikes(item)}
          to="/"
          className="btn btn-link d-flex align-items-center">
          {isLike ? (
            <i
              style={{ color: "red", fontSize: "20px" }}
              className="fa fa-heart hover-primary mr-2"></i>
          ) : (
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-heart btn-hover-primary hover-primary mr-2"></i>
          )}
          {isLike ? " Eklendi" : "Listene Ekle"}
        </div>
      </div>
    </>
  );
};

export default AddToSaveBtn;
