import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import LiderSlide from '../../components/ClassesSlider/LiderSlide';
import OtherTeacherList from '../../components/OtherTeacherList/OtherTeacherList';
import VideoItem1 from '../../components/VideoComponents/VideoItem1';
import DownloadGuid from '../LessonsDetail/components/DownloadGuid';
import PageTitle from './components/PageTitle';
import TeacherImgSlider from './components/TeacherImgSlider';

export default function TeacherDetail() {
  return (
    <div>
      <PageTitle />

      <TeacherImgSlider />
      <Container>
        <section>
          <Row>
            <Col xs="12" sm="12" md="6" className="text-left ">
              <span className="text-uppercase text-primary ">about</span>
              <h3 className="mt-2">About Teacher</h3>
              <p className="mt-4">
                Yilmaz Erdoğan spent his childhood in Ankara until he moved to Istanbul along with his family. His family is of Kurdish origin. In
                1987, he dropped out of his civil engineering studies at Istanbul Technical University and joined the On Duty Theater Staff (Turkish:
                Nöbetçi Tiyatrosu) managed by Ferhan Şensoy. He also became chief screenwriter at Levent Kırca’s long-running television sketch show,
                Olacak O Kadar. In 1988, he founded his own theatre company, Güldüşündürü, and staged a successful production of his self-penned
                Suleiman the Magnificent and Rambo (Turkish: Kanuni Sultan Süleyman ve Rambo). In 1994, he founded Beşiktaş Cultural Center (Turkish:
                Beşiktaş Kültür Merkezi) with business partner Necati Akpınar and achieved recognition as Mükremin Abi alongside actress Demet Akbağ
                in the ground breaking television series Bir Demet Tiyatro (1995–2002) on Star TV. He also became chief screenwriter at Levent Kırca’s
                long-running television sketch show, Olacak O Kadar.
              </p>
            </Col>
            <Col xs="12" sm="12" md="6">
              {/* <div className="d-flex align-items-center justify-content-between mb-5">
                <h5 className="main-title channel-logo w-100 text-left">
                  Teachers’s Classes
                </h5>
              </div>
              <div>
                <VideoItem1
                  vMax="385px"
                  hMax="498px"
                  img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
                />
              </div> */}
            </Col>
          </Row>
        </section>
        <section>
          <div className="mt-5">
            <Row className="align-items-center">
              <Col sm="12" fluid>
                <div className="text-center iq-breadcrumb-two">
                  <h6 className=" text-primary mb-4 text-uppercase">İlgini Çekebilecek Eğitmenler</h6>
                  <h3 className="title mb-5">Other Classes in Arts & Entertainment, Music</h3>
                </div>
              </Col>
            </Row>
            <OtherTeacherList />
          </div>
        </section>
      </Container>
      <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
        <div className="mb-5 mt-5">
          <Container>
            <DownloadGuid title="İlgini Çekebilecek Eğitimler" desc="E-posta adresini gir. İlgini çekebilecek içeriklerimizden haberdar olun. " />
          </Container>
        </div>
      </section>
    </div>
  );
}
