import React, { useState } from "react";

const QuestıonItem = ({ item }) => {
  const [faq, setfaq] = useState(false);
  return (
    <div
      className={`iq-accordion-block 2  ${faq ? "iq-active" : ""}`}
      onClick={() => {
        setfaq(!faq);
      }}>
      <div className="iq-accordion-title">
        <div className="iq-icon-right">
          {!faq ? (
            <i aria-hidden="true" className="fa fa-plus"></i>
          ) : (
            <i aria-hidden="true" className="fa fa-minus"></i>
          )}
        </div>
        <h4 className="mb-0 d-none d-md-block accordion-title iq-heading-title">
          {item?.title}
        </h4>
        <h5 className="mb-0 d-block d-md-none accordion-title iq-heading-title">
          {item?.title}
        </h5>
      </div>
      <div className={`iq-accordion-details ${faq ? "d-block" : "d-none"}`}>
        <p className="iq-content-text">{item?.description}</p>
      </div>
    </div>
  );
};

export default QuestıonItem;
