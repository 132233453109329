import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const API = process.env.REACT_APP_BASE_ENDPOIND;
const teachers = `${API}teachers`;

export const getTeachers = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(`${teachers}?page[number]=${pageParam}&page[size]=${queryKey[1]}`);
  return data;
};
export const getPopularTeachers = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(`${teachers}/${queryKey[1]}?page[number]=${pageParam}&page[size]`);
  return data;
};
export const getTeachersForfilters = async () => {
  const { data } = await axios.get(`${teachers}?page[number]=0&page[size]=200`);
  return data;
};
export const getTeacher = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(`${teachers}/${queryKey[0]}?page[number]=${pageParam}&page[size]=12`);
  return data;
};

export const getTeachersFilter = async ({ pageParam = 0, queryKey }) => {
  let param = queryKey[1];
  if (param === '') param = '?';
  else param = '?category_id=' + param + '&';
  const { data } = await axios.get(`${teachers}${param}page[number]=${pageParam}&page[size]=12`);
  return data;
};

export const createTeachers = (body) => {
  return axios.post(teachers, body).then((response) => response.data);
};

export const updateTeacher = (item) => {
  const { slug, body } = item;
  return axios.put(`${teachers}/${slug}`, body).then((response) => response.data);
};
export const updatePhotoTeacher = (item) => {
  const { slug, formData } = item;
  return axios.post(`${teachers}/${slug}/photo`, formData).then((response) => response.data);
};
export const updateLogoTeacher = (item) => {
  const { slug, formData } = item;
  return axios.post(`${teachers}/${slug}/logos`, formData).then((response) => response.data);
};
export const deleteTeacher = (slug) => {
  return axios.delete(`${teachers}/${slug}`).then((response) => response.data);
};
