import React from 'react';
import { Link } from 'react-router-dom';
import xIcon from '../../assets/icons/x.png';
import { useAuth } from '../../contexts/AuthContext';
import './style.css';
const VideoHeaderTitle = ({ handleClose, titleHeader, item }) => {
  const { inLogin } = useAuth();
  return (
    <div className="video-header w-100 position-relative d-flex justify-content-between align-items-center ">
      <div className="d-flex flex-row align-items-center">
        {item?.teacher && (
          <div
            style={{
              background: `url(${item?.teacher?.photo?.path})`,
              backgroundSize: 'cover',
            }}
            className="profile-pic avatar-50 rounded-circle img-fluid"
          >
            {/* <img
            src={item?.teacher?.photo?.path}
            className="profile-pic avatar-70 rounded-circle img-fluid"
            alt="user"
          /> */}
          </div>
        )}

        <div className="text-left  ml-3 mt-3">
          <h5 style={{ textTransform: 'capitalize' }}> {item?.name}</h5>
          <p className="mt-1" style={{ color: ' rgba(255, 255, 255, 0.502817)' }}>
            {item?.category?.name}
          </p>
        </div>
      </div>
      <div>
        {!inLogin && (
          <Link to="/sign-up" style={{ position: 'absolute', top: '15px', right: '40px' }} className="d-none d-lg-block btn btn-hover mr-4">
            Üye Ol
          </Link>
        )}
        <img onClick={handleClose} className="model-close-icon  cursor-pointer" style={{ top: '20px', right: '10px' }} src={xIcon} />
      </div>
    </div>
  );
};

export default VideoHeaderTitle;
