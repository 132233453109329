import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Nav, Tab } from 'react-bootstrap';
import IconUser from '../../../assets/icons/icons-user.svg';
import { gsap } from 'gsap';
import '../style.css';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { useAuth } from '../../../contexts/AuthContext';
import bg from '../../../assets/images/bg/r3.jpg';
import bg2 from '../../../assets/images/bg/Album Grid.png';
import { isMobile } from '../../../utils/isMobil';
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: '',
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '');

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const HomeFooterComponent = ({ type, price }) => {
  const { inLogin } = useAuth();
  const [bgImg, setBg] = useState(type !== 'classes' ? bg : bg);

  const animationInit = () => {
    if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {
      const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };
  return (
    <>
      <Swiper
        navigation={{
          prevEl: '#prev5',
          nextEl: '#next5',
        }}
        pagination={{
          clickable: true,
        }}
        onInit={() => {
          animationInit();
        }}
        onSlideChangeTransitionStart={() => animationInit()}
        loop={false}
        id="home-slider"
        className="slider m-0 p-0"
      >
        <SwiperSlide
          className="slide slick-bg slick-bg11 home-footer-card-bg"
          style={{
            maxHeight: '498px',
            background: `url(${bgImg})`,
          }}
        >
          <Container className="position-relative h-100 pl-0 pl-xs-1 pl-md-5 ml-3">
            <div className="slider-inner h-100">
              <Row className="align-items-center h-100 ">
                <Col xl="7" lg="12" md="12">
                  <div className="d-flex flex-wrap align-items-center mb-2"></div>
                  <h4 className="slider-text big-title title text-capitalize" style={{ lineHieght: '50px' }}>
                    Wise&Rise İle Türkiye’nin En İyilerini İzle
                  </h4>
                  <div className="d-flex flex-wrap align-items-center text-start">
                    <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" className="text-left">
                      İzledikçe Geliş, {isMobile() ? <br /> : ''} Geliştikçe Dönüş!
                    </p>
                  </div>
                  <div
                    className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-start"
                    data-iq-gsap="onStart"
                    data-iq-position-y="80"
                    data-iq-delay="0.8"
                  >
                    {inLogin ? (
                      ''
                    ) : (
                      <div className="d-flex flex-column flex-md-row mt-3 mt-md-0">
                        <Link to="/sign-up" className="btn btn-hover">
                          Hesabını oluştur
                        </Link>
                        <Link to="/uyelik-planlari" className="btn btn-link p-2">
                          <div className="price btn-link">
                            <span className="price-item btn-link"> {price[2]?.price} ₺ / Peşin </span>
                            <span className="price-item btn-link"> 99 ₺(Aylık) / Taksitli</span>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl="5" lg="12" md="12" className="trailor-video text-center">
                  {/* {type === 'classes' && <img height={'130%'} src={bg2} />} */}
                </Col>
              </Row>
            </div>
          </Container>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
export default HomeFooterComponent;
