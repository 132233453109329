import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import about from "../../../assets/images/lessons/about.png";
import NotesModal from "./NotesModal";
import style from "./style.module.css";
import closeIcon from "../../../assets/icons/x.png";

export default function NoteItem({ type, handleClose1, item }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const open = () => {
    if (type === "slider") handleShow();
  };
  return (
    <>
      <div
        className={`${style.wsNateCard} ${
          type === "slider" ? "cursor-pointer" : ""
        }`}
        style={{ border: type == "modal" ? "none" : "" }}
        onClick={open}>
        <div className="d-flex align-items-center">
          <div>
            <img
              className={style.Mask}
              src={item?.thumbnail ? item?.thumbnail?.path : about}
            />
          </div>
          <div className="text-left w-100 d-flex justify-content-between flex-wrap">
            <div className="">
              <h6 className="text-left mb-1">{item?.name}</h6>
              <span
                className={type != "slider" ? "text-primary" : "text-muted"}>
                {item?.classroom?.name}{" "}
              </span>
              <span className="text-muted"> {item?.category?.name}</span>
            </div>
          </div>
        </div>

        {type == "page" && (
          <div className="pr-4">
            <div onClick={handleShow} className="btn btn-hover ">
              Notları Görüntüle({item?.total_note})
            </div>
          </div>
        )}
        {type == "slider" && (
          <div className={`${style.notesCount}`}>{item?.total_note}</div>
        )}
      </div>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: "#14161A" }}>
          <NotesModal type={"modal"} handleClose={handleClose} item={item} />
        </Modal.Body>
      </Modal>
    </>
  );
}
