import React, { memo, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import { ModalHeader } from '../../ModalHeader';
import ClassroomAdd from '../../../Pages/Admin/Classroom/AddClassroom';
import { Link } from 'react-router-dom';
import ShereBtn from '../../ShereBtn';
import { useAuth } from '../../../contexts/AuthContext';
import { notify, notifyError } from '../../AlartNotify/AlertNotify';
import { delLike, setLike } from '../../../Services/Liked';
import menu from '../../../assets/icons/icons-more-vertical.png';
import { ReactComponent as NotificationIcon } from '../../../assets/icons/notificationIcon.svg';
import SendNotification from '../../../Pages/Admin/components/SendNotification';

const ClassroomDropdown = ({ item, mutation }) => {
  const { inLogin, isAdmin, Refresh, profile } = useAuth();
  const [isLike, setIsLike] = useState(item?.is_favorite);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [classroomId, setClassroomId] = useState(null);

  const del = (id) => {
    Swal.fire({
      title: 'Onayla!',
      text: 'Silmek istediğine emin misin?',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, Sil',
      cancelButtonText: 'İptal',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        mutation.mutate(id);
      } else if (result.isDenied) {
      }
    });
  };
  const setLikes = () => {
    if (!inLogin) {
      notifyError('Üye Olmalısın!');
      return;
    }

    if (!isLike) {
      setLike(item?.id, 'classrooms', profile?.id).then(() => {
        setIsLike((prev) => !prev);
        notify('Favorilere Eklendi');
      });
    } else {
      delLike(item?.id, 'classrooms', profile?.id).then(() => {
        setIsLike((prev) => !prev);
        notify('Favorilerden Çıkarıldı.');
      });
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <div>
      {!isAdmin ? (
        <div className="ws-card-menu-icon btn-hover-primary cursor-pointer ">
          {isLike ? (
            <i onClick={setLikes} style={{ color: 'red', fontSize: '20px' }} className="fa fa-heart hover-primary"></i>
          ) : (
            <i onClick={setLikes} style={{ fontSize: '20px' }} className="fa fa-heart btn-hover-primary hover-primary"></i>
          )}
        </div>
      ) : (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ws-card-menu-icon">
          <DropdownToggle className="cursor-pointer" tag="div">
            <img src={menu} alt="" />
          </DropdownToggle>
          <DropdownMenu className="bg-dark ">
            <DropdownItem className="text-white h2">
              {' '}
              <Link to={`/${item?.teacher?.slug}/${item?.slug}`}>
                {' '}
                <i className="fa fa-eye mr-2"></i>İncele{' '}
              </Link>
            </DropdownItem>
            {inLogin && (
              <DropdownItem onClick={setLikes} className="text-white h2">
                {' '}
                <i className="ri-heart-fill mr-2"></i> {isLike ? 'Favorilerden Kaldır' : 'Favorilere Ekle'}{' '}
              </DropdownItem>
            )}
            {isAdmin && (
              <DropdownItem onClick={handleShow} className="text-white h2">
                <i className="fa fa-pencil mr-2"></i> Düzenle
              </DropdownItem>
            )}
            {isAdmin && (
              <DropdownItem onClick={() => del(item?.slug)} className="text-white h2">
                <i className="fa fa-trash mr-2"></i> Sil
              </DropdownItem>
            )}
            {isAdmin && (
              <DropdownItem
                onClick={() => {
                  handleShow1();
                  setClassroomId(item?.id);
                }}
                className="text-white h2"
              >
                <NotificationIcon width={'15'} className="hover-primary" /> Bildirim Gönder
              </DropdownItem>
            )}
            <div className="pl-4">
              <ShereBtn link={'classrooms/cevik-manifesto'} type={false} />
            </div>
          </DropdownMenu>
        </Dropdown>
      )}

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#14161A' }}>
          <ModalHeader handleClose={handleClose} titleHeader={'Eğitim Güncelle'} />
          <ClassroomAdd classroom={item} handleClose={handleClose} update={true} />
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={show1} onHide={handleClose1} centered>
        <Modal.Body style={{ background: '#14161A' }}>
          <ModalHeader handleClose={handleClose1} titleHeader={'Bildirim Gönder'} />
          <SendNotification handleClose={handleClose1} classroomId={classroomId} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default memo(ClassroomDropdown);
