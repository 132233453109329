import React, { useState, useEffect } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import Multiselect from "multiselect-react-dropdown";
import { Col, Row, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createClassrom,
  getClassroomsPathFile,
  updateBannerClassrom,
  updateClassrom,
  updateCoverClassrom,
  updateDocClassrom,
  updateThumClassrom,
  updateVideoClassrom,
} from "../../../Services/Classroom";
import { useApi } from "../../../contexts/RequestContext";
import {
  getTeachers,
  getTeachersForfilters,
} from "../../../Services/TeachersApi";
import {
  notify,
  notifyError,
} from "../../../components/AlartNotify/AlertNotify";
import SelectDoc from "./SelectDoc";
import SelectImg from "./SelectImg";

const teacherSchema = Yup.object().shape({
  name: Yup.string().min(3, "En az 3 karakter olmalı!").required(),
  description: Yup.string().min(8).required(),
  category_id: Yup.number().required(),
  teacher_id: Yup.string().required(),
  raw_files_path: Yup.string(),
  coming_soon: Yup.string().required(),
  specs: Yup.string().required(),
});

export default function ClassroomAdd({ classroom, handleClose, update }) {
  const [teacher, setTeacher] = useState([]);
  const initialValues = {
    ...classroom,
    name: classroom?.name,
    description: classroom?.description,
    category_id: classroom?.category?.id,
    teacher_id: classroom?.teacher?.id,
    raw_files_path: classroom?.raw_files_path,
    teaser: classroom?.teaser,
    coming_soon: classroom?.coming_soon,
    specs: classroom?.specs,
  };
  const { categories } = useApi();
  const [thumbnail, setThumbnail] = useState(null);
  const [banner, setBanner] = useState(null);
  const [doc, setDocument] = useState(null);
  const [cover, setCover] = useState(null);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateClassrom,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:AllClasses");
      notify("Başarılı güncellendi!");
      handleClose();
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationThumbail = useMutation({
    mutationFn: updateThumClassrom,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:AllClasses");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationCover = useMutation({
    mutationFn: updateCoverClassrom,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:AllClasses");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationBanner = useMutation({
    mutationFn: updateBannerClassrom,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:AllClasses");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationVideo = useMutation({
    mutationFn: updateVideoClassrom,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:AllClasses");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationDoc = useMutation({
    mutationFn: updateDocClassrom,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:AllClasses");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  const mutationCreate = useMutation({
    mutationFn: createClassrom,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("Admin:AllClasses");
      notify("Başarılı eklendi!");
      handleClose();
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  // const { status, data } = useInfiniteQuery(
  //   ["Admin:Teachers"],
  //   getTeachersForfilters,
  //   {
  //     getNextPageParam: (lastPage) => {
  //       if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
  //         return lastPage?.meta?.current_page + 1;
  //     },
  //   }
  // );
  const [filePath, setFilePath] = useState([]);

  useEffect(() => {
    if (update)
      (async () =>
        await getClassroomsPathFile(classroom?.slug)
          .then((res) => {
            setFilePath(res?.data);
          })
          .catch((err) => {
            console.log(err);
          }))(classroom?.slug);
    (async () =>
      await getTeachersForfilters()
        .then((res) => {
          setTeacher(res?.data);
        })
        .catch((err) => {
          console.log(err);
        }))();
    return () => {};
  }, []);

  let formData = new FormData();
  const formik = useFormik({
    initialValues,
    validationSchema: teacherSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (update) {
        if (thumbnail) {
          formData.append("thumbnail", thumbnail);
          await mutationThumbail.mutate({ slug: classroom.slug, formData });
        }
        if (cover) {
          formData.append("cover", cover);
          await mutationCover.mutate({ slug: classroom.slug, formData });
        }
        if (banner) {
          formData.append("banner", banner);
          await mutationBanner.mutate({ slug: classroom.slug, formData });
        }
        if (doc) {
          formData.append("document", doc);
          await mutationDoc.mutate({ slug: classroom.slug, formData });
        }
        if (values?.teaser) {
          const file = {
            teaser: values?.teaser,
          };
          await mutationVideo.mutate({ slug: classroom.slug, file });
        }
        const body = {
          category_id: Number(values.category_id),
          teacher_id: values.teacher_id,
          name: values.name,
          description: values.description,
          raw_files_path: values.raw_files_path,
          coming_soon: values?.coming_soon,
          specs: values?.specs,
        };
        mutation.mutate({ slug: classroom.slug, body });
      } else {
        try {
          formData.append("name", values.name);
          formData.append("description", values.description);
          formData.append("category_id", values.category_id);
          formData.append("teacher_id", values.teacher_id);
          formData.append("thumbnail", thumbnail);
          formData.append("banner", banner);
          formData.append("document", doc);
          formData.append("raw_files_path", values.raw_files_path);
          formData.append("coming_soon", values.coming_soon);
          formData.append("specs", values.specs);
          formData.append("cover", cover);

          await mutationCreate.mutate(formData);
        } catch (error) {
          console.log(error);
        }
      }
    },
  });
  const search = (e) => {
    console.log("1212");
  };
  return (
    <Row
      className="row align-items-center m-profile justify-content-center h-100"
      style={{ background: "transparent", padding: "20px" }}
    >
      <Col lg="12">
        <div className="p-5 text-left">
          <Row>
            <Col className="device-margin">
              <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate>
                <Row>
                  <Col sm="6">
                    <Form.Group className="">
                      <Form.Label
                        htmlFor="name"
                        style={{ color: "white" }}
                        className="mb-2"
                      >
                        Başlık <span className="text-primary">*</span>
                      </Form.Label>

                      {/* <Row> */}
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps("name")}
                        name="name"
                        className="form-control mb-0"
                        id="exampleInputName"
                        placeholder="Enter Titile"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.name}
                          </span>
                        </div>
                      )}
                      {/* </Row> */}
                    </Form.Group>
                  </Col>
                  <Col sm="6" className="">
                    <Form.Group>
                      <Form.Label
                        htmlFor="raw_files_path"
                        style={{ color: "white" }}
                        className="mb-2"
                      >
                        Path <span className="text-primary">*</span>
                      </Form.Label>

                      {/* <Row> */}
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps("raw_files_path")}
                        name="raw_files_path"
                        className="form-control mb-0"
                        id="exampleInputraw_files_path"
                        placeholder="Enter path name"
                        autoComplete="off"
                        required
                      />
                      {/* </Row> */}

                      {formik.touched.raw_files_path &&
                        formik.errors.raw_files_path && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.raw_files_path}
                            </span>
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6" className="multi">
                    <Form.Label
                      htmlFor="description"
                      style={{ color: "white" }}
                    >
                      Kategori Seç <span className="text-primary">*</span>
                    </Form.Label>
                    <select
                      className="mt-2"
                      data-control="select"
                      data-hide-search="true"
                      {...formik.getFieldProps("category_id")}
                      name="category_id"
                      defaultValue={"null"}
                    >
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"null"}
                      >
                        Seç
                      </option>
                      {categories?.map((item) => {
                        return (
                          <option
                            style={{ background: "#000", color: "#fff" }}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {formik.touched.category_id &&
                      formik.errors.category_id && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.category_id}
                            </span>
                          </div>
                        </div>
                      )}
                  </Col>
                  <Col sm="6" className="multi">
                    <Form.Label
                      htmlFor="description"
                      style={{ color: "white" }}
                    >
                      Öğretmen Seç<span className="text-primary">*</span>
                    </Form.Label>
                    <select
                      className="mt-2"
                      data-control="select2"
                      data-hide-search="true"
                      {...formik.getFieldProps("teacher_id")}
                      name="teacher_id"
                      defaultValue={"null"}
                      id="d1"
                      onmousedown="if(this.options.length>5){this.size=5;}"
                    >
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"null"}
                      >
                        Seç
                      </option>

                      {teacher?.map((item) => {
                        return (
                          <option
                            style={{ background: "#000", color: "#fff" }}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {formik.touched.teacher_id && formik.errors.teacher_id && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span className="text-primary" role="alert">
                            {formik.errors.teacher_id}
                          </span>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col sm="12">
                    <Form.Group>
                      <Form.Label
                        htmlFor="description"
                        style={{ color: "white" }}
                        className="mt-3 mb-2"
                      >
                        Açıklama <span className="text-primary">*</span>
                      </Form.Label>

                      <textarea
                        type="text"
                        {...formik.getFieldProps("description")}
                        name="description"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Enter description"
                        autoComplete="off"
                        required
                        style={{ lineHeight: "25px" }}
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.description}
                            </span>
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <SelectImg
                    title={"Dikey Resim Seç"}
                    img={classroom?.thumbnail?.path}
                    setImg={setThumbnail}
                  />
                  <SelectImg
                    title={"Yatay Resimini Seç"}
                    img={classroom?.banner?.path}
                    setImg={setBanner}
                  />
                  <SelectImg
                    title={"Header Resimini Seç"}
                    img={classroom?.cover?.path}
                    setImg={setCover}
                  />
                  <SelectDoc
                    title={"Belge Seç"}
                    doc={classroom?.document}
                    setDoc={setDocument}
                  />
                  <Col sm="6" className="multi">
                    <Form.Label
                      htmlFor="description"
                      style={{ color: "white" }}
                    >
                      Yakında Geleceklere Ekle{" "}
                      <span className="text-primary">*</span>
                    </Form.Label>
                    <select
                      className="mt-2"
                      data-control="select"
                      data-hide-search="true"
                      {...formik.getFieldProps("coming_soon")}
                      name="coming_soon"
                      defaultValue={"null"}
                    >
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"null"}
                      >
                        Seç
                      </option>
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"0"}
                      >
                        Hayır
                      </option>
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"1"}
                      >
                        Evet
                      </option>
                    </select>
                    {formik.touched.coming_soon &&
                      formik.errors.coming_soon && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.coming_soon}
                            </span>
                          </div>
                        </div>
                      )}
                  </Col>
                  <Col sm="6" className="multi">
                    <Form.Label
                      htmlFor="description"
                      style={{ color: "white" }}
                    >
                      Yayınla <span className="text-primary">*</span>
                    </Form.Label>
                    <select
                      className="mt-2"
                      data-control="select"
                      data-hide-search="true"
                      {...formik.getFieldProps("specs")}
                      name="specs"
                      defaultValue={"null"}
                    >
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"null"}
                      >
                        Seç
                      </option>
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"0"}
                      >
                        Hayır
                      </option>
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"1"}
                      >
                        Evet
                      </option>
                    </select>
                    {formik.touched.specs && formik.errors.specs && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span className="text-primary" role="alert">
                            {formik.errors.specs}
                          </span>
                        </div>
                      </div>
                    )}
                  </Col>
                  {update && (
                    <Col sm="6" className="multi">
                      <Form.Label style={{ color: "white" }}>
                        Giriş Videosu Seç{" "}
                        <span className="text-primary">*</span>
                      </Form.Label>
                      <select
                        className="mt-2"
                        data-control="select"
                        data-hide-search="true"
                        {...formik.getFieldProps("teaser")}
                        name="teaser"
                      >
                        <option
                          style={{ background: "#000", color: "#fff" }}
                          value={"0"}
                        >
                          Seç
                        </option>
                        {filePath?.map((item, index) => {
                          return (
                            <option
                              style={{ background: "#000", color: "#fff" }}
                              key={index}
                              value={item}
                            >
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.teaser && formik.errors.teaser && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.teaser}
                            </span>
                          </div>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
                <div className="mt-5">
                  <Row>
                    <Col sm="12">
                      <div className="d-flex justify-content-end">
                        <div onClick={handleClose} className="btn btn-link">
                          İptal
                        </div>
                        <button
                          disabled={
                            (formik.isSubmitting ||
                              !formik.isValid ||
                              !formik.touched) &&
                            !update
                          }
                          type="submit"
                          className="btn btn-hover"
                        >
                          Kaydet
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
