import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import backtick from '../../../assets/images/bg/backtick.png';
import '../style.css';

export default function CEOComent() {
  return (
    <div className="mt-5 pb-0">
      <Container className="mt-3 mobil-max-width-container mobil-btn-wrapper pt-4">
        <Row className="align-items-center">
          <Col sm="12" fluid>
            <div className="mb-0 w-100  mobil-content">
              <img className="mb-5" src={backtick} />
              <p className="m-auto mobil-sub-title" style={{ maxWidth: '680px' }}>
                “Herkes kendi talihinin mimarıdır; faber est suae quisque fortunae. ”
              </p>
              <div className="mt-4">
                <div className="author-title mobil-title">Appius Claudius Caecus</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
