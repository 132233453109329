import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import style from './style.module.css';
import 'react-toastify/dist/ReactToastify.css';
import ClassroomDropdown from '../UI/Dropdowns/ClassroomDropdown';
import { useApi } from '../../contexts/RequestContext';

export default function ItemCW(props) {
  const { videoSet, teacherSet, setCat } = useApi();
  const history = useHistory();
  const lastWatch = () => {
    for (let i = props?.item?.videos.length - 1; i >= 0; i--) {
      if (props?.item?.videos[i]?.current_duration !== '00:00:00') return i;
    }
    return 0;
  };
  const open = () => {
    teacherSet(props?.item?.teacher);
    videoSet(props?.item);
    history.push(`/${props?.item?.teacher?.slug}/${props?.item?.slug}/${props?.item?.videos[lastWatch()]?.slug}`);
  };
  const openCategory = () => {
    setCat(props?.item?.category);
    history.push(`/${props?.item?.category?.slug}`);
  };
  return (
    <div
      className={`${style.wsCard} ws-card position-relative`}
      style={{
        // maxWidth: props.wMax,
        // maxHeight: props.hMax,
        // minWidth: props.wMax,
        // minHeight: props.hMax,
        background: `url(${props?.item?.thumbnail?.path})`,
      }}
    >
      <div onClick={openCategory} className={`text-white ${style.wsCardHeaderLeft}`}>
        {props?.item?.category?.name}
      </div>
      {/* <img
        className=""
        src={props?.item?.thumbnail?.path}
        alt=""
        style={{maxWidth:props.wMax,maxHeight:props.hMax,minWidth:props.wMax,minHeight:props.hMax,backgroundImage:`url(${props?.item?.thumbnail?.path})`}}
      /> */}

      <ClassroomDropdown item={props?.item} mutation={''} />
      <div className="ws-card-img-bg"></div>
      <div className={`${style.wsFooter}`}>
        <div className="block-description d-flex align-items-start justify-content-end mb-5">
          <h6 className="text-white text-left" style={{ width: '100%' }}>
            <Link to={`/${props?.item?.teacher?.slug}/${props?.item?.slug}`} title={props?.item?.name}>
              {props?.item?.name}
            </Link>
          </h6>
          <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
            <span className="">{props?.item?.teacher?.name}</span>
          </div>
        </div>

        <div className={`d-flex ${style.progressWrapper}`}>
          <div className={style.time}>{props?.item?.completion_rate}%</div>
          <div className={` ${style.progress}`}>
            <div className={style.progressLine1} style={{ width: '100%' }}></div>
            <div className={style.progressLine} style={{ width: `${props?.item?.completion_rate}%` }}></div>
          </div>
        </div>
        <div className={`d-flex flex-column align-items-stretch ${style.btnPlay}`}>
          <div onClick={open} role="button" className="btn btn-hover  mb-3" title={props?.item?.name}>
            <i className="fa fa-play mr-1" aria-hidden="true"></i>
            Şimdi İzle
          </div>
          <Link to={`/${props?.item?.teacher?.slug}/${props?.item?.slug}`} role="button" className="btn btn-outline-light" title={props?.item?.name}>
            İncele
          </Link>
        </div>
      </div>
    </div>
  );
}
