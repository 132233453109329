import React from 'react';
import {Dropdown} from "react-bootstrap";
import { Link } from "react-router-dom";
import user1 from "../../../../../assets/images/bg/Group 3.png";
import Card from "../../../../../components/Card";
import { useApi } from '../../../../../contexts/RequestContext';
import CustomToggle from "../../../../../components/dropdowns";
import { ReactComponent as NotificationIcon } from "../../../../../assets/icons/notificationIcon.svg";
import { updateNotifications } from '../../../../../Services/Notificatio';
import moment from 'moment';
import 'moment/locale/tr';

moment().locale('tr');

const Notification = (props) => {
    const {notifications,NotRefresh} = useApi();
    const readAll = ()=>{
        updateNotifications().then(()=>{
            NotRefresh();
        }).catch((err)=>(
            console.log(err)
        ))
    }
   
    return (
        <Dropdown as="li" className="nav-item" >
        <Dropdown.Toggle
          href="#"
          as={CustomToggle}
          variant="search-toggle position-relative"
          style={{zIndex:'9999'}}
        >
          <NotificationIcon className="hover-primary" />
         {notifications?.total_notifications>0 &&  <span className="bg-danger dots"></span>}
        </Dropdown.Toggle>
        <Dropdown.Menu
        className="iq-sub-dropdown mt-3"
        align="right"
        style={{ width: "470px", borderRadius: "10px",zIndex:'999999' }}
     
      >
        <Card className="shadow-none m-0" style={{ background: "#14161A",zIndex:'9999' }}>
          <Card.Body style={{ background: "#14161A" ,zIndex:'9999'}}>
            <div
              className="d-flex justify-content-between align-items-center p-4"
              style={{ background: "#14161A",zIndex:'9999999' }}
            >
              <h6>Bildirimler</h6>
              {notifications?.total_notifications>0 &&  <span className="text-primary cursor-pointer" onClick={readAll}>Tümünü Sil</span>}
            </div>
            <div style={{maxHeight:'400px',overflow:'scroll'}}>

          
            {notifications?.data?.filter((item)=>{return item?.status===0}).map((item)=>{
                return    <Link key={item?.id}
                to={`/${item?.teacher?.slug}/${item?.slug}`}
                className="iq-sub-card"
                style={{ background: "#14161A" }}
              >
                <div className="media align-items-center">
                  <img
                    style={{ borderRadies: "50%" }}
                    src={user1}
                    className="img-fluid mr-3 avatar"
                    alt="streamit"
                  />
                  <div className="media-body">
                    <h6 className="mb-0 ">{item?.title}</h6>
                    <small className="font-size-12">
                      {" "}
                    {item?.message}
                    </small>
                    <br />
                    <small className="font-size-12"> {moment(item?.created_at).calendar()}</small>
                  </div>
                </div>
              </Link>
            })}
              </div>
         
          </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
    );
}

export default Notification;