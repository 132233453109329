import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import bgImg from "../../../assets/images/bg/r3.jpg";
import "../Signup/style.css";
import { ResetPasswordApi } from "../../../Services/User";
import {
  notify,
  notifyError,
} from "../../../components/AlartNotify/AlertNotify";

const loginSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(6, "En az 6 karakter olmalı!")
    .max(50, "En fazla 50 karakter olmalı!")
    .matches(
      "^(?=.*[a-z])(?=.*[0-9])(?=.{6,})",
      "En az bir harf ve sayı içermelidir."
    )
    .required("Zorunlu alan!"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Şifreler uyuşmuyor!")
    .required("Zorunlu alan!"),
});

const initialValues = {
  token: "",
  new_password: "",
  confirm_password: "",
};

const ResetPassword = (props) => {
  const { token } = useParams();
  let history = useHistory();

  const [password, setPassword] = useState("");
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const body = {
        token: token,
        password: values?.new_password,
        confirm_password: values?.confirm_password,
      };
      ResetPasswordApi(body)
        .then((data) => {
          notify("Şifreniz Başarılı Bir Şekilde Değiştirildi");
          history.push("/login");
        })
        .catch((err) => {
          notifyError(err?.response?.data?.message);
        });
    },
  });

  return (
    <>
      <section
        className="sign-in-page slick-bg"
        style={{ backgroundImage: `url(${bgImg})` }}>
        <Container>
          <Row
            className="justify-content-center align-items-center "
            style={{ marginTop: "10%" }}>
            <Col lg="7" md="12" className="align-self-center">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <h1 className="mb-3 h2 text-center">Yeni Şifre Oluştur</h1>
                    {/* <h6 className="mb-3 text-center">
                      Şifreni değiştirmek için; mevcut şifreni ve e-postanı
                      girip, yeni şifre oluşturabilirsin.
                    </h6> */}
                    <Form onSubmit={formik.handleSubmit} noValidate>
                      <Row>
                        <Col lg="12" className="device-margin">
                          <div className="profile-from">
                            <Form
                              className="mt-4"
                              onSubmit={formik.handleSubmit}
                              noValidate>
                              <Form.Group>
                                <Row className="ml-1">
                                  <Form.Label
                                    htmlFor="new_password"
                                    style={{ color: "white" }}>
                                    Yeni Şifreniz
                                  </Form.Label>
                                </Row>
                                <Form.Control
                                  type="password"
                                  className="form-control mb-0 rounded"
                                  {...formik.getFieldProps("new_password")}
                                  id="new_password"
                                  placeholder=""
                                  autoComplete="off"
                                  name="new_password"
                                  required
                                />
                                {formik.touched.new_password &&
                                  formik.errors.new_password && (
                                    <div className="fv-plugins-message-container">
                                      <span
                                        className="text-primary"
                                        role="alert">
                                        {formik.errors.new_password}
                                      </span>
                                    </div>
                                  )}
                              </Form.Group>
                              <Form.Group className="">
                                <Row className="ml-1">
                                  <Form.Label
                                    htmlFor="confirm_password"
                                    style={{ color: "white" }}>
                                    Yeni Şifreni Tekrar Gir
                                  </Form.Label>
                                </Row>
                                <Form.Control
                                  type="password"
                                  className="form-control mb-0 rounded"
                                  {...formik.getFieldProps("confirm_password")}
                                  id="confirm_password"
                                  placeholder=""
                                  autoComplete="off"
                                  name="confirm_password"
                                  required
                                />
                                {formik.touched.confirm_password &&
                                  formik.errors.confirm_password && (
                                    <div className="fv-plugins-message-container">
                                      <span
                                        className="text-primary"
                                        role="alert">
                                        {formik.errors.confirm_password}
                                      </span>
                                    </div>
                                  )}
                              </Form.Group>
                            </Form>
                          </div>
                        </Col>

                        <Col sm="12" className="mt-2 mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            Minumum 6 karekterli olmalıdır. En az bir harf ve
                            sayı içermelidir.
                          </div>
                        </Col>
                      </Row>

                      <Button
                        type="submit"
                        className="btn btn-hover btn-primary1 my-2 w-100">
                        Kaydet
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
