import React from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import bgImg from "../../../assets/images/bg/r3.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../Signup/style.css";
import { notify, notifyError } from "../../../components/AlartNotify/AlertNotify";
import { ForgotPassword } from "../../../Services/User";

const SignuoSchema = Yup.object().shape({
  email: Yup.string()
    .email("Lütfen E-posta adresi gir.")
    .min(3, "En az 3 karakter olmalı!")
    .max(50, "En fazla 50 karakter olmalı!")
    .required("Lütfen E-posta adresi gir."),
});

const initialValues = {
  email: "",
};
const SendCodeToMail = (props) => {
  let history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: SignuoSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      ForgotPassword(values).then((res)=>{
        notify("E-postana gelen link ile devam et!");
        history.push("/");
      }).catch((err)=>{
        notifyError(err.response.data.message)
      })
     
    },
  });

  return (
    <>
      <section
        className="sign-in-page slick-bg ws-bg-credit-card"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <Container>
          <Row
            className="justify-content-center align-items-center "
            style={{ marginTop: "10%" }}
          >
            <Col lg="7" md="12" className="align-self-center">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <h3 className="mb-3 text-center">Şifreni Sıfırla</h3>
                    <h6 className="mb-3 text-center mb-5">
                      Şifrenizi unuttuysan, E-posta bilgilerini girip, şifreni
                      değiştirebilirsin.
                    </h6>
                    <Form onSubmit={formik.handleSubmit} noValidate>
                      <Row>
                        <Col md="12" className="text-left">
                          <Form.Group>
                            <Form.Label
                              className="mb-2 text-white"
                              style={{ fontSize: "16px", fontWeight: "800" }}
                            >
                              E-posta Adresi
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0"
                              id="exampleInputEmail2"
                              {...formik.getFieldProps("email")}
                              name="email"
                              placeholder="E-posta Adresi"
                              autoComplete="off"
                              required
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.email}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Button
                        type="submit"
                        className="btn btn-hover btn-primary1 my-2 w-100"
                      >
                        Gönder
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SendCodeToMail;
