import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Row, Col, Modal } from "react-bootstrap";
import { getLessons } from "../../../Services/Videos";
import { ModalHeader } from "../../../components/ModalHeader";
import VideoItem from "./VideoItem";
import Pagination from "../../../components/UI/pagination/Pagination";

const VideoList = (props) => {
  const {
    status,
    data,
    error,
    isFetchingNextPage,

    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["Admin:Lessons"], getLessons, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
    },
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col xs="12" sm="12" md="9">
      <Row>
        <Col sm="12" className="d-flex justify-content-end mb-3 w-100">
          <div onClick={handleShow} className="btn btn-hover">
            Ekle
          </div>
        </Col>{" "}
      </Row>
      <Row>
        {status === "loading" ? (
          <p>Yükleniyor...</p>
        ) : status === "error" ? (
          <span>Hata oluştu: {error.message}</span>
        ) : (
          <>
            <Row>
              {data?.pages.map((page) => (
                <React.Fragment key={page.nextId}>
                  {page?.data?.map((item, index) => (
                    <Col xs="12" sm="6" md="4" className="mb-3" key={index}>
                      <VideoItem item={item} />
                    </Col>
                  ))}
                </React.Fragment>
              ))}
            </Row>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <Pagination
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Col>
      </Row>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: "#000" }}>
          <ModalHeader handleClose={handleClose} titleHeader={"Add Lessons"} />
        </Modal.Body>
      </Modal>
    </Col>
  );
};

export default VideoList;
