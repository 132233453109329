import React from "react";
import { Col, Row, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../../../contexts/RequestContext";
import { createNotifications } from "../../../../Services/Notificatio";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notificationSchema = Yup.object().shape({
  title: Yup.string().min(3, "En az 3 karakter olmalı!").required(),
  message: Yup.string().min(3, "En az 3 karakter olmalı!").max(100).required(),
});
const notify = (text) => {
  toast.success(text, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const SendNotification = ({ handleClose, classroomId }) => {
  const { NotRefresh } = useApi();
  const initialValues = {
    title: "",
    message: "",
    classroom_id: classroomId,
    model: "Classroom",
    type: "All Profile",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: notificationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      createNotifications(values).then((res) => {});
      NotRefresh();
      notify("Message sent successfully");
      handleClose();
    },
  });

  return (
    <>
      {" "}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="" style={{ background: "transparent" }}>
        <Row
          className="row align-items-center m-profile justify-content-center h-100"
          style={{ background: "transparent", padding: "20px" }}
        >
          <Col lg="12">
            <div className="p-5 text-left">
              <Row>
                <Col className="device-margin">
                  <Form
                    className="mt-4"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <Row>
                      <Col sm="12">
                        <Form.Group className="">
                          <Row className="ml-1 mb-2">
                            <Form.Label
                              htmlFor="title"
                              style={{ color: "white" }}
                            >
                              Title <span className="text-primary">*</span>
                            </Form.Label>
                          </Row>
                          {/* <Row> */}
                          <Form.Control
                            type="text"
                            {...formik.getFieldProps("title")}
                            name="title"
                            className="form-control mb-0"
                            id="exampleInputtitle"
                            placeholder="Enter Category Name"
                            autoComplete="off"
                            required
                            //defaultValue={teach?.name}
                          />
                          {formik.touched.title && formik.errors.title && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.title}
                              </span>
                            </div>
                          )}
                          {/* </Row> */}
                        </Form.Group>
                      </Col>
                      <Col sm="12" className="">
                        <Form.Group>
                          <Row className="ml-1 mb-2">
                            <Form.Label
                              htmlFor="message"
                              style={{ color: "white" }}
                            >
                              Message <span className="text-primary">*</span>
                            </Form.Label>
                          </Row>
                          {/* <Row> */}
                          <textarea
                            type="text"
                            {...formik.getFieldProps("message")}
                            name="message"
                            className="form-control mb-0"
                            id="exampleInputEmail1"
                            placeholder="Enter message"
                            autoComplete="off"
                            required
                            style={{ lineHeight: "25px" }}
                          />
                          {/* </Row> */}

                          {formik.touched.message && formik.errors.message && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.message}
                              </span>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      {/* <Col sm="6" className="">
                      <Form.Group>
                        <Row className="ml-1 mb-2">
                          <Form.Label
                            htmlFor="description"
                            style={{ color: "white" }}
                          >
                            Description <span className="text-primary">*</span>
                          </Form.Label>
                        </Row>
                   
                        <textarea
                          type="text"
                          {...formik.getFieldProps("description")}
                          name="description"
                          className="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="Enter description"
                          autoComplete="off"
                          required
                          style={{ lineHeight: "25px" }}
                        />
                    

                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                {formik.errors.description}
                              </span>
                            </div>
                          )}
                      </Form.Group>
                    </Col> */}
                    </Row>

                    <div className="mt-5">
                      <Row>
                        <Col sm="12">
                          <div className="d-flex justify-content-end">
                            <div onClick={handleClose} className="btn btn-link">
                              İptal
                            </div>
                            <button
                              disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                              }
                              type="submit"
                              className="btn btn-hover"
                            >
                              Send
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SendNotification;
