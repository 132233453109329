import React,{useState} from 'react'
import { Col } from "react-bootstrap";
import Dropzone from "react-dropzone";
export default function SelectDoc({title,doc,setDoc}) {
    const [showDoc, setShowDoc] = useState( doc );
    const [errors, setErrors] = useState("");

  return (
    <Col sm="6" className="mt-4">
    <div className="">
      <Dropzone
        accept={{
          "image/*": [".png", ".jpg", ".jpeg",".pdf",".doc",".docs"],
        }}
        multiple={false}
        maxFiles={1}
        onDrop={async (acceptedFiles, thumbnailRejections) => {
            setDoc(acceptedFiles[0]);

            setShowDoc(
            acceptedFiles[0]
          );
          //handleChange(acceptedFiles)
          thumbnailRejections.forEach((thumbnail) => {
            thumbnail.errors.forEach((err) => {
              if (err.code === "thumbnail-too-large") {
                setErrors(`Hata: ${err.message}`);
              }

              if (err.code === "thumbnail-invalid-type") {
                setErrors(`Hata: ${err.message}`);
              } else {
                setErrors(`Hata: ${err.message}`);
              }
            });
          });
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps({ className: "dropzone" })}
            >
              <input
                {...getInputProps()}
                accept="image/*"
                className="form-control mb-0 form-control"
                placeholder="Select Img"
              />

              <div>
                {" "}
                <i className="fa fa-image ml-3"></i>{" "}
                <span> {title}</span>
              </div>
              <p
                style={{
                  color: "red",
                  padding: 5,
                  margin: 0,
                  fontSize: 14,
                }}
              >
                {errors}
              </p>
            </div>
          </section>
        )}
      </Dropzone>
      <aside className="d-flex justify-content-center p-3">
      <div

      >
       <span>{showDoc?.name}</span>
      </div>
    </aside>
    </div>
  </Col>
  )
}
