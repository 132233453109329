import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import about from '../../../assets/images/lessons/about.png';
import playIcon from '../../../assets/icons/play.png';
import lockIcon from '../../../assets/icons/icons-lock.png';
import style from './style.module.css';
import { useAuth } from '../../../contexts/AuthContext';
import { useApi } from '../../../contexts/RequestContext';
import Swal from 'sweetalert2';
import VideoModal from '../../../components/VideoModal';
import { deleteLesson } from '../../../Services/Videos';
import ClassroomVideo from '../../Admin/Video/AddVideo';
import { notify, notifyError } from '../../../components/AlartNotify/AlertNotify';

export default function LesonsList({ item, items, index, refetch, classroom_id, classroom_slug }) {
  const history = useHistory();
  const { inLogin, isAdmin } = useAuth();
  const { videoSet } = useApi();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);
  const [more, setMore] = useState(false);
  const deleteVideo = (id) => {
    Swal.fire({
      title: 'Onayla!',
      text: 'Silmek istediğine emin misin?',
      confirmButtonColor: '#be161a',
      confirmButtonText: 'Evet, Sil',
      background: '#000',
      color: '#fff',
      showCancelButton: true,
      customClass: {
        closeButton: 'btn btn-outline-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteLesson(id)
          .then((res) => {
            notify('Başarılı  silindi!');
            refetch();
          })
          .catch((err) => {
            notifyError(err.response.data.error);
          });
      } else if (result.isDenied) {
      }
    });
  };

  const open = () => {
    if (inLogin) {
      videoSet(items);
      history.push(`/${items?.teacher?.slug}/${items?.slug}/${item?.slug}`);
    } else history.push('/login');
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className={`mt-${index === 0 ? '0' : '4'} mt-md-5`}>
        <Row className="d-flex flex-wrap align-items-center">
          <Col sm="12" md="4">
            <div
              onClick={open}
              className={`${style.wsAboutList} ${!inLogin ? style.wsPlayIslogin : ''} ${inLogin ? '' : style.wsPlayinLogin}`}
              style={{
                background: `url(${item?.thumbnail ? item?.thumbnail?.path : about})`,
                cursor: 'pointer',
              }}
            >
              <div className={`${style.wsCardImgBg}`}></div>
              <img style={{ display: 'hidden' }} src={item?.thumbnail ? item?.thumbnail?.path : about} />
              <div className={`${style.wsPlay} `}>
                <div className={style.wsPlayIcon}>
                  <img className={`${inLogin ? 'ml-2' : 'ml-0'}`} src={inLogin ? playIcon : lockIcon} />
                </div>
              </div>
              <div className={style.time}>{item?.duration}</div>
              {inLogin && (
                <>
                  {' '}
                  <div className={style.progressLine1} style={{ width: '100%' }}></div>
                  <div className={style.progressLine} style={{ width: `${item?.completion_rate}%` }}></div>
                </>
              )}
            </div>
          </Col>
          <Col sm="12" md="8" className="text-left mt-2">
            <h4 onClick={open} className="h4" style={{ cursor: 'pointer' }}>
              {index + 1}. {item?.name}
            </h4>
            {isAdmin && (
              <div className={style.adminEdit}>
                <i onClick={handleShowUpdate} className="fa fa-pencil cursor-pointer mr-3"></i>
                <i
                  onClick={() => {
                    deleteVideo(item.slug);
                  }}
                  className="fa fa-trash cursor-pointer "
                ></i>
              </div>
            )}

            <div className="d-flex flex-wrap align-items-center">
              <p className="mt-1 mt-md-3 mb-1 mb--md-3">{item?.description}</p>
            </div>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose} centered style={{ background: '#000', width: '100%' }}>
          <Modal.Body style={{ background: '#000', width: '100%' }}>
            <VideoModal handleClose={handleClose} item={item} />
          </Modal.Body>
        </Modal>
        <Modal show={showUpdate} onHide={handleCloseUpdate} centered style={{ background: '#000' }} size="lg">
          <Modal.Body style={{ background: '#000' }}>
            <ClassroomVideo
              video={item}
              update={true}
              handleClose={handleCloseUpdate}
              classroom_id={classroom_id}
              classroom_slug={classroom_slug}
              refetch={refetch}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
