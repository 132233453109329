import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ItemCW from "../../components/ContinuesWatching/ItemCW";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getClassroomsComplated } from "../../Services/Classroom";
import Pagination from "../../components/UI/pagination/Pagination";
import { useAuth } from "../../contexts/AuthContext";
export default function Completed() {
  const { profile } = useAuth();
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["AllWatched", profile?.id], getClassroomsComplated, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
    },
  });
  return (
    <div>
      <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50">
        <Container>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                <div className="w-75">
                  <h4 className="main-title channel-logo w-100 text-left">
                    Tamamlanan Eğitimler
                  </h4>
                  <p className=" text-left mt-2">
                    Takip ettiğin sınıfları görüntüleyip, listeleyebilirsin.
                  </p>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between mr-3">
                    <div to="/profile" className="btn btn-outline-light">
                      Son İzlediklerin
                    </div>
                  </div>


                </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            {status === "loading" ? (
              <p>Yükleniyor...</p>
            ) : status === "error" ? (
              <span>Hata oluştu: {error.message}</span>
            ) : (
              <>
                {data?.pages.map((page) => (
                  <React.Fragment key={page.nextId}>
                    {page?.data?.map((item) => (
                      <Col
                        key={item.id}
                        xs="12"
                        sm="4"
                        md="3"
                        className="mb-5"
                        style={{ width: "270px", minHeight: "505px" }}>
                        <ItemCW
                          vMax="270px"
                          hMax="505px"
                          img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
                          item={item}
                        />
                      </Col>
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
          </Row>
          <Pagination
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Container>
      </div>
    </div>
  );
}
