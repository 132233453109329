import axios from "axios";

const API = process.env.REACT_APP_BASE_ENDPOIND;

const classrooms = `${API}classrooms`;

export const getClassrooms = async (q) => {
  const { data } = await axios.get(`${classrooms}${q}`);
  return data;
};
export const getClassroomsQ = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${classrooms}?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const getClassroomsComplated = async ({ pageParam = 0, queryKey }) => {
  const profileId = queryKey[1];
  const { data } = await axios.get(
    `${API}activities/watched/${profileId}?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const getClassroomsWatching = async (q) => {
  const { data } = await axios.get(
    `${API}activities/watching/${q}?page[number]=0&page[size]=100`
  );
  return data;
};
export const getClassroomsAdmin = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${classrooms}/all?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const getClassroomFilter = async ({ pageParam = 0, queryKey }) => {
  let param = queryKey[1];
  if (param === "") param = "?";
  else param = "?category_id=" + param + "&";
  const { data } = await axios.get(
    `${classrooms}${param}page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const getClassroomSearch = async ({ pageParam = 0, queryKey }) => {
  let cat_id = queryKey[1];
  let tech_id = queryKey[2];
  let q = queryKey[3];
  let order = queryKey[4];
  const { data } = await axios.get(
    `${API}search?category_id=${cat_id}&teacher_id=${tech_id}&q=${q}&order=${order}&page[number]=${pageParam}&page[size]=24`
  );
  return data;
};
export const getClassroomsDetails = async ({ pageParam = 0, queryKey }) => {
  let param = queryKey[1];
  const { data } = await axios.get(`${classrooms}/${param}`);
  return data;
};
export const getClassroomsDetailss = async (queryKey) => {
  const { data } = await axios.get(`${classrooms}/${queryKey}`);
  return data;
};
export const getClassroomsPathFile = async (slug) => {
  const { data } = await axios.get(`${classrooms}/${slug}/raw_files`);
  return data;
};

export const createClassrom = (body) => {
  return axios.post(classrooms, body).then((response) => response.data);
};
export const deleteClassroom = (slug) => {
  return axios
    .delete(`${classrooms}/${slug}`)
    .then((response) => response.data);
};

export const updateClassrom = (item) => {
  const { slug, body } = item;
  return axios
    .put(`${classrooms}/${slug}`, body)
    .then((response) => response.data);
};
export const updateThumClassrom = (item) => {
  const { slug, formData } = item;
  return axios
    .post(`${classrooms}/${slug}/thumbnail`, formData)
    .then((response) => response.data);
};
export const updateCoverClassrom = (item) => {
  const { slug, formData } = item;
  return axios
    .post(`${classrooms}/${slug}/cover`, formData)
    .then((response) => response.data);
};
export const updateDocClassrom = (item) => {
  const { slug, body } = item;
  return axios
    .post(`${classrooms}/${slug}/document`, body)
    .then((response) => response.data);
};
export const updateVideoClassrom = (item) => {
  const { slug, file } = item;
  return axios
    .put(`${classrooms}/${slug}/teaser`, file)
    .then((response) => response.data);
};
export const updateBannerClassrom = (item) => {
  const { slug, formData } = item;
  return axios
    .post(`${classrooms}/${slug}/banner`, formData)
    .then((response) => response.data);
};
