import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getNotes } from '../../Services/Note';
import NoteItem from './NoteItem';
import NoteItemModal from './NoteItemModal';
import style from './style.module.css';
const NotesModal = ({ handleClose, item }) => {
  const [refresh, setRefresh] = useState(false);
  const handleChange = () => {
    setRefresh(!refresh);
  };
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getNotes('0', `?classroom_id=${item?.id}&`).then((res) => {
      setNotes(res?.data);
    });

    return () => {};
  }, [refresh, item]);
  return (
    <div>
      <NoteItem type={'modal'} handleClose1={handleClose} item={item} />
      <div className={`${style.scroll}`}>
        {notes?.length < 1 && (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '90px' }}>
            Henüz bir not eklenmedi
          </div>
        )}
        {notes?.map((note) => {
          return <NoteItemModal item={note} key={note?.id} note={note} classroomData={item} handleChange={handleChange} />;
        })}
      </div>
    </div>
  );
};

export default NotesModal;
