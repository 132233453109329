import React from "react";
import ExtraPages from "../router/extra-pages-route";

const BlankLayout = () => {
  return (
    <>
      <div className="wraper">
        <div className="content-page" id="content-page">
          <ExtraPages />
        </div>
      </div>
    </>
  );
};

export default BlankLayout;
