import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
//img


import slyte from './style.module.css'
import './style.css'

// install Swiper modules
SwiperCore.use([Navigation]);

export default function TeacherImgSlider() {
  const [lessons, setLessons] = useState([1, 1, 1, 1, 1, 1]);
  return (
    <section id="movieshow" className="iq-main-slider pt-5 pb-5">
      <div id="prev" className="swiper-button swiper-button-prev">
        <i className="ri-arrow-left-s-line"></i>
      </div>
      <div id="next" className="swiper-button swiper-button-next">
        <i className="ri-arrow-right-s-line"></i>
      </div>
      <Swiper
        slidesPerView={4}
        spaceBetween={40}
        centeredSlides={true}
        navigation={{
          prevEl: "#prev",
          nextEl: "#next",
        }}
        breakpoints={{
          320: { slidesPerView: 1.4 },
          550: { slidesPerView: 2 },
          991: { slidesPerView: 4 },
          1400: { slidesPerView: 4 },
        }}
        loop={true}
        className={`lessonlist`}
      >
        {lessons?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div>
                <div className="shows-img ">
                  {/* <img src={banner} className="w-100 img" alt="" /> */}
           
            
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}
