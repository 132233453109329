import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import bgImg from '../../../assets/images/bg/r3.jpg';
import './style.css';
import { useAuth } from '../../../contexts/AuthContext';
import { getPlans, Reg3 } from '../../../Services/AuthApi';
import iconPlus from '../../../assets/icons/IconPlus.png';
import { notifyError } from '../../../components/AlartNotify/AlertNotify';
const SelectPlan = (props) => {
  let history = useHistory();
  const queryParams = window.location.pathname.includes('mobile');
  const { regId, setSelectedPlan } = useAuth();
  const [isMonthly, setIsMonthly] = useState(true);
  const [price, setPrice] = useState([]);
  useEffect(() => {
    getPlans('Yearly')
      .then((res) => {
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);

  const onSubmit = (type) => {
    Reg3(regId?.id, { plan_id: type })
      .then((data) => {
        //notify('Başarı İle Oluşturuldu')
        if (queryParams) history.push('/mobile/set-card');
        else history.push('/set-card');
      })
      .catch((err) => {
        notifyError('Error');
      });
    //  history.push('/set-card')
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section
        className="sign-in-page slick-bg ws-bg-select-plan"
        style={{
          backgroundImage: `url(${bgImg})`,
          position: 'fixed',
          height: '100%',
          position: 'relative',
        }}
      >
        <Container>
          <Row className="justify-content-center align-items-center plan-visible" style={{ marginTop: '5%' }}>
            <Col lg="10" md="12" className="align-self-center">
              <div className="text-center ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <h6 className="mb-3 text-primary text-center">Adım 3/4</h6>
                    <h1 className="d-none d-sm-block h2 mb-3 text-center">Üyelik Planını Seç</h1>
                    <h1 className="d-block d-sm-none h3 mb-3 text-center">Üyelik Planını Seç</h1>

                    <div className="isMonthly">
                      <div
                        className={`${isMonthly && 'isMonthlyActive'}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsMonthly(true);
                        }}
                      >
                        Peşin
                      </div>
                      <div
                        className={`${!isMonthly && 'isMonthlyActive'}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsMonthly(false);
                        }}
                      >
                        Taksitli
                      </div>
                    </div>
                  </div>
                  <Row className="mt-2 pb-5 justify-content-center">
                    <Col sm="12" md="6" className="p-0 pt-4 pb-4">
                      <div className="ws-card-left text-left">
                        <div className="text-primary text-uppercase my-3">{isMonthly ? 'Peşin' : 'Taksitli'}</div>
                        <div className="text-white my-3">1 Hesap - 4 Profil</div>
                        <h4 className="my4">
                          {/* {!inLogin && (isMonthly ? monthlyPrice[0]?.price : yearPrice[0]?.price)} */}
                          {isMonthly ? price[1]?.name : price[2]?.name}
                          {/* <span style={{ fontSize: '25px', color: '#fff' }}>₺ {!isMonthly ? '/Ay' : ''}</span> */}
                        </h4>
                        <div className="my-3  d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-light">9 Kategori+20’den fazla içerik</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Yeni İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Size Özel Bonus İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">İzlerken Not Alma Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Mobil Cihazdan Podcast Dinleme Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</span>
                        </div>

                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">İstediğin Zaman İptal Etme Seçeneği</span>
                        </div>

                        <div
                          className="d-flex justify-content-center align-items-center mt-3 w-100"
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          <div
                            className="btn btn-hover w-100"
                            onClick={() => {
                              setSelectedPlan(isMonthly ? price[1] : price[2]);
                              onSubmit(isMonthly ? price[1]?.id : price[2]?.id);
                            }}
                          >
                            SATIN AL
                          </div>
                        </div>
                      </div>
                    </Col>

                    {/* <Col sm="12" md="6" className="p-0 pt-4 pb-3">
                      <div className="ws-card-left text-left">
                        <div className="text-primary text-uppercase my-3">BİREYSEL</div>
                        <div className="text-white my-3">1 Hesap - 1 Profil</div>
                        <h3 className="my4">
                          {price[0]?.price}₺
                        </h3>
                        <div className="my-3  d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-light">9 Kategori+20’den fazla içerik</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Yeni İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Size Özel Bonus İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">İzlerken Not Alma Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Mobil Cihazdan Podcast Dinleme Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</span>
                        </div>
                        {isMonthly !== 'Yearly' && (
                          <div className="my-3">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">İstediğin Zaman İptal Etme Seçeneği</span>
                          </div>
                        )}

                        <div
                          className="d-flex justify-content-center align-items-center mt-3 w-100"
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          <div
                            className="btn btn-link btn-outline-light w-100 "
                            onClick={() => {
                              setSelectedPlan(price[0]);
                              onSubmit(price[0]?.id);
                            }}
                          >
                            Seç
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    {/* <Col sm="12" md="6" className="p-0">
                      <div className="ws-card-right text-left">
                        <div className="text-primary text-uppercase my-3">AİLE</div>
                        <div className="text-white my-3 h6">1 hesap, 4 Cihaz</div>
                        <h3 className="my4">{price[1]?.price}₺</h3>
                        <div className="my-3  d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">9 Kategori+20’den fazla içerik</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Yeni İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Ay Size Özel Bonus İçerikler</span>
                        </div>
                        <div className="my-3 d-flex align-items-start d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">İzlerken Not Alma Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Mobil Cihazdan Podcast Dinleme Özelliği</span>
                        </div>
                        <div className="my-3 d-flex align-items-start">
                          <span className="mr-3">
                            <img src={iconPlus} />
                          </span>
                          <span className="text-white">Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</span>
                        </div>
                        {isMonthly !== 'Yearly' && (
                          <div className="my-3">
                            <span className="mr-3">
                              <img src={iconPlus} />
                            </span>
                            <span className="text-white">İstediğin Zaman İptal Etme Seçeneği</span>
                          </div>
                        )}
                        <div
                          className="d-flex justify-content-center align-items-center mt-3 w-100 r-mb-23"
                          data-iq-gsap="onStart"
                          data-iq-position-y="80"
                          data-iq-delay="0.8"
                        >
                          <div
                            className="btn btn-hover w-100"
                            onClick={() => {
                              setSelectedPlan(price[1]);
                              onSubmit(price[1]?.id);
                            }}
                          >
                            Seç
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SelectPlan;
