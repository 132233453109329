import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageTitleSingle from "./components/PageTitleSingle";
import userpinc from "../../assets/images/Userpic.png";
import { Link } from "react-router-dom";
import "./style.css";
import Comments from "./components/Comments";
import Blog from "./components/Blog";
import SinglePageSlider from "./components/SinglePageSlider";

const SinglePage = (props) => {
  return (
    <div>
      <PageTitleSingle />
      <Container >
        <Row className="single-container text-left p-5">
          <Col sm="2" className="position-relative">
            <div className=" d-inline-block single-avatar">
              <img
                src={userpinc}
                className="profile-pic avatar-120 rounded-circle img-fluid"
                alt="user"
              />
            </div>
            <div className="text-left w-100 mt-4">
              <h6> {"Yuliya Petrova"}</h6>
              <p
                className="mt-1"
                style={{ color: " rgba(255, 255, 255, 0.502817)" }}
              >
                {"Florida, USA"}
              </p>
            </div>
          </Col>
          <Col sm="10">
            <h3>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip.
            </h3>
            <p className="mt-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
              <br />
              <br />
              <br />
              <br />
              Section 1.10.32 of "de Finibus Bonorum et Malorum", written by
              Cicero in 45 BC "Sed ut perspiciatis unde omnis iste natus error
              sit voluptatem accusantium doloremque laudantium, totam rem
              aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
              architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
              voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
              quia consequuntur magni dolores eos qui ratione voluptatem sequi
              nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
              sit amet, consectetur, adipisci velit, sed quia non numquam eius
              modi tempora incidunt ut labore et dolore magnam aliquam quaerat
              voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
              ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
              consequatur? Quis autem vel eum iure reprehenderit qui in ea
              voluptate velit esse quam nihil molestiae consequatur, vel illum
              qui dolorem eum fugiat quo voluptas nulla pariatur?"
            </p>
          </Col>
        </Row>
      </Container>
      <SinglePageSlider/>
      <Container >
        <Row className="single-container text-left p-5">
          <Col sm="2"></Col>
          <Col sm="10">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <div className=" widget_tag_cloud mt-5">
              <div className="tagcloud">
                <ul className="wp-tag-cloud">
                  <li className="rounded-pill">
                    <Link to="#" className="tag-cloud-link rounded-pill">
                    Design
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="tag-cloud-link ">
                    Arts & Entertainment
                    </Link>
                  </li>

 
                </ul>
              </div>
            </div>

            <Comments/>
          </Col>
     
        </Row>
        <div className="text-left mt-5">

     
        <h4 style={{fontWeight: '700'}}>You might also like</h4>
        <Row className="mt-3">
            <Col xs="12" sm="6" md="4">
              <Blog />
            </Col>
            <Col xs="12" sm="6" md="4">
              <Blog />
            </Col>
            <Col xs="12" sm="6" md="4">
              <Blog />
            </Col>
          </Row>
          </div>
      </Container>
    </div>
  );
};

export default SinglePage;
