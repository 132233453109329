import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export default function NewVideoTitle({ item, news, one }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center  ws-new-title">
      {/* {news && <div className="news">Yeni</div>} */}
      <div className="text-center">
        <Link to={`/${item?.teacher?.slug}/${item?.slug}`} className="ws-card-title1">
          {item?.teacher?.logo ? (
            <img src={item?.teacher?.logo?.path} alt={`${item?.teacher?.name} logosu`} style={{ width: '70%' }} />
          ) : (
            <h3 style={{ textTransform: 'capitalize' }}>{item?.teacher?.name}</h3>
          )}
        </Link>
        <div className="ws-new-card-line"></div>
        <div style={{ textTransform: 'capitalize' }} className="d-flex justify-content-center text-center w-100 px-3 mb-1">
          {item?.name}
        </div>
        {/* <div className="d-flex justify-content-center align-item-center">
          <div className="text-white ws-ls-card-header-left">
            {item?.category?.name}
          </div>
        </div>

        <div className="ws-card-time mb-4">
          <Clock /> Toplam {item?.classroom_duration}
        </div> */}
      </div>
    </div>
  );
}
