import axios from "axios";


export default function Interceptor(){
    axios.interceptors.request.use(
        config => {
          config.headers['Authorization'] = `Bearer ${localStorage.getItem('ws-access_token') || ''}`;
              return config;
          },
          error => {
              return Promise.reject(error);
          }
      );
}