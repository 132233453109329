import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { ModalHeader } from "../../../components/ModalHeader";
import { Row, Col, Modal } from "react-bootstrap";
import { getAvatarsQ } from "../../../Services/AvatarApi";
import ItemAvatar from "./ItemAvatar";
import AddAvatar from "./AddAvatar";
import Pagination from "../../../components/UI/pagination/Pagination";

const Avatars = () => {
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(["Admin:AllAvatars"], getAvatarsQ, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
    },
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col xs="12" sm="12" md="9">
      <Row>
        <Col sm="12" className="d-flex justify-content-end mb-3 w-100">
          <div onClick={handleShow} className="btn btn-hover">
            Ekle
          </div>
        </Col>{" "}
      </Row>
      {status === "loading" ? (
        <p>Yükleniyor...</p>
      ) : status === "error" ? (
        <span>Hata oluştu: {error.message}</span>
      ) : (
        <>
          <Row>
            {data?.pages.map((page) => (
              <React.Fragment key={page.nextId}>
                {page?.data?.map((item) => (
                  <Col key={item.id} xs="12" sm="6" md="4" className="mb-5">
                    <ItemAvatar avatar={item} refetch={refetch} />
                  </Col>
                ))}
              </React.Fragment>
            ))}
          </Row>
          <Pagination
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </>
      )}
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: "#14161A" }}>
          <ModalHeader handleClose={handleClose} titleHeader={"Avatar Ekle"} />
          <AddAvatar
            avatar={null}
            handleClose={handleClose}
            update={false}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </Col>
  );
};

export default Avatars;
