import React from "react";
import userpinc from "../../../assets/images/Userpic.png";
import InputMessage from "./InputMessage";

const Comments = (props) => {
  return (
    <div className="mt-5 text-left pt-2">
      <h6 className="mb-4">32 comments</h6>
      <div className="d-flex align-items-start mb-4">
        <div className=" mr-3 ">
          <img
            src={userpinc}
            className="profile-pic avatar-50 rounded-circle img-fluid"
            alt="user"
          />
        </div>

        <div className="d-flex align-items-end">
          <div className="text-left w-100">
            <h6 className=" mb-3">
              {" "}
              {"Yuliya Petrova"} <span className="text-muted">3:32 pm</span>{" "}
            </h6>
            <p
              className="mb-0"
              style={{ color: " rgba(255, 255, 255, 0.502817)" }}
            >
              Play the hits! Some of our favorite editorial features of the last
              few years with Miguel, Kindness, Toro y Moi, Future Islands, Lee
              Fields.
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-start mb-4">
        <div className=" mr-3 ">
          <img
            src={userpinc}
            className="profile-pic avatar-50 rounded-circle img-fluid"
            alt="user"
          />
        </div>

        <div className="d-flex align-items-start">
          <div className="text-left w-100 ">
            <h6 className=" mb-3">
              Artyom Tarasov <span className="text-muted">3:32 pm</span>{" "}
            </h6>
            <p
              className="mb-0"
              style={{ color: " rgba(255, 255, 255, 0.502817)" }}
            >
              I’ve seen people drop hints that they were Bitcoin’s creator
              seemingly to try to bring in investment money
              I’ve seen people drop hints that they were Bitcoin’s creator
              seemingly to try to bring in investment money
              I’ve seen people drop hints that they were Bitcoin’s creator
              seemingly to try to bring in investment money
              I’ve seen people drop hints that they were Bitcoin’s creator
              seemingly to try to bring in investment money
            </p>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <InputMessage />
      </div>
    </div>
  );
};

export default Comments;
