import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "./components/PageTitle";
import { Link } from "react-router-dom";
import Blog from "./components/Blog";
import MostDevice from "./components/MostDevice";
import CommentsBlog from "./components/CommentsBlog";
import HomeFooterComponent from "../Home/components/HomeFooterCom";
import HomeTitileComponent from "../Home/components/HomeTitileCom";
import ArticleFooterComponent from "./components/ArticleFooterCom";

const Article = (props) => {
  return (
    <div>
      <PageTitle />
      <main id="main" className="site-main">
        <Container >
          <Row>
            <Col lg="8" sm="12" className="mb-3">
              <Row>
                <Col xs="12" sm="6">
                  <Blog />
                </Col>
                <Col xs="12" sm="6">
                  <Blog />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MostDevice />
                </Col>
              </Row>
            </Col>
            <Col lg="4" sm="12" className="text-left ws-article-left mb-3">
              <CommentsBlog />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs="12" sm="6" md="4">
              <Blog />
            </Col>
            <Col xs="12" sm="6" md="4">
              <Blog />
            </Col>
            <Col xs="12" sm="6" md="4">
              <Blog />
            </Col>
          </Row>
        
        </Container>
        <div
          className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
          
        >
          <Container >
            <Row className="align-items-center justify-content-center">
              <Col sm="12" md="6" >
                <nav className="text-center iq-breadcrumb-two ">
                  <h2 className="title text-primary">The Ultimate List of New Crazy Gadgets</h2>
                  <p
                    className="mt-4 text-white "
                    style={{ margin: "auto" }}
                  >
                 The best new charging phone cases you’ll actually want
                  </p>
                  <Link to='contact-us' className="btn btn-link btn-outline-light mt-4">
                     İletişim
                </Link>
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
        
       <ArticleFooterComponent type={'classes'}/>
      </main>
     
    </div>
  );
};

export default Article;
