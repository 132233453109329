import React, { useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import VideoItem1 from '../../../components/VideoComponents/VideoItem1';
import { Row, Col, Modal } from 'react-bootstrap';
import { getClassroomsAdmin, getClassroomsQ } from '../../../Services/Classroom';
import { ModalHeader } from '../../../components/ModalHeader';
import ClassroomAdd from './AddClassroom';
import Pagination from '../../../components/UI/pagination/Pagination';

export default function ClassroomAdmin() {
  const { status, data, error, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(['Admin:AllClasses'], getClassroomsAdmin, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page) return lastPage?.meta?.current_page + 1;
    },
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col xs="12" sm="12" md="10">
      <Row>
        <Col sm="12" className="d-flex justify-content-end mb-3 w-100">
          <div onClick={handleShow} className="btn btn-hover">
            Ekle
          </div>
        </Col>{' '}
      </Row>
      {status === 'loading' ? (
        <p>Yükleniyor...</p>
      ) : status === 'error' ? (
        <span>Hata oluştu: {error.message}</span>
      ) : (
        <>
          <Row>
            {data?.pages.map((page) => (
              <React.Fragment key={page.nextId}>
                {page?.data?.map((item) => (
                  <Col key={item.id} xs="12" sm="6" md="4" className="mb-5 h-auto">
                    <VideoItem1 vMax="330px" hMax="498px" item={item} minH="498px" />
                  </Col>
                ))}
              </React.Fragment>
            ))}
          </Row>
          <Pagination hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </>
      )}
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#14161A' }}>
          <ModalHeader handleClose={handleClose} titleHeader={'Eğitim Ekle'} />
          <ClassroomAdd classroom={null} handleClose={handleClose} update={false} />
        </Modal.Body>
      </Modal>
    </Col>
  );
}
