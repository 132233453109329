import React, { useState, useEffect, useCallback, useRef } from "react";
import PageTitle from "../../components/PageTitle";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Container, Row, Col } from "react-bootstrap";
import VideoItem1 from "../../components/VideoComponents/VideoItem1";
import { getClassroomSearch } from "../../Services/Classroom";
import HomeFooterComponent from "../Home/components/HomeFooterCom";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../contexts/RequestContext";
import style from "./style.module.css";
import { Label } from "reactstrap";
import { getTeachersForfilters } from "../../Services/TeachersApi";
import Pagination from "../../components/UI/pagination/Pagination";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

export default function Search() {
  const { inLogin } = useAuth();
  const [querySearch, setQuerySearch] = useState(["", "", "", ""]);
  const { categories } = useApi();
  const [teacherId, setTeacherId] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [catId, setCatId] = useState("");
  const [order, setOrder] = useState("");
  const [query, setQuery] = useState("");
  const [price, setPrice] = useState([{ price: "59.9" }, { price: "99" }]);

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [
      "AllClassesSearch",
      querySearch[0],
      querySearch[1],
      querySearch[2],
      querySearch[3],
    ],
    getClassroomSearch,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
          return lastPage?.meta?.current_page + 1;
      },
    }
  );
  useEffect(() => {
    getTeachersForfilters().then((res) => {
      setTeachers(res?.data);
    });
  }, []);

  const useDebounce = (callback, delay) => {
    const timer = useRef(null);

    const debounceCallback = useCallback(
      (...args) => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
          callback(...args);
        }, delay);
      },
      [callback, delay]
    );

    return debounceCallback;
  };

  const changeHandler = useDebounce(async (e) => {
    setQuerySearch([catId, teacherId, e.target.value, order]);
    refetch(true);
  }, 500);

  return (
    <div>
      <Row>
        <Col sm="12">
          <PageTitle
            title={"Ara"}
            subTitile={"En İyilerin Okuluna Hoşgeldin"}
          />
        </Col>
      </Row>
      <Container>
        <div className={`${style.container} flex-wrap  bd-highlight`}>
          {/* <div
            className={`d-flex flex-wrap flex-md-nowrap  ${style?.wSelectBox}  bd-highlight`}>
            <div className="text-left pr-3 pl-3 w-100">
              <Label className={`${style.label}`}>Kategoriler</Label>
              <select
                className={`${style.myClass}`}
                onChange={(e) => setCatId(e.target.value)}>
                <option
                  style={{ background: "#000", color: "#fff" }}
                  value={""}>
                  Tüm Kategoriler
                </option>
                {categories?.map((item) => {
                  return (
                    <option
                      style={{ background: "#000", color: "#fff" }}
                      key={item.id}
                      value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={`${style.line}`}></div>
            <div className="text-left pr-3  pl-3 w-100">
              <Label className={`${style.label}`}>Eğitmenler</Label>
              <select
                className={`${style.myClass} w-100`}
                onChange={(e) => setTeacherId(e.target.value)}>
                <option
                  style={{ background: "#000", color: "#fff" }}
                  value={""}>
                  Tüm Eğitmenler
                </option>
                {teachers?.map((item, index) => {
                  return (
                    <option
                      style={{ background: "#000", color: "#fff" }}
                      key={item.id}
                      value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={`${style.line}`}></div>
            <div className="text-left pr-3  pl-3 w-100 w-md-75">
              <Label className={`${style.label}`}>Sırala</Label>
              <select
                className={`${style.myClass}`}
                onChange={(e) => setOrder(e.target.value)}>
                <option
                  style={{ background: "#000", color: "#fff" }}
                  value="mewest">
                  Yeniler
                </option>
                <option
                  style={{ background: "#000", color: "#fff" }}
                  value="oldest">
                  Eskiler
                </option>
              </select>
            </div>
            <div className={`${style.line}`}></div>
          </div> */}
          <div className="d-flex justify-content-start align-items-center  flex-grow-1 bd-highlight px-2 my-3 my-md-0">
            <input
              className={`form-control ${style.inputSearch} w-100 flex-grow-1 bd-highlight pl-4`}
              placeholder="Aranacak kelime..."
              onChange={changeHandler}
            />
          </div>
          {/* <div
            className="d-flex align-items-center r-mb-23 my-3"
            data-iq-gsap="onStart"
            data-iq-position-y="80"
            data-iq-delay="0.8">
            <button onClick={onSubmit} className="btn btn-hover mr-4">
              Ara
            </button>
          </div> */}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="h3">Sonuçlar</h3>
          <span className="text-primary">
            {data?.pages[0]?.meta?.total} Sonuç Bulundu
          </span>
        </div>
        <Row className="mt-5">
          <Col xs="12" sm="12" md="12">
            {status === "loading" ? (
              <p>Yükleniyor...</p>
            ) : status === "error" ? (
              <span>Hata oluştu: {error.message}</span>
            ) : (
              <>
                {data?.pages[0]?.meta?.total > 0 ? (
                  <Row>
                    {data?.pages.map((page) => (
                      <React.Fragment key={page.nextId}>
                        {page?.data?.map((item) => (
                          <Col
                            key={item.id}
                            xs="6"
                            sm="6"
                            md="4"
                            lg="3"
                            className="mb-5">
                            <VideoItem1
                              vMax="330px"
                              hMax="498px"
                              img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
                              item={item}
                              minH="450px"
                            />
                          </Col>
                        ))}
                      </React.Fragment>
                    ))}
                  </Row>
                ) : (
                  <Row className="d-flex justify-content-center my-5">
                    <Col xs="12" sm="8" md="6">
                      <div
                        className={`w-100 d-flex flex-column align-items-center justify-content-center`}>
                        <h5 className="mt-5 mb-2">
                          Aramanız herhangi bir sonuç getirmedi.
                        </h5>
                        <div className="text-left mt-4">
                          Aradığını bulamadın mı?
                          <ul className="px-4">
                            <li>Bir eğitmen veya bir eğitimi mi arıyorsun?</li>
                            <li>
                              {" "}
                              Eğitmen adı ya da konu bazlı anahtar bir kelimeyle
                              tekrar dene.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

                <Pagination
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>

      {!inLogin && (
        <div className="mb-5 mt-5">
          <Container>
            <HomeFooterComponent price={price} type={"classes"} />
          </Container>
        </div>
      )}
    </div>
  );
}
