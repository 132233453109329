import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './style.css';
import ClassroomDropdown from '../UI/Dropdowns/ClassroomDropdown';
import { useApi } from '../../contexts/RequestContext';

export default function LiderSlide({ item }) {
  const { setCat } = useApi();
  const history = useHistory();
  const openCategory = () => {
    setCat(item?.category);
    history.push(`/${item?.category?.slug}`);
  };

  return (
    <div className="ws-ls-card  position-relative" style={{ background: `url(${item?.banner?.path})` }}>
      <div className="ws-ls-card-header d-flex justify-content-between position-absolute">
        <div onClick={openCategory} className="text-white ws-ls-card-header-left">
          {item?.category?.name}
        </div>
      </div>
      <ClassroomDropdown item={item} mutation={''} />

      <div className="img-box">{/* <img src={item?.banner?.path} className="img-fluid" alt="" /> */}</div>
      <div className="block-description d-flex align-items-start justify-content-end">
        <h6 className="text-white text-left">
          <Link to={`/${item?.teacher?.slug}/${item?.slug}`} title={item?.name}>
            {item?.name}
          </Link>
        </h6>
        <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
          <span className="">{item?.teacher?.name}</span>
        </div>
      </div>
      <div className="ws-card-img-bg"></div>
    </div>
  );
}
