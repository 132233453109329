import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import style from './style.module.css';
import sendIcon from '../../../assets/icons/icons-more-vertical.png';
import SendMail from '../../../components/SendMail';
import { useAuth } from '../../../contexts/AuthContext';

export default function DownloadGuid({ title, desc, doc }) {
  const { inLogin } = useAuth();
  const Download = () => {
    window.open(doc?.path);
  };
  return (
    <Container>
      <Row className={`${style.bg} ${style.bgCard} text-left`}>
        <Col md="6" sm="12">
          <h4 className="mb-4 mt-3 mt-md-0">{title}</h4>
          <p style={{ fontSize: '14px' }}>{desc}</p>
        </Col>
        <Col md="6" sm="12" className={`${style.bgCard} d-flex justify-content-end`}>
          {!inLogin && <SendMail bg={style.bg} />}
          {inLogin && (
            <div onClick={Download} className="btn btn-hover">
              Eğitim Notlarını İndir
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
