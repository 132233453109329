import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import icon from "../../assets/icons/icons-award.png";
import { Link } from "react-router-dom";

const AccountSettings = (props) => {
  return (
    <div>
      <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50">
        <Container>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                <div className="w-75">
                  <h4 className="main-title channel-logo w-100 text-left">
                    Account Settings
                  </h4>
                  <p className="w-100 text-left mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud
                  </p>
                </div>

                <div
                  className=" mt-4 ws-category-list-top text-left"
                  style={{
                    width: "auto",
                    height: "auto",
                    // margin: "40px 0 40px 158px",
                    padding: "15px",
                    borderRadius: "16px",
                    backdropFilter: "blur(6.4px)",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="m-2 ">
                      <img src={icon} />
                    </div>

                    <div>
                      <span className="fw-bolder"> Go Platinum Plans</span>{" "}
                      <br />
                      <span className="text-gray">
                        {" "}
                        Add feautures just for $5/month
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <div
                className="sign-user_card "
                style={{ backgroundColor: "#14161A", borderRadius: "10px" }}
              >
                <Row>
                  <Col lg="12" className="device-margin p-5">
                    <div className="w-75">
                      <h4 className="main-title w-100 text-left">
                        Account Settings
                      </h4>
                      <p className="w-100 text-left mt-2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor.
                      </p>
                    </div>
                    <div className="profile-from">
                      <Form className="mt-4" action="#">
                        <Row>
                          <Col sm="12">
                          <div className="custom-control custom-checkbox d-inline-block">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck"
                            >
                              I have read and approve the {' '} 
                              <Link to='/uyelik-sozlesmesi' className="text-primary">
                               KVKK Clarification Text and Confidentiality Agreement
                              </Link>{" "}
                              and the Online Membership Agreement
                            </label>
                          </div>
                          </Col>

                          <Col sm="6" className="">
                            <Form.Group>
                              <Row className="ml-1">
                                <Form.Label
                                  htmlFor="email"
                                  style={{ color: "white" }}
                                >
                                  E-posta Adresi{" "}
                                  <span className="text-primary">*</span>
                                </Form.Label>
                              </Row>
                              {/* <Row> */}
                              <Form.Control
                                type="email"
                                className="form-control mb-0 rounded"
                                id="exampleInputl2"
                                placeholder="Enter Your Email"
                                autoComplete="off"
                                name="email"
                                required
                              />
                              {/* </Row> */}

                              <p className=" d-flex justify-content-end text-primary fs-8 ">
                                Email not verified.{" "}
                                <Link
                                  to="#"
                                  className="text-primary"
                                  style={{ textDecoration: "underline" }}
                                >
                                  Verify Now{" "}
                                </Link>
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <Form.Group>
                              <Row className="ml-1">
                                <Form.Label style={{ color: "white" }}>
                                  Telefon numarası{" "}
                                  <span className="text-primary">*</span>
                                </Form.Label>
                              </Row>

                              <Row>
                                <Col lg="4">
                                  <select
                                    className="form-control mb-0 rounded"
                                    data-control="select2"
                                    data-hide-search="true"
                                    data-placeholder="+90"
                                    name="country_code"
                                  >
                                    <option
                                      style={{
                                        background: "#000",
                                        color: "#fff",
                                      }}
                                    >
                                      +90
                                    </option>
                                  </select>
                                  {/* {formik.touched.code && formik.errors.code && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span className="text-primary" role="alert">
                                    {formik.errors.code}
                                  </span>
                                </div>
                              </div>
                            )} */}
                                </Col>
                                <Col lg="8">
                                  <Form.Group>
                                    <Form.Control
                                      type="text"
                                      className="form-control mb-0 rounded"
                                      name="phone_number"
                                      id="phone_number"
                                      placeholder="552555555"
                                      autoComplete="off"
                                      required
                                    />
                                    <p className=" d-flex justify-content-end text-primary  ">
                                      Phone not verified.{" "}
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        style={{ textDecoration: "underline" }}
                                      >
                                        Verify Now{" "}
                                      </Link>
                                    </p>
                                  </Form.Group>
                                  {/* {formik.touched.number && formik.errors.number && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span className="text-primary" role="alert">
                                  {formik.errors.number}
                                </span>
                              </div>
                            </div>
                          )} */}
                                </Col>
                              </Row>
                              {/* <Select
                              className=" "
                              placeholder="+90"
                              value={data2.filter((obj) =>
                                selectedValue.includes(obj.value)
                              )} // set selected values
                              {...formik.getFieldProps("code")}
                              name="code"
                              options={data2} // set list of the data
                              isClearable
                            /> */}
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group>
                              <Row className="ml-1">
                                <Form.Label
                                  htmlFor="location"
                                  style={{ color: "white" }}
                                >
                                  Location
                                </Form.Label>
                              </Row>
                              <Form.Control
                                type="text"
                                className="form-control mb-0 rounded"
                                id="location"
                                placeholder="Enter Your Location"
                                autoComplete="off"
                                name="location"
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            <Form.Group className="mb-3">
                              <Row className="ml-1">
                                <Form.Label style={{ color: "white" }}>
                                  Biyography
                                </Form.Label>
                              </Row>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="border-dark"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AccountSettings;
