import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Modal } from 'react-bootstrap';
import closeIcon from '../../assets/icons/x.png';
import './style.css';
import { useAuth } from '../../contexts/AuthContext';
import { ReactComponent as Liked } from '../../assets/icons/likeIcon1.svg';
import { ReactComponent as Docs } from '../../assets/icons/documentIcon.svg';
import iconSetting from '../../assets/icons/icons-settings.png';
import iconBiyo from '../../assets/icons/icons-life-buoy.png';
import iconCreditCard from '../../assets/icons/icons-credit-card.png';
import EditProfile from '../../Pages/CreateProfile/EditProfile';

const RightMenu = () => {
  const { openRightMenu, profile, user, logout } = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="menu-page-right">
      <div className="menu-page-right-header">
        <img onClick={openRightMenu} width="30" src={closeIcon} />
      </div>
      <div className="menu-page-body mt-3">
        <Row>
          <Col xs="3" className=" text-xs-start text-md-right">
            <div className=" d-inline-block ">
              {profile ? (
                <img src={profile?.avatar?.path} className="profile-pic avatar-50 rounded img-fluid" alt="user" />
              ) : (
                <div
                  className="profile-pic avatar-50 rounded-circle text-white d-flex justify-content-center align-items-center"
                  style={{ fontSize: '23px', background: '#BE161A' }}
                >
                  {user?.email?.toUpperCase()?.slice(0, 1)}
                  {user?.email?.toUpperCase()?.slice(1, 2)}
                </div>
              )}
            </div>
          </Col>
          <Col xs="9" className="d-flex align-items-center " style={{ overflow: 'hidden' }}>
            <div className="text-left w-100">
              <h6> {profile ? profile?.name : `${user?.name}`}</h6>
              <p className="mt-1 text-ellipsis" style={{ color: ' rgba(255, 255, 255, 0.502817)' }}>
                {' '}
                {user?.email}
              </p>
            </div>
          </Col>
        </Row>
        <div>
          <Link to="/who-is-watch" onClick={openRightMenu} className="iq-sub-card setting-dropdown mt-2">
            <div className="media align-items-center ml-1 mt-2 mb-0">
              <div className="right-icon">
                <i className="ri-file-user-line text-light"></i>
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 ">Profil{user?.plan_type !== 'Individual' && 'i Değiştir'} </h6>
              </div>
            </div>
          </Link>
          {profile?.is_main ? (
            <Link to="/profile" onClick={openRightMenu} className="iq-sub-card setting-dropdown">
              <div className="media align-items-center">
                <div className="right-icon">
                  <img width={'20px'} src={iconSetting} alt={'settings'} />
                </div>
                <div className="media-body ml-3">
                  <h6 className="my-0 ">Hesap Ayarları</h6>
                </div>
              </div>
            </Link>
          ) : (
            ''
          )}

          <Link to="/my-progress" onClick={openRightMenu} className="iq-sub-card setting-dropdown">
            <div className="media align-items-center">
              <div className="right-icon">
                {/* <img width={'20px'} src={iconCreditCard} alt={'credit-card'} /> */}
                <Docs fill="#fff" />
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 "> Aktivitelerim</h6>
              </div>
            </div>
          </Link>

          <Link to="/following-teachers" onClick={openRightMenu} className="iq-sub-card setting-dropdown">
            <div className="media align-items-center">
              <div className="right-icon">
                {/* <img width={'20px'} src={iconCreditCard} alt={'credit-card'} /> */}
                <Liked className="text-white" fill="#fff" />
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 ">Favorilerim</h6>
              </div>
            </div>
          </Link>

          <Link to="/uyelik-planlari" onClick={openRightMenu} className="iq-sub-card setting-dropdown">
            <div className="media align-items-center">
              <div className="right-icon">
                <img width={'20px'} src={iconCreditCard} alt={'credit-card'} />
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 ">Planlarım</h6>
              </div>
            </div>
          </Link>
          <Link to="/sikca-sorulan-sorular" onClick={openRightMenu} className="iq-sub-card setting-dropdown">
            <div className="media align-items-center">
              <div className="right-icon">
                <img width={'20px'} src={iconBiyo} alt={'biyo'} />
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 ">Yardım Destek</h6>
              </div>
            </div>
          </Link>
          {/* <Link to="/login" className="iq-sub-card setting-dropdown">
                <div className="media align-items-center" onClick={logout}>
                  <div className="right-icon">
                    <img width={"20px"} src={iconLoguot} />
                  </div>
                  <div className="media-body ml-3">
                    <h6 className="my-0 ">Çıkış Yap</h6>
                  </div>
                </div>
              </Link> */}
          <div className="w-100 text-center">
            <div
              className="btn btn-hover mt-3 w-100 mb-4"
              style={{ borderRadius: '6px' }}
              onClick={() => {
                logout();
                openRightMenu();
              }}
            >
              Çıkış Yap
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Body style={{ background: '#000' }}>
          <EditProfile profile={profile} handleClose={handleClose} isSetting={true} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RightMenu;
