import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isMobile } from '../../utils/isMobil';
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Geçerli bir e-posta olmalı!')
    .min(3, 'E-post en az 3 karakter olmalı!')
    .max(50, 'En fazla 50 karakter olmalı!')
    .required('Lütfen E-posta adresi gir.'),
});
const initialValues = {
  email: '',
};
export default function SendMail(props) {
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      window.location.href = '../sikca-sorulan-sorular';
    },
  });
  return (
    <div id="search-2" style={{ maxWidth: isMobile() ? '400px' : '300px' }} className={`widget widget_search mb-5 d-none d-md-block p-0 ${props.bg}`}>
      <form className="search-form" onSubmit={formik.handleSubmit} noValidate>
        <label>
          <span className="input-group screen-reader-text">Mail Adresi</span>
        </label>
        <input
          type="email"
          {...formik.getFieldProps('email')}
          name="email"
          className="w-100"
          style={{ borderRadius: '10px' }}
          placeholder="E-posta Adresi"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="text-primary" role="alert">
                {formik.errors.email}
              </span>
            </div>
          </div>
        )}
        <button type="submit" className="search-submit">
          <i className="fa fa-paper-plane"></i>
        </button>
      </form>
    </div>
  );
}
