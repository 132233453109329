import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import icon from '../../../assets/icons/icons-award.png';
import { useApi } from '../../../contexts/RequestContext';
import { isMobile } from '../../../utils/isMobil';

export default function Categorieslist() {
  const { categories, setCat, selectCategory } = useApi();
  const handleClick = (e) => {
    setCat('kategoriler');
  };

  return (
    <div id="categories-2" className="widget widget_categories text-left p-1 p-md-3">
      <Link className="ws-category-list-top cursor-pointer" to={`/kategoriler`} onClick={handleClick}>
        <span className="ml-2">Tüm Kategoriler</span>
      </Link>
      <div className="mt-3">
        <h5 className="widget-title"></h5>
        <ul>
          {categories?.map((item, index) => {
            return (
              <li
                onClick={(e) => {
                  e.preventDefault();
                  setCat(item);
                }}
                key={index}
                className={`${isMobile() && 'mb-0 pb-0'} `}
              >
                {/* <div      style={{ cursor: "pointer" }}
                      className={`${
                        selectCategory?.id === item?.id && "text-primary"
                      } btn-link`}>{item?.name}</div> */}
                <Link
                  className={`${selectCategory?.id === item?.id && 'text-primary'} ${
                    isMobile() ? 'wsAnswerCardImg' : 'btn-link'
                  }   text-decoration-none`}
                  style={{ textDecorationLine: 'none' }}
                  to={`/${item?.slug}`}
                >
                  {item?.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
