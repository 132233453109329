import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import icon from '../../../assets/icons/icons-award.png'
import { useApi } from '../../../contexts/RequestContext';

export default function Categorieslist() {
    const [topCategories,setTopCategories] = useState(['Yakında Gelecekler','Just Added','Trending','Most Popular']);
    const { categories,setCatTeacher,selectCategoryTeacher } = useApi();


    return (
        <div id="categories-2" className="widget widget_categories">
            <div className='ws-category-list-top' onClick={()=>{setCatTeacher('')}}>
                <img src={icon} /> <span className='ml-2'>Tüm Eğitmenler</span>
            </div>
            <div className='mt-3 text-left'>
                {/* <ul>
                    {topCategories?.map((item)=>{
                        return    <li><Link to="#">{item}</Link></li>
                    })}
                 
                 
                </ul> */}
                <h5 className="widget-title"></h5>
                <ul>
                {categories?.map((item,index)=>{
                      return (
                        <li onClick={()=>{setCatTeacher(item)}} key={index}>
                          <div      style={{ cursor: "pointer" }}
                                className={`${
                                    selectCategoryTeacher?.id === item?.id && "text-primary"
                                } btn-link`}>{item?.name}</div>
                        </li>
                      );
                    })}
                </ul>
            </div>

        </div>
    )
}
