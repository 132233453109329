import React, { useEffect, useState } from 'react';
import Categorieslist from './components/Categorieslist';
import { Container, Row, Col } from 'react-bootstrap';
import { useInfiniteQuery } from '@tanstack/react-query';

import HomeFooterComponent from '../Home/components/HomeFooterCom';
import PageTitile from './components/PageTitile';
import TeacherItem from '../../components/TeacherItem';

import { getTeachersFilter } from '../../Services/TeachersApi';
import { useAuth } from '../../contexts/AuthContext';
import { useApi } from '../../contexts/RequestContext';
import Pagination from '../../components/UI/pagination/Pagination';
import { getPlans } from '../../Services/AuthApi';

export default function Teachers() {
  const { inLogin } = useAuth();

  const { selectCategoryTeacher } = useApi();
  const [price, setPrice] = useState([{ price: '59.9' }, { price: '99' }]);

  const { status, data, error, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['AllTeachers', selectCategoryTeacher ? selectCategoryTeacher?.id : ''],
    getTeachersFilter,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.meta?.current_page < lastPage?.meta?.last_page) return lastPage?.meta?.current_page + 1;
      },
    }
  );

  // useEffect(() => {
  //   if (data) setTeachers(data.data);
  //   return () => {};
  // }, [data]);
  useEffect(() => {
    getPlans('Monthly').then((res) => {
      setPrice(res.data);
    });
    return () => {};
  }, []);

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <div>
      <Row>
        <Col sm="12">
          <PageTitile />
        </Col>
      </Row>
      <Container>
        <Row className="mt-5">
          <Col md="3">
            <Categorieslist />{' '}
          </Col>
          <Col>
            <Row>
              {status === 'loading' ? (
                <p>Yükleniyor...</p>
              ) : status === 'error' ? (
                <span>Hata oluştu: {error.message}</span>
              ) : (
                <>
                  <Row className="px-2 w-100">
                    {data?.pages?.map((page) => (
                      <React.Fragment key={page.nextId}>
                        {page?.data?.map((item, index) => (
                          <Col key={index} xs="6" sm="6" md="4" className="">
                            <TeacherItem teacher={item} />
                          </Col>
                        ))}
                      </React.Fragment>
                    ))}
                  </Row>
                </>
              )}
            </Row>
            <Row>
              <Col>
                <Pagination hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {!inLogin && (
        <div className="mb-5 mt-5">
          <Container>
            <HomeFooterComponent price={price} />
          </Container>
        </div>
      )}
    </div>
  );
}
