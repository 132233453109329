import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

import TeachersList from "./components/TeachersList";

export default function FollowingTeachers() {
  const [type, setType] = useState("teachers");

  return (
    <div>
      <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50">
        <Container>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                <div className="w-75">
                  <h4 className="main-title channel-logo w-100 text-left">
                    Takip Edilen Eğitmenler
                  </h4>
                  <p className="w-100 text-left mt-2">
                    Sevdiğin, beğendiğin, izlemek istediğin
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  {/* <div className="d-flex align-items-center justify-content-between mr-3">
                    <div to="/profile" className="btn btn-outline-light">
                    Son İzlediklerin
                  </div>
                    <select
                      className="mt-2"
                      data-control="select"
                      data-hide-search="true"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      name="type_name"
                    >
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"teachers"}
                      >
                        Teachers
                      </option>
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"classrooms"}
                      >
                        Classrooms
                      </option>
                    </select>
                  </div> */}

                  {/* <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link> */}
                </div>
              </div>
            </Col>
          </Row>

          <TeachersList type={"teachers"} />

          <Row className="mt-5 w-100">
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                <div className="w-75">
                  <h4 className="main-title channel-logo w-100 text-left">
                    Takip Edilen Eğitimler
                  </h4>
                  <p className="w-100 text-left mt-2">
                    Takip ettiğin dersleri görüntüleyebilir ve
                    listeleyebilirsin.
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  {/* <div className="d-flex align-items-center justify-content-between mr-3">
                    <div to="/profile" className="btn btn-outline-light">
                    Son İzlediklerin
                  </div>
                    <select
                      className="mt-2"
                      data-control="select"
                      data-hide-search="true"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      name="type_name"
                    >
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"teachers"}
                      >
                        Teachers
                      </option>
                      <option
                        style={{ background: "#000", color: "#fff" }}
                        value={"classrooms"}
                      >
                        Classrooms
                      </option>
                    </select>
                  </div> */}

                  {/* <Link className="iq-view-all" to="/movie-category">
                      Tümünü Gör
                    </Link> */}
                </div>
              </div>
            </Col>
          </Row>
          <TeachersList type={"classrooms"} />
        </Container>
      </div>
    </div>
  );
}
