import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container, Form } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Change } from '../../../Services/User';
import { notify, notifyError } from '../../../components/AlartNotify/AlertNotify';

const loginSchema = Yup.object().shape({
  current_password: Yup.string().min(6, 'En az 6 karakter olmalı!').max(50, 'En fazla 50 karakter olmalı!').required('Zorunlu alan!'),
  new_password: Yup.string()
    .min(6, 'En az 6 karakter olmalı!')
    .max(50, 'En fazla 50 karakter olmalı!')
    .matches('^(?=.*[a-z])(?=.*[0-9])(?=.{6,})', 'En az bir harf ve sayı içermelidir.')
    .required('Zorunlu alan!'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Şifreler uyuşmuyor!')
    .required('Zorunlu alan!'),
});

const initialValues = {
  current_password: '',
  new_password: '',
  confirm_new_password: '',
};

const ChangePassword = () => {
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      Change(values)
        .then((data) => {
          notify('Şifreniz Başarılı Bir Şekilde Değiştirildi');
        })
        .catch((err) => {
          notifyError(err?.response?.data?.message);
        });
    },
  });
  return (
    <>
      <Container>
        <Row className="row align-items-center justify-content-center h-100">
          <Col lg="12">
            <div className="sign-user_card " style={{ backgroundColor: '#14161A', borderRadius: '10px' }}>
              <Row>
                <Col lg="12 ">
                  <h5>Şifre Değiştir</h5>
                  <p>Şifreni değiştirmek için; mevcut şifreni girip, yeni şifre oluşturabilirsin.</p>
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="device-margin">
                  <div className="profile-from">
                    <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate>
                      <Row>
                        <Col lg="6">
                          <Form.Group className="">
                            <Row className="ml-1">
                              <Form.Label htmlFor="current_password" style={{ color: 'white' }}>
                                Mevcut Şifreniz
                              </Form.Label>
                            </Row>
                            <Form.Control
                              type="password"
                              className="form-control mb-0 rounded"
                              {...formik.getFieldProps('current_password')}
                              id="current_password"
                              placeholder=""
                              autoComplete="off"
                              name="current_password"
                              required
                            />
                            {formik.touched.current_password && formik.errors.current_password && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.current_password}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <Form.Group>
                            <Row className="ml-1">
                              <Form.Label htmlFor="new_password" style={{ color: 'white' }}>
                                Yeni Şifreniz
                              </Form.Label>
                            </Row>
                            <Form.Control
                              type="password"
                              className="form-control mb-0 rounded"
                              {...formik.getFieldProps('new_password')}
                              id="new_password"
                              placeholder=""
                              autoComplete="off"
                              name="new_password"
                              required
                            />
                            {formik.touched.new_password && formik.errors.new_password && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.new_password}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg="6">
                          <Form.Group className="">
                            <Row className="ml-1">
                              <Form.Label htmlFor="confirm_new_password" style={{ color: 'white' }}>
                                Yeni Şifreni Tekrar Gir
                              </Form.Label>
                            </Row>
                            <Form.Control
                              type="password"
                              className="form-control mb-0 rounded"
                              {...formik.getFieldProps('confirm_new_password')}
                              id="confirm_new_password"
                              placeholder=""
                              autoComplete="off"
                              name="confirm_new_password"
                              required
                            />
                            {formik.touched.confirm_new_password && formik.errors.confirm_new_password && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.confirm_new_password}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          {' '}
                          <div className="d-flex justify-content-end">
                            <Link to={'/'} className="btn btn-link">
                              İptal
                            </Link>
                            <button type="submit" className="btn btn-hover">
                              Güncelle
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangePassword;
