import axios from 'axios';





const headers = {
    "Content-Type": "application/json",
  };
const API = process.env.REACT_APP_BASE_ENDPOIND;
const countries = `${API}countries`

export const getCountries = async () =>{
    return await axios.get(countries,{headers});
} 