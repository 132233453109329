import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import { gsap } from "gsap";
import '../style.css'
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import imgBg from '../../../assets/images/Homepage/lessonsdetail.png'
import { useAuth } from '../../../contexts/AuthContext';



SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);



const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: 0.4,
            rotate: 0,
        };
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

            const setOption = {
                opacity: option.opacity,
                scale: option.scale,
                x: option.position.x,
                y: option.position.y,
                ease: option.ease,
                rotate: option.rotate,
                duration: option.duration,
                delay: option.delay,
            };

            return setOption;
        } else {
            return { opacity: 0 };
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem);

        gsap.from(elem, setOption);
    },

    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem);

        gsap.to(elem, setOption);
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem);

        const setEndOption = gsapAnimate.getData(elem);

        setEndOption.opacity = 1;

        setEndOption.x = 0;

        setEndOption.y = 0;

        setEndOption.rotate = 0;

        setEndOption.scale = 1;

        gsap.fromTo(elem, setOption, setEndOption);
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr);
        }
        return Number(defaultVal);
    },
};



const PageTitle = () => {
    const {inLogin} = useAuth()
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [toggler1, setToggler1] = useState(false);

    const animationInit = () => {
        if (
            document.querySelector(".swiper-container .swiper-slide-active") !== null
        ) {
            const gsapElem = document
                .querySelector(".swiper-container .swiper-slide-active")
                .querySelectorAll('[data-iq-gsap="onStart"]');

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem);
            });
        }
    };
    return (
        <>

            <Swiper
                navigation={{
                    prevEl: "#prev5",
                    nextEl: "#next5",
                }}
                pagination={{
                    clickable: true,
                }}
                onInit={() => {
                    animationInit();
                }}
                onSlideChangeTransitionStart={() => animationInit()}
                loop={false}
                id="home-slider"
                className="slider m-0 p-0"
                
            >
                <SwiperSlide className="slide slick-bg slick-bgLessons ws-title-bg" style={{background:`url(${imgBg})`}}>
                    <Container  className="position-relative h-100">
                        <div className="slider-inner h-100">
                            <Row className="align-items-center  iq-ltr-direction h-100 ">
                                <Col xl="7" lg="12" md="12">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div
                                            className="d-flex align-items-center mt-2 mt-md-3 text-primary text-uppercase"
                                            data-iq-gsap="onStart"
                                            data-iq-position-x="-200"
                                            data-iq-delay="-0.5"
                                            style={{ width: '271px', height: '34px', }}
                                        >
                                            design & style

                                        </div>
                                    </div>
                                    <h1
                                        className="slider-text big-title title text-uppercase"
                                        data-iq-gsap="onStart"
                                        data-iq-position-x="-200"

                                    >
                                        toilar brands
                                    </h1>
                                    <div className='wd-card-line'></div>
                                    <div className="d-flex flex-wrap align-items-center text-left">
                                        <p
                                            data-iq-gsap="onStart"
                                            data-iq-position-y="80"
                                            data-iq-delay="0.8"
                                        >
                                            Fashion luminaries and Oscar de la Renta creative directors Laura Kim and Fernando Garcia teach you how to bring ideas to life through collaboration.
                                        </p>
                                    </div>
                                  {inLogin &&   <div
                                        className="d-flex align-items-center r-mb-23"
                                        data-iq-gsap="onStart"
                                        data-iq-position-y="80"
                                        data-iq-delay="0.8"
                                    >
                                        <Link
                                            to="/"
                                            className="btn btn-hover"
                                        >
                                            View Class
                                        </Link>
                                        <Link to="/" className="btn btn-link">
                                          + Follow
                                        </Link>
                                    </div>}  
                                
                                </Col>
                                <Col
                                    xl="5"
                                    lg="12"
                                    md="12"
                                    className="trailor-video text-center"
                                >
                                    {/* <Link
                                        onClick={() => setToggler1(!toggler1)}
                                        to="/"
                                        className="video-open playbtn"
                                    >
                                        <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="80px"
                                            height="80px"
                                            viewBox="0 0 213.7 213.7"
                                            enableBackground="new 0 0 213.7 213.7"
                                            xmlSpace="preserve"
                                        >
                                            <polygon
                                                className="triangle"
                                                fill="none"
                                                strokeWidth="7"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                points="73.5,62.5 148.5,105.8 73.5,149.1 "
                                            />
                                            <circle
                                                className="circle"
                                                fill="none"
                                                strokeWidth="7"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                cx="106.8"
                                                cy="106.8"
                                                r="103.3"
                                            />
                                        </svg>
                                        <span className="w-trailor">Tanıtımı İzle</span>
                                    </Link> */}
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
export default PageTitle