import axios from 'axios'




const API = process.env.REACT_APP_BASE_ENDPOIND;
const surveys = `${API}surveys`
const answer = `${API}profiles`

export const  getSurvey = async (id) =>{
    const {data} = await axios.get(`${surveys}/${id}`);
    return data;
} 
export const  putSurvey = async (profileId,id,body) =>{
  
    const {data} = await axios.put(`${answer}/${profileId}/surveys/${id}`,body);
    return data;
} 