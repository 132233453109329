import React from 'react'
import { Link } from "react-router-dom";
import { useApi } from '../../../../../contexts/RequestContext';



export default function Categories() {
const {categories,setCat} = useApi(); 
  
  return (
    <div className="d-flex align-self-stretch align-items-stretch">
    <ul className="sub-menu">
        {categories?.map((item,index)=>{
            return   <li key={index} className="menu-item">
            <Link onClick={()=>setCat(item)} to={`/${item?.slug}`} title={`${item?.name} Eğitimleri`}>{item?.name}</Link>
          </li>
        })}
     
    </ul>
    {/* <div className="sub-menu" style={{marginLeft:'250px'}}>
        <div className="" style={{width:'445px'}}>
        <img src={logo}/>
        </div>
      
    </div> */}
  </div>
  )
}
