import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import aboutus1 from '../assets/images/bg/r3.jpg';

export default function PageTitle({ title, subTitile }) {
  return (
    <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${aboutus1})` }}>
      <Container>
        <Row className="align-items-center">
          <Col sm="12">
            <nav className="text-center iq-breadcrumb-two ">
              <h1 className="h2">{title}</h1>
              <div className="red-line"></div>
              <p className="mt-3 text-white w-100 w-xs-50 w-sm-50 " style={{ margin: 'auto' }}>
                {subTitile}
              </p>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
