import { Switch, Route } from "react-router-dom";
import SelectPlan from "../Pages/Auth/Signup/SelectPlan";
import SetBankCard from "../Pages/Auth/Signup/SetBankCard";
import SetPassword from "../Pages/Auth/Signup/SetPassword";
import SignUpMobile from "../Pages/Auth/SignupMobile";
import SuccessPage from "../Pages/Auth/SignupMobile/SuccessPage";

const ExtraPages = () => {
  return (
    <Switch>
      <Route path="/mobile/sign-up" component={SignUpMobile} />
      <Route path="/mobile/set-password" component={SetPassword} />
      <Route path="/mobile/select-plan" component={SelectPlan} />
      <Route path="/mobile/set-card" component={SetBankCard} />
      <Route path="/mobile/success" component={SuccessPage} />
    </Switch>
  );
};

export default ExtraPages;
