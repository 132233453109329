import axios from 'axios';


const API = process.env.REACT_APP_BASE_ENDPOIND;
const profiles = `${API}profiles`;



export const getProfile = async (id) =>{
    return await axios.get(`${profiles}/${id}`);
} 
export const getProfiles = async () =>{
    return await axios.get(profiles);
} 
export const updateProfile = async (id,body) =>{
    return await axios.put(`${profiles}/${id}`,body);
} 

export const createProfiles = async (body) =>{
    return await axios.post(profiles,body);
} 
export const selectProfile = async (id) =>{
    return await axios.put(`${profiles}/${id}/select`);
} 
export const deleteProfile = async (id) =>{
    return await axios.delete(`${profiles}/${id}`);
} 





export const getProfileVideos1 = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${profiles}/${queryKey[1]}/videos?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const updateProfileVideosTime1 = (id, body,profileId) => {
  return axios.put(`${profiles}/${profileId}/videos/${id}`, body).then((response) => response.data);
};
