import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Nav, Tab } from 'react-bootstrap';
import { getTeachers } from '../../Services/TeachersApi';
import MyCertificatesItem from '../MyCertificatesItem';
import { getClassroomsComplated } from '../../Services/Classroom';
import { useAuth } from '../../contexts/AuthContext';

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function MyCertificates() {
  const [teachers, setTeachers] = useState([1, 1, 1, 11, , 1, 1]);
  const { profile } = useAuth();
  const { isLoading, error, data } = useQuery(['Certificates', profile?.id], getClassroomsComplated);
  useEffect(() => {
    if (data) setTeachers(data.data);
    return () => {};
  }, [data]);
  if (isLoading) return 'Yükleniyor...';

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <Container>
      <Row>
        <Col sm="12" className="overflow-hidden">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="main-title channel-logo w-50 text-left mt-0">Sertifikalarım</h4>
            {teachers?.length > 0 && (
              <div className="d-flex align-items-center justify-content-between">
                <div style={{ width: '50px' }} className="d-flex align-items-center justify-content-between mr-3">
                  <div id="prevCertificate" className="">
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div id="nextCertificate" className="">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                </div>

                <Link className="iq-view-all" to="/my-certificates" title="sertifikalarim">
                  Tümü
                </Link>
              </div>
            )}
          </div>
          <div id="favorites-contens">
            <Swiper
              navigation={{
                prevEl: '#prevCertificate',
                nextEl: '#nextCertificate',
              }}
              breakpoints={{
                320: { slidesPerView: 1.5 },
                550: { slidesPerView: 1.5 },
                991: { slidesPerView: 4 },
                1400: { slidesPerView: 5 },
              }}
              loop={false}
              slidesPerView={5}
              spaceBetween={15}
              as="ul"
              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
            >
              {teachers?.map((item) => {
                return (
                  <SwiperSlide as="li">
                    <MyCertificatesItem teacher={item?.teacher} item={item} />
                  </SwiperSlide>
                );
              })}
              <div className="d-flex justify-content-center w-100 mt-5">
                {teachers?.length < 1 && (
                  <div>
                    <button disabled className="btn  btn-outline-light">
                      İçerik bulunamadı
                    </button>
                  </div>
                )}
              </div>
            </Swiper>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
