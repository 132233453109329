import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
//select
import icon from '../../assets/icons/icons-award.png';
import { getCountries } from '../../Services/Country';
import { useAuth } from '../../contexts/AuthContext';
import ChangePassword from './components/ChangePassword';
import './style.css';

export default function EditProfile() {
  const [countries, setCountries] = useState([]);
  const { profile, user } = useAuth();
  useEffect(() => {
    getCountries().then(({ data }) => {
      setCountries(data.data);
    });
  }, []);
  return (
    <>
      <section>
        <Container style={{ paddingTop: '100px' }}>
          <Row>
            <Col lg="2">
              {profile ? (
                <div className="upload_profile d-inline-block">
                  <img src={profile?.avatar?.path} className="profile-pic avatar-120 rounded img-fluid" alt="user" />
                </div>
              ) : (
                <div
                  className="img-fluid avatar-120 rounded-circle d-flex justify-content-center align-items-center"
                  style={{ fontSize: '39px', background: '#BE161A' }}
                >
                  {user?.email?.toUpperCase()?.slice(0, 1)}
                  {user?.email?.toUpperCase()?.slice(1, 2)}
                </div>
              )}
            </Col>
            <Col lg="7" className="d-flex align-items-center justify-content-center justify-content-md-start">
              <div className="text-center text-md-left ">
                <h3 className="mb-2"> {profile ? profile?.name : user?.email}</h3>
                <span className=""> {user?.email}</span>
                <br />
                <span className="mt-2">
                  +{user?.country_code} {user?.phone_number}
                </span>
              </div>
            </Col>
            <Col lg="3">
              <div
                className=" mt-3 ws-category-list-top text-left"
                style={{
                  width: 'auto',
                  height: 'auto',
                  // margin: "40px 0 40px 158px",
                  padding: '15px',
                  borderRadius: '16px',
                  backdropFilter: 'blur(6.4px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
              >
                <div className="d-flex justify-content-start text-left align-items-center">
                  <div className="m-2 ">
                    <img src={icon} alt="" />
                  </div>

                  <div>
                    <span className="fw-bolder"> {user?.plan_type === 'Individual' ? 'Bireysel' : 'Taksitli'}</span> <br />
                    <span className="text-gray"> </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="m-profile manage-p mt-3 mt-md-5 pt-3" style={{ paddingTop: '0px' }}>
        <ChangePassword />
      </section>
    </>
  );
}
