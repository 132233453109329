import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.css';
import { Container } from 'react-bootstrap';
export default function SignUp() {
  return (
    <Container style={{ zIndex: '9999' }}>
      <div className={`${style.container} flex-wrap`}>
        <h5 className={`${style.text} `}>
          Yıllık <span className={`${style.text1} `}>890 TL</span> ’den başlayan fiyatlarla tüm eğitimlere sınırsız erişim
        </h5>
        <div className="d-flex align-items-center mt-1" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
          <Link to="/sign-up" className="btn btn-hover " title="Üye Ol">
            Şimdi İlerle
          </Link>
        </div>
      </div>
    </Container>
  );
}
