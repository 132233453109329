import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { getLikes } from "../../../Services/Liked";
import VideoItem1 from "../../../components/VideoComponents/VideoItem1";
import { useInfiniteQuery } from "@tanstack/react-query";
import TeacherItem from "../../../components/TeacherItem";
import { useAuth } from "../../../contexts/AuthContext";
import Pagination from "../../../components/UI/pagination/Pagination";
const TeachersList = ({ type }) => {
  const { profile } = useAuth();
  const { refresh } = useAuth();
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(["FollowingTeachers", profile?.id, type], getLikes, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
    },
  });
  useEffect(() => {
    refetch();

    return () => {};
  }, [refresh]);
  return (
    <>
      {status === "loading" ? (
        <p>Yükleniyor...</p>
      ) : status === "error" ? (
        <span>Hata oluştu: {error.message}</span>
      ) : (
        <>
          {type === "teachers" ? (
            <Row className="w-100">
              {data?.pages?.map((page) => (
                <React.Fragment key={page.nextId}>
                  {page?.data?.map((item, index) => (
                    <Col key={index} xs="6" sm="4" md="3" className="w-100">
                      <TeacherItem key={index} teacher={item} />
                    </Col>
                  ))}
                </React.Fragment>
              ))}
            </Row>
          ) : (
            <Row>
              {data?.pages?.map((page) => (
                <React.Fragment key={page.nextId}>
                  {page?.data?.map((item, index) => (
                    <Col key={index} xs="6" sm="3" md="3" className="mb-5">
                      <VideoItem1
                        vMax="285px"
                        hMax="505px"
                        img="https://www.masterclass.com/course-images/attachments/2G4hcaWEGsjD6rshZDCJWVE6?width=300&amp;height=533&amp;fit=cover&amp;dpr=2"
                        item={item}
                        minH="450px"
                      />
                    </Col>
                  ))}
                </React.Fragment>
              ))}
            </Row>
          )}
        </>
      )}

      <Row>
        <Col>
          <Pagination
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default TeachersList;
