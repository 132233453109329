import axios from "axios";



let profile = JSON.parse(localStorage.getItem("ws-user-profile"));
const API = process.env.REACT_APP_BASE_ENDPOIND;
const video_notes = `${API}notes`;
const videos = `${API}profiles/${profile?.id}/videos`;

export const getNotes = async (pageParam=0, queryKey) => {
  const { data } = await axios.get(
    `${video_notes}${queryKey}page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const getAllNotes = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${API}profiles/${queryKey[1]}/notes?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const getSliderNotes = async ( pageParam = 0, profileId) => {

  const { data } = await axios.get(
    `${API}profiles/${profileId}/notes?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};

export const CreateNote = (body) => {
  return axios.post(video_notes, body).then((response) => response.data);
};

export const UpdateNote = (id, note) => {
  return axios
    .put(`${video_notes}/${id}`, note)
    .then((response) => response.data);
};

export const deleteNote = (id) => {
  return axios.delete(`${video_notes}/${id}`).then((response) => response.data);
};

export const getProfileVideos = async ({ pageParam = 0, queryKey }) => {
  const { data } = await axios.get(
    `${videos}?page[number]=${pageParam}&page[size]=12`
  );
  return data;
};
export const updateProfileVideosTime = (id, body) => {
  return axios.put(`${videos}/${id}`, body).then((response) => response.data);
};
