import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import VideoTitle from "./VideoTitle";
import { deleteClassroom } from "../../Services/Classroom";
import ClassroomDropdown from "../UI/Dropdowns/ClassroomDropdown";
import { notify, notifyError } from "../AlartNotify/AlertNotify";
import "./style.css";
import { useAuth } from "../../contexts/AuthContext";

export default function VideoItem1(props) {
  const { inLogin } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteClassroom, {
    onSuccess: () => {
      queryClient.invalidateQueries("Admin:Teachers");
      notify("Başarılı Silindi");
    },
    onError: (error, variables, context) => {
      notifyError(error.response.data.message);
    },
  });
  return (
    <div
      className="ws-card"
      style={{
        background: `url(${props?.item?.thumbnail?.path})`,
      }}>
      {inLogin ? (
        <ClassroomDropdown item={props.item} mutation={mutation} />
      ) : (
        ""
      )}
      <div className="ws-card-img-bg"></div>

      <VideoTitle item={props.item} className="title-card" />
    </div>
  );
}
