import React, { useState } from 'react';
import PageTitle from '../../components/PageTitle';
import Categorieslist from './components/Categorieslist';

import { Container, Row, Col } from 'react-bootstrap';
import VideoItem1 from '../../components/VideoComponents/VideoItem1';

import HomeFooterComponent from '../Home/components/HomeFooterCom';
import { useAuth } from '../../contexts/AuthContext';
import ClassroomAdmin from './Classroom';
import TeachersAdmin from './Teacher';
import Categories from './Categories/Categories';
import VideoList from './Video';
import Avatars from './Avatars/Avatars';
import AvatarCategories from './AvatarCategories/AvatarCategories';

export default function AdminPage() {
  const { inLogin } = useAuth();
  const [title, setTitle] = useState('Tüm Eğitmenler');
  const [page, setPage] = useState('teachers');

  //   useEffect(() => {
  //     if (data) setClassroom(data?.data);
  //     return () => {};
  //   }, [data]);

  return (
    <div>
      <Row>
        <Col sm="12">
          <PageTitle title={title} subTitile={'150 den fazla eğitime sınırsız erişim imkanı.'} />
        </Col>
      </Row>
      <Container>
        <Row className="mt-5">
          <Col xs="12" sm="12" md="2">
            <div id="categories-2" className="widget widget_categories text-left">
              {/* <div className="ws-category-list-top">
         <span className="ml-2">Pages</span>
      </div> */}
              <div className="mt-3">
                <ul>
                  <li>
                    <div
                      onClick={() => {
                        setPage('teachers');
                        setTitle('Tüm Eğitmenler');
                      }}
                      style={{ cursor: 'pointer' }}
                      className={`${page === 'teachers' && 'text-primary'} btn-link`}
                    >
                      Eğitmenler
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        setPage('classroom');
                        setTitle('Tüm Eğitimler');
                      }}
                      style={{ cursor: 'pointer' }}
                      className={`${page === 'classroom' && 'text-primary'} btn-link`}
                    >
                      Eğitimler
                    </div>
                  </li>
                  {/* <li>
                    <div
                       onClick={() => {
                        setPage("lessons");
                        setTitle("Tüm Eğitmenler");
                      }}
                      style={{ cursor: "pointer" }}
                      className={`${
                        page === "lessons" && "text-primary"
                      } btn-link`}
                      to="#"
                    >
                      Video
                    </div>
                  </li> */}
                  <li>
                    <div
                      onClick={() => {
                        setPage('categories');
                        setTitle('Tüm Kategoriler');
                      }}
                      style={{ cursor: 'pointer' }}
                      className={`${page === 'categories' && 'text-primary'} btn-link`}
                      to="#"
                    >
                      Kategoriler
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        setPage('avatars');
                        setTitle('Tüm Avatarlar');
                      }}
                      style={{ cursor: 'pointer' }}
                      className={`${page === 'avatars' && 'text-primary'} btn-link`}
                      to="#"
                    >
                      Avatarlar
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        setPage('avatar-categories');
                        setTitle('Tüm Avatar Kategorileri');
                      }}
                      style={{ cursor: 'pointer' }}
                      className={`${page === 'avatar-categories' && 'text-primary'} btn-link`}
                      to="#"
                    >
                      Avatar Kategorileri
                    </div>
                  </li>
                </ul>
                <h5 className="widget-title"></h5>
              </div>
            </div>
          </Col>
          {page === 'classroom' && <ClassroomAdmin />}
          {page === 'teachers' && <TeachersAdmin />}
          {/* {page === "lessons" && <VideoList />} */}
          {page === 'categories' && <Categories />}
          {page === 'avatars' && <Avatars />}
          {page === 'avatar-categories' && <AvatarCategories />}
        </Row>
      </Container>

      {!inLogin && (
        <div className="mb-5">
          <Container>
            <HomeFooterComponent />
          </Container>
        </div>
      )}
    </div>
  );
}
