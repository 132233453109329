import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Col, Row, Modal } from "react-bootstrap";

import { gsap } from "gsap";
import "../style.css";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/RequestContext";
import bg from "../../../assets/images/article/BackgroundSingle.png";


SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const PageTitleSingle = (props) => {
  const { inLogin } = useAuth();
  const { videoSet } = useApi();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [toggler1, setToggler1] = useState(false);

  const animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };
  const open = (slug) => {
    videoSet(props?.item?.videos);
    history.push(`/watching/${slug}`);
  };
  return (
    <>
      <Swiper
        navigation={{
          prevEl: "#prev5",
          nextEl: "#next5",
        }}
        pagination={{
          clickable: true,
        }}
        onInit={() => {
          animationInit();
        }}
        onSlideChangeTransitionStart={() => animationInit()}
        loop={false}
        id="home-slider"
        className="slider m-0 p-0"
      >
        <SwiperSlide
          className="slide slick-bg slick-bg111 "
          style={{ background: `url(${bg})` }}
        >
          <Container >
            <Row className="align-items-center">
              <Col sm="12">
                <nav className="text-center iq-breadcrumb-two ">
                  <h6 className="text-primary text-uppercase mb-4">Community</h6>
                  <h2 className="article-title">
                    How to Plan Awesome Content That Grows
                  </h2>
                  <p
                    className="mt-4 article-sub-title"
                    style={{ margin: "auto" }}
                  >
                    Make content with value. Make things that will drive
                    traffic, then use funnels to turn that traffic into
                    conversions and you’ll have your own awesome mailing list in
                    no time
                  </p>
                </nav>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>

      </Swiper>
 
    </>
  );
};
export default PageTitleSingle;
