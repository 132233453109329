import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bgImg from '../../assets/images/bg/r3.jpg';
import happyIcon from '../../assets/icons/happy.png';
import './style.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAvatars } from '../../Services/AvatarApi';
import { createProfiles } from '../../Services/Profile';
import { notify, notifyError } from '../../components/AlartNotify/AlertNotify';

const SignuoSchema = Yup.object().shape({
  name: Yup.string().min(3, 'En az 3 karakter olmalı!').max(50, 'En fazla 50 karakter olmalı!').required('İsim zorunlu alan!'),
});

const initialValues = {
  name: '',
  avatar: 'happy.png',
  language: 'tr',
  timezone: 'Europe/Istanbul',
};
const AddProfile = (props) => {
  // const [accept, setAccept] = useState(true);
  const [avatar, setAvatar] = useState(happyIcon);
  const [avatarName, setAvatarName] = useState(null);
  const [listAvatar, setListAvatar] = useState([]);
  const [isVisible, setIsVisible] = useState('none');

  const formik = useFormik({
    initialValues,
    validationSchema: SignuoSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let body = {
        name: values?.name,
        avatar_id: avatarName,
        language: values?.language,
        timezone: values?.timezone,
      };
      createProfiles(body)
        .then((data) => {
          notify('Profiliniz başarıyla eklendi');
          history.push('/who-is-watch');
        })
        .catch((err) => {
          notifyError(err.response.data.message);
        });
    },
  });
  let history = useHistory();

  useEffect(() => {
    getAvatars()
      .then((data) => {
        setListAvatar(data?.data.data);
        setAvatarName(data?.data?.data[0]?.id);
        setAvatar(data?.data?.data[0]?.file?.path);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="sign-in-page slick-bg" style={{ backgroundImage: `url(${bgImg})` }}>
        <Container>
          <Row className="justify-content-center align-items-center " style={{ marginTop: '10%' }}>
            <Col lg="7" md="12" className="align-self-center text-left">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <h6 className="mb-3 text-primary text-center"></h6>
                    <h3 className="mb-3 text-center">Profil Ekle</h3>
                    <h6 className="mb-3 text-center">Diğer Kişiler İçin Profil Ekle</h6>
                    <div className="d-flex justify-content-center">
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsVisible(isVisible === 'none' ? 'block' : 'none');
                        }}
                        width={'90'}
                        height={'90'}
                        src={avatar}
                      />
                    </div>
                    <Form onSubmit={formik.handleSubmit} noValidate>
                      <Row>
                        <Col md="12">
                          <Form.Group className="emoji">
                            <Form.Label>İsim</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control mb-0 "
                              id="exampleInputname2"
                              {...formik.getFieldProps('name')}
                              name="name"
                              placeholder="İsim Gir"
                              autoComplete="off"
                              required
                            />
                            <div
                              className="ws-select-icon"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setIsVisible(isVisible == 'none' ? 'block' : 'none');
                              }}
                            >
                              <img src={avatar} />
                            </div>

                            {formik.touched.name && formik.errors.name && (
                              <div className="fv-plugins-message-container">
                                <span className="text-primary" role="alert">
                                  {formik.errors.name}
                                </span>
                              </div>
                            )}
                          </Form.Group>
                          <div className="ws-emoji-card" style={{ display: isVisible }}>
                            <div className="ws-emoji-card-header">Ruh Halini Seç</div>
                            <div className="ws-emoji-card-body">
                              <Row className="ws-emoji-card-body-list">
                                {listAvatar?.map((item, index) => {
                                  return (
                                    <Col
                                      key={index}
                                      xs="3"
                                      sm="3"
                                      md="3"
                                      className="mb-3"
                                      onClick={() => {
                                        setAvatarName(item?.id);
                                        setAvatar(item?.file?.path);
                                        setIsVisible(isVisible == 'none' ? 'block' : 'none');
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {' '}
                                      <img src={item?.file?.path} width="90px" />
                                    </Col>
                                  );
                                })}
                              </Row>
                            </div>
                          </div>
                        </Col>

                        {/* <Col sm="12" className="mt-4 mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck"
                              onChange={() => setAccept(!accept)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck"
                            >
                              <Link
                                to="/gizlilik-politikasi"
                                target="_blank"
                                className="text-primary"
                              >
                                KVKK Aydınlatma Metni ve Gizlilik Sözleşmesi
                              </Link>{" "}
                              ile{" "}
                              <Link
                                to="/uyelik-sozlesmesi"
                                target="_blank"
                                className="text-primary"
                              >
                                Online Üyelik Sözleşmesini
                              </Link>{" "}
                              okudum ve onaylıyorum.
                            </label>
                          </div>
                        </Col> */}
                      </Row>

                      <Button
                        type="submit"
                        className="btn btn-hover btn-primary1 my-2 w-100"
                        // disabled={accept}
                      >
                        Ekle
                      </Button>
                    </Form>

                    <Link to="./who-is-watch" type="submit" className="btn btn-outline-light my-2 w-100">
                      İptal
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddProfile;
