import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getSliderNotes } from "../../Services/Note";
import NoteItem from "../../Pages/MyProgress/components/NoteItem";
import { useAuth } from "../../contexts/AuthContext";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

export default function MyNotesSlider(props) {
  const { profile } = useAuth();
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getSliderNotes("0", profile?.id).then((res) => {
      setNotes(res?.data);
    });

    return () => {};
  }, []);
  return (
    <Container>
      <Row>
        <Col sm="12" className="overflow-hidden">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="main-title channel-logo w-50 text-left mt-0">
              Notlarım
            </h4>
            {notes?.length > 0 && (
              <div className="d-flex align-items-center justify-content-between">
                <div
                  style={{ width: "50px" }}
                  className="d-flex align-items-center justify-content-between mr-3"
                >
                  <div id="prevNote" className="">
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div id="nextNote" className="">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                </div>

                <Link
                  className="iq-view-all"
                  to="/all-my-notes"
                  title="Tum Notlarim"
                >
                  Tümü
                </Link>
              </div>
            )}
          </div>
          <div id="favorites-contens">
            <Swiper
              navigation={{
                prevEl: "#prevNote",
                nextEl: "#nextNote",
              }}
              breakpoints={{
                320: { slidesPerView: 1 },
                550: { slidesPerView: 1 },
                991: { slidesPerView: 2 },
                1400: { slidesPerView: 2 },
              }}
              loop={false}
              slidesPerView={5}
              spaceBetween={15}
              as="ul"
              className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
            >
              {notes?.length < 1 && (
                <div className="d-flex justify-content-center w-100 mt-5">
                  <div>
                    <button disabled={true} className="btn  btn-outline-light">
                      İçerik bulunamadı
                    </button>
                  </div>
                </div>
              )}
              {notes?.map((item) => {
                return (
                  <SwiperSlide key={item?.id} as="li">
                    <NoteItem type={"slider"} item={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
